const months = [
  'jan',
  'fev',
  'mar',
  'abr',
  'mai',
  'jun',
  'jul',
  'ago',
  'set',
  'out',
  'nov',
  'dez',
];

const fromNumberToPrice = (amount: number): string => {
  if (amount < 0) return '--';
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(amount);
};

const fromDateToHumanExpression = (text: string, exclude?: string): string => {
  // considerando receber o text sempre nesse formato
  // 2021-03-04 15:00
  const [date, hour] = text.split(' ');
  const [year, month, day] = date.split('-');

  const key_month = Number.parseInt(month, 10);

  if (exclude && exclude === 'day') return `${months[key_month - 1]} ${year}`;

  return `${day} de ${months[key_month - 1]} de ${year}`;
};

export { fromNumberToPrice, fromDateToHumanExpression };
