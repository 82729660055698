// src/styles/global.ts
import { createGlobalStyle, keyframes } from 'styled-components';

export default createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body{
    background: ${({ theme }) => theme.colors.background_primary};    
    -webkit-font-smoothing: antialiased;
  }

  body, input, button {
    font-family: Montserrat, sans-serif;
    font-size: 16px;
  }

  #root {
    /* max-width: 960px; */
    /* margin: 0 auto; */
    /* padding: 40px 20px; */
  }

  button{
    cursor: pointer;
    border:0;
    background:none;
  }

  a {
    text-decoration: none;
  }
`;

export const appearScreenFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;
