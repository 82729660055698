import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: 767.9px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 2vw;
  }

  @media (max-width: 539.9px) {
    grid-template-columns: 1fr;
  }
`;

export const NoInfo = styled.p`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.paragraph}px;
  color: ${({ theme }) => theme.colors.text_gray_75};
`;

export const Items = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.colors.text_gray_15};
  border-radius: 15px;

  margin-bottom: 2vh;
  padding: 15px 5%;

  @media (max-width: 767.9px) {
    padding: 0;
  }

  > svg {
    @media (max-width: 767.9px) {
      display: none;
    }
  }
`;

export const Content = styled.div`
  flex: 1;
  padding-left: 2%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @media (max-width: 767.9px) {
    flex-direction: column;
    position: relative;
    padding: 5vh 5vw;
  }
`;

export const Description = styled.div`
  @media (max-width: 767.9px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2vh;
  }

  > p {
    font-weight: 500;
    font-size: ${({ theme }) => theme.fontSize.paragraphBig}px;
    color: ${({ theme }) => theme.colors.text_blue};
    margin-bottom: 5px;
  }

  > span {
    font-size: ${({ theme }) => theme.fontSize.legend}px;
    color: ${({ theme }) => theme.colors.text_gray_75};
  }
`;

export const Amount = styled.div`
  border-right: 1px solid ${({ theme }) => theme.colors.text_gray_15};
  padding-right: 6%;

  @media (max-width: 767.9px) {
    border-right: none;
    padding-left: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2vh;
  }

  > p {
    font-weight: 600;
    font-size: ${({ theme }) => theme.fontSize.paragraphBig}px;
    color: ${({ theme }) => theme.colors.text_gray};
    margin: 5px 0;
  }

  > span {
    font-size: ${({ theme }) => theme.fontSize.legend}px;
    color: ${({ theme }) => theme.colors.text_gray_75};
  }
`;

export const Status = styled.div`
  min-width: 100px;
`;

export const Pending = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    width: 15px;
  }
  > p {
    margin-left: 10px;
    font-size: ${({ theme }) => theme.fontSize.paragraph}px;
    color: ${({ theme }) => theme.colors.text_red};
  }
`;

export const Paid = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    width: 15px;
  }
  > p {
    margin-left: 10px;
    font-size: ${({ theme }) => theme.fontSize.paragraph}px;
    color: ${({ theme }) => theme.colors.text_blue};
  }
`;

export const Download = styled.button`
  @media (max-width: 767.9px) {
    position: absolute;
    top: 3%;
    left: 84%;
  }

  > svg {
    @media (max-width: 767.9px) {
      width: 40px;
    }
  }
`;
