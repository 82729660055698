import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { IAlert } from './typing';

/* VERSÃO MOBILE
 * - 1024px
 * - 960px
 * - 768px
 * - 540px
 * - 480px
 * - 360px
 * - 320px
 */

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.background_secondary};
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    display: grid;
    grid-template-rows: 16vh 84vh;
    grid-template-columns: 2.2fr 7.8fr;
    grid-template-areas:
      'header header'
      'menu main';
  }
`;

export const Header = styled.div`
  grid-area: header;
  padding: 2vh 2vw;

  @media (max-width: 1023.9px) {
    height: 16vh;
  }
`;

export const Main = styled.div`
  grid-area: main;
  overflow: auto;

  padding: 0 2vw;

  @media (min-width: 1024px) {
    padding: 0 2vw 2vh 0;
  }
`;

export const Alert = styled.div<IAlert>`
  background-color: #cce5ff;
  border-radius: 10px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  padding: 3vh 3vw;
  margin-bottom: 2vh;
  display: none;

  ${props =>
    props.isShow &&
    css`
      display: block;
    `}

  > p {
    font-size: ${({ theme }) => theme.fontSize.paragraph}px;
    color: ${({ theme }) => theme.colors.text_gray};
    font-weight: 400;
    > strong {
      text-transform: capitalize;
      font-weight: 500;
    }
  }
`;

export const LinkDirect = styled(Link)`
  font-size: ${({ theme }) => theme.fontSize.paragraph}px;
  color: ${({ theme }) => theme.colors.text_blue};
  font-weight: 500;
`;

export const BoxGroup = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: 2vh;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 7fr 3fr;
    grid-column-gap: 1vw;
  }
`;

export const Indicators = styled.div`
  background-color: ${({ theme }) => theme.colors.background_primary};
  border-radius: 10px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  min-height: 120px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 25px 0;
  padding: 5vh 10vw;
  grid-template-areas:
    'amount amount'
    'indication indication';

  @media (min-width: 480px) {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0;
  }
`;

export const NumberIndications = styled.div`
  grid-area: indication;

  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 479.9px) {
    padding-top: 4vh;
    border-top: 1px solid ${({ theme }) => theme.colors.text_gray_15};
  }

  > p {
    font-size: ${({ theme }) => theme.fontSize.title}px;
    color: ${({ theme }) => theme.colors.text_blue};
    margin-bottom: 10px;
    font-weight: 500;

    @media (max-width: 539.9px) {
      font-size: ${({ theme }) => theme.fontSize.paragraphBig}px;
    }
  }

  > span {
    font-size: ${({ theme }) => theme.fontSize.paragraph}px;
    color: ${({ theme }) => theme.colors.text_gray_50};

    @media (max-width: 539.9px) {
      font-size: ${({ theme }) => theme.fontSize.legend}px;
      text-align: center;
    }
  }
`;

export const Revenues = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 480px) {
    border-left: 1px solid ${({ theme }) => theme.colors.text_gray_15};
  }

  > span {
    font-size: ${({ theme }) => theme.fontSize.paragraph}px;
    color: ${({ theme }) => theme.colors.text_gray_50};
    margin-bottom: 10px;

    @media (max-width: 539.9px) {
      font-size: ${({ theme }) => theme.fontSize.legend}px;
      text-align: center;
    }
  }

  > p {
    font-size: ${({ theme }) => theme.fontSize.title}px;
    color: ${({ theme }) => theme.colors.text_blue};
    font-weight: 500;

    @media (max-width: 539.9px) {
      font-size: ${({ theme }) => theme.fontSize.paragraphBig}px;
    }
  }
`;

export const ReferralCode = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background_primary};
  border-radius: 10px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  padding: 15px;
  min-height: 120px;

  @media (max-width: 767.9px) {
    margin-top: 2vh;
    flex-direction: row;
    justify-content: center;
  }

  @media (max-width: 479.9px) {
    flex-direction: column;
    padding: 5vh 5vw;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  > div > span {
    font-size: ${({ theme }) => theme.fontSize.legend}px;
    color: ${({ theme }) => theme.colors.text_gray_50};
    margin-bottom: 10px;

    @media (max-width: 767.9px) {
      font-size: ${({ theme }) => theme.fontSize.paragraph}px;
    }
  }

  > div > p {
    font-size: ${({ theme }) => theme.fontSize.paragraphBig}px;
    color: ${({ theme }) => theme.colors.text_blue};
    font-weight: 400;
    margin-bottom: 10px;
  }

  > button {
    @media (max-width: 767.9px) {
      margin-left: 5vw;
    }

    @media (max-width: 479.9px) {
      margin-left: 0;
      margin-top: 2vh;
    }
  }
`;

export const Menu = styled.nav`
  @media (min-width: 1023.9px) {
    grid-area: menu;
    padding: 0 1vw 2vh 2vw;
  }
`;

export const Content = styled.section`
  background-color: ${({ theme }) => theme.colors.background_primary};
  border-radius: 10px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  min-height: 300px;
  padding: 30px 3vw;

  @media (min-width: 1080px) {
    padding: 40px 80px;
  }

  @media (min-width: 560px) {
    padding: 40px 5vw;
  }
`;

export const HeaderOptions = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 479.9px) {
    flex-direction: column;
    justify-content: center;
  }

  > button {
    @media (max-width: 479.9px) {
      margin-bottom: 3vh;
      max-width: 80% !important;
    }
  }
`;

export const MonthsFilter = styled.div`
  background-color: ${({ theme }) => theme.colors.background_secondary_50};
  border-radius: 10px;

  @media (max-width: 479.9px) {
    width: 80%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
`;

export const MonthsButtonNav = styled.button`
  align-items: center;
  justify-content: center;
  padding: 15px 20px;

  > svg {
    width: 12px;
  }
`;

export const Date = styled.span`
  margin: 0 15px;
  font-size: ${({ theme }) => theme.fontSize.paragraph}px;
  text-transform: capitalize;
`;

export const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 150px;
`;

export const List = styled.div`
  padding: 40px 0 15px 0;
`;

export const ModalShared = styled(Modal)`
  background-color: ${({ theme }) => theme.colors.background_primary};
  border-radius: 10px;
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.1);
  border: 0.5px solid ${({ theme }) => theme.colors.text_gray_15};
  max-width: 550px;
  position: relative;

  @media (min-width: 540px) {
    max-height: 400px;
    width: 80%;
    padding: 40px 80px;
    margin: 25vh auto 0;
  }

  @media (max-width: 539.9px) {
    max-height: 80%;
    margin: 25vh 10vw 0;
    padding: 5vh 15vw;
  }

  > h1 {
    text-align: center;
    font-weight: 500;
    font-size: ${({ theme }) => theme.fontSize.paragraph}px;
    color: ${({ theme }) => theme.colors.text_gray};
    margin-bottom: 10px;
  }

  > p {
    text-align: center;
    font-weight: 400;
    font-size: ${({ theme }) => theme.fontSize.legend}px;
    color: ${({ theme }) => theme.colors.text_gray_50};
    margin-bottom: 20px;
  }
`;

export const ButtonCloseModal = styled.button`
  position: absolute;
  top: 10%;
  left: 93%;

  @media (max-width: 539.9px) {
    top: 2vh;
    left: 90%;
  }

  > svg {
    width: 12px;
    margin: 0;
  }
`;

export const ButtonSocial = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 539.9px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  > button svg {
    width: 40px;
    margin-left: 15px;
  }
`;

export const ModalRescue = styled(Modal)`
  background-color: ${({ theme }) => theme.colors.background_primary};
  border-radius: 10px;
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.1);
  border: 0.5px solid ${({ theme }) => theme.colors.text_gray_15};
  max-height: 400px;
  max-width: 550px;
  width: 80%;
  padding: 40px 80px;
  margin: 25vh auto 0;
  position: relative;

  @media (max-width: 539.9px) {
    margin: 25vh 10vw 0;
    padding: 6vh 6vw;
  }

  > h1 {
    text-align: center;
    font-weight: 500;
    font-size: ${({ theme }) => theme.fontSize.subtitle}px;
    color: ${({ theme }) => theme.colors.text_gray};
    margin-bottom: 20px;

    @media (max-width: 539.9px) {
      font-size: ${({ theme }) => theme.fontSize.paragraphBig}px;
    }
  }

  > p {
    text-align: center;
    font-weight: 400;
    font-size: ${({ theme }) => theme.fontSize.paragraph}px;
    color: ${({ theme }) => theme.colors.text_gray_50};
    margin-bottom: 20px;

    @media (max-width: 539.9px) {
      font-size: ${({ theme }) => theme.fontSize.legend}px;
    }
  }

  > form {
    padding: 20px 10% 0;
  }
`;
