import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface IContainerProps {
  sidebarIsOpen: boolean;
}

interface ICommonQuestions {
  isActive: boolean;
}

export const Container = styled.div<IContainerProps>`
  background: ${({ theme }) => theme.colors.background_primary};
  height: 100vh;

  overflow: ${({ sidebarIsOpen }) => (sidebarIsOpen ? 'hidden' : 'auto')};
`;

export const FlashyInformation = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6vh 22vw 14vh;

  @media (max-width: 768px) {
    padding: 6vh 12vw 14vh;
  }
`;

export const FlashyTitle = styled.h1`
  text-align: center;
  color: ${({ theme }) => theme.colors.text_blue};
  font-size: ${({ theme }) => theme.fontSize.titleBig / 8}rem;
  font-weight: 700;
  line-height: 5rem;
  margin-bottom: 5vh;

  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSize.titleBig / 10}rem;
  }
`;

export const FlashySubtitle = styled.h2`
  text-align: center;
  color: ${({ theme }) => theme.colors.text_gray};
  font-size: ${({ theme }) => theme.fontSize.paragraphBig}px;
  font-weight: 400;
  line-height: 30px;
  max-width: 80%;
`;

export const RangeInformation = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12vw;
  background: ${({ theme }) => theme.colors.background_secondary_50};
`;

export const RangeInformationItems = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4vh 2vw;
  max-width: 800px;
`;

export const RangeInformationItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6vh 0;

  > svg {
    max-width: 250px;
    max-height: 250px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    :first-child {
      flex-direction: column-reverse;
    }
    > svg {
      margin-bottom: 4vh;
    }
  }
`;

export const RangeInformationItemContainer = styled.div`
  padding: 0 3vw;
`;

export const RangeInformationItemTitle = styled.p`
  margin-top: 1.5vh;
  color: ${({ theme }) => theme.colors.text_gray};
  font-size: ${({ theme }) => theme.fontSize.title}px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 3vh;
`;

export const RangeInformationItemText = styled.span`
  color: ${({ theme }) => theme.colors.text_gray_75};
  font-size: ${({ theme }) => theme.fontSize.paragraphMed}px;
  font-weight: 500;
  line-height: 30px;
`;

export const SolutionsInformation = styled.section`
  padding: 0 12vw;
`;

export const SolutionsInformationBox = styled.div`
  padding: 8vh 0;
`;

export const SolutionsTitle = styled.h1`
  text-align: center;
  color: ${({ theme }) => theme.colors.text_gray_75};
  font-size: ${({ theme }) => theme.fontSize.subtitleBig}px;
  font-weight: 700;
  margin-bottom: 4vh;

  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSize.paragraphMed}px;
  }
`;

export const SolutionsSubtitle = styled.h2`
  text-align: center;
  color: ${({ theme }) => theme.colors.text_blue};
  font-size: ${({ theme }) => theme.fontSize.subtitle / 9}rem;
  font-weight: 700;
  margin: 4vh auto;
  max-width: 70%;
  line-height: 3rem;

  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSize.subtitleBig}px;
    line-height: 30px;
    max-width: 100%;
  }
`;

export const SolutionsBoxItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 4vh 12vw 6vh;

  @media (max-width: 768px) {
    padding: 4vh 0 6vh;
  }
`;

export const SolutionsItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10vh;

  > svg {
    max-width: 70px;
    max-height: 70px;
    margin-right: 3vw;
  }

  @media (max-width: 540px) {
    flex-direction: column;
    margin-bottom: 12vh;

    > svg {
      max-width: 90px;
      max-height: 90px;
      margin-right: 0;
      margin-bottom: 6vh;
    }
  }
`;

export const SolutionsItemDescription = styled.div`
  @media (max-width: 540px) {
    text-align: center;
    > p {
      text-align: center;
    }
  }
`;

export const SolutionsItemDescriptionTitle = styled.p`
  text-align: start;
  color: ${({ theme }) => theme.colors.text_blue};
  font-size: ${({ theme }) => theme.fontSize.paragraphBig}px;
  font-weight: 700;
  margin-bottom: 1.5vh;
`;

export const SolutionsItemDescriptionText = styled.span`
  text-align: start;
  color: ${({ theme }) => theme.colors.text_gray};
  font-size: ${({ theme }) => theme.fontSize.paragraphMed}px;
  font-weight: 400;
  line-height: 30px;
`;

export const ChoosePlanLink = styled(Link)``;

export const ButtonChoosePlan = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
  max-width: 300px;
  padding: 0 15px;
  margin: 0 auto;
  border: 2px solid ${({ theme }) => theme.colors.text_blue};
  border-radius: 30px;
  color: ${({ theme }) => theme.colors.text_blue};
  font-size: ${({ theme }) => theme.fontSize.paragraphMed}px;
  font-weight: 600;

  :hover {
    color: ${({ theme }) => theme.colors.text_gray};
    border: 2px solid ${({ theme }) => theme.colors.text_gray};
  }
`;

export const AffiliateRules = styled.div`
  display: flex;
  justify-content: center;
  padding: 4vh 0 0;
`;

export const AffiliateRulesLink = styled(Link)`
  > p {
    color: ${({ theme }) => theme.colors.text_blue};
    font-size: ${({ theme }) => theme.fontSize.paragraphMed}px;
    font-weight: 600;

    :hover {
      color: ${({ theme }) => theme.colors.text_gray};
    }
  }
`;

// export const Affiliate = styled.section`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   background-color: ${({ theme }) => theme.colors.text_blue};
//   min-height: 350px;
//   text-align: center;
//   padding: 8vh 12vw 4vh;
//   position: relative;

//   h1 {
//     color: #fff;
//     font-size: ${({ theme }) => theme.fontSize.titleBig}px;
//     font-weight: 700;
//     margin-bottom: 4vh;
//   }

//   p {
//     color: #fff;
//     font-size: ${({ theme }) => theme.fontSize.paragraphMed}px;
//     font-weight: 500;
//     margin-bottom: 6vh;
//   }

//   p.legend {
//     margin-bottom: 0;
//     position: absolute;
//     bottom: 3vh;
//     left: 2vw;
//     font-size: ${({ theme }) => theme.fontSize.legend}px;
//     opacity: 0.7;
//   }

//   button {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     height: 50px;
//     width: 100%;
//     max-width: 230px;
//     padding: 0 15px;
//     margin: 0 auto;
//     border: 1px solid #fff;
//     border-radius: 30px;
//     color: #fff;
//     font-size: ${({ theme }) => theme.fontSize.paragraphMed}px;
//     font-weight: 500;

//     :hover {
//       opacity: 0.8;
//     }
//   }

//   @media (max-width: 768px) {
//     padding: 8vh 12vw 10vh;
//   }
// `;

export const CommonQuestions = styled.section`
  padding: 12vh 12vw 10vh;
`;

export const CommonQuestionsTitle = styled.h1`
  text-align: center;
  color: ${({ theme }) => theme.colors.text_gray_75};
  font-size: ${({ theme }) => theme.fontSize.subtitleBig}px;
  font-weight: 700;
  margin-bottom: 4vh;

  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSize.paragraphMed}px;
  }
`;

export const CommonQuestionsSubtitle = styled.h2`
  text-align: center;
  color: ${({ theme }) => theme.colors.text_blue};
  font-size: ${({ theme }) => theme.fontSize.subtitle / 9}rem;
  font-weight: 700;
  margin: 4vh auto;
  max-width: 70%;
  line-height: 3rem;

  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSize.subtitleBig}px;
    line-height: 30px;
    max-width: 100%;
  }
`;

export const CommonQuestionsItems = styled.div`
  padding: 6vh 8vw;
  @media (max-width: 768px) {
    padding: 6vh 0;
  }
`;

export const CommonQuestionsItem = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const CommonQuestionsItemTouch = styled.button<ICommonQuestions>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5vh 4vw;
  background-color: ${({ theme }) => theme.colors.background_primary};

  > svg {
    max-width: 20px;
    max-height: 20px;
    transition: 500ms;
    path {
      fill: ${({ theme }) => theme.colors.text_blue};
    }
  }

  ${props =>
    props.isActive &&
    css`
      background-color: ${({ theme }) => theme.colors.background_secondary_50};

      > svg {
        transform: rotate(45deg);
      }
    `}
`;

export const CommonQuestionsItemTitle = styled.p`
  text-align: start;
  color: ${({ theme }) => theme.colors.text_gray};
  font-size: ${({ theme }) => theme.fontSize.paragraphBig}px;
  font-weight: 600;
`;

export const CommonQuestionsItemDescription = styled.div<ICommonQuestions>`
  overflow: hidden;
  max-height: 0;
  pointer-events: none;
  transition: max-height 400ms ease-in-out;

  > p {
  }

  ${props =>
    props.isActive &&
    css`
      max-height: 500px;
      transition: max-height 400ms ease-in;
    `}
`;

export const CommonQuestionsItemDescriptionText = styled.p`
  padding: 4vh 4vw;
  text-align: start;
  color: ${({ theme }) => theme.colors.text_gray};
  font-size: ${({ theme }) => theme.fontSize.paragraphMed}px;
  font-weight: 500;
  line-height: 30px;
`;

export const Whatsapp = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 4vh;
  right: 4vw;
  width: 60px;
  height: 60px;
  padding: 2vh;
  border-radius: 50%;
  background-color: #00bb2d;

  :hover {
    background-color: #34af23;
  }

  svg {
    width: 35px;
    height: 35px;
  }

  @media (max-width: 767.9px) {
    bottom: 2vh;
    right: 2vw;
    width: 40px;
    height: 40px;
    padding: 1vh;
    svg {
      width: 24px;
      height: 24px;
    }
  }
`;
