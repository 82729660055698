import { Form } from '@unform/web';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

/* VERSÃO MOBILE
 * - 1024px
 * - 960px
 * - 768px
 * - 540px
 * - 480px
 * - 360px
 * - 320px
 */
export const Container = styled.div`
  background: ${({ theme }) => theme.colors.background_secondary};
  display: flex;

  flex-direction: column;

  @media (min-width: 1024px) {
    display: grid;
    grid-template-rows: 16vh 84vh;
    grid-template-columns: 2.2fr 7.8fr;
    grid-template-areas:
      'header header'
      'menu main';
  }
`;

export const Header = styled.div`
  grid-area: header;
  padding: 2vh 2vw;

  @media (max-width: 1023.9px) {
    height: 16vh;
  }
`;

export const Main = styled.div`
  grid-area: main;
  overflow: auto;

  padding: 0 2vw;

  @media (min-width: 1024px) {
    padding: 0 2vw 2vh 0;
  }
`;

export const Menu = styled.nav`
  @media (min-width: 1023.9px) {
    grid-area: menu;
    padding: 0 1vw 2vh 2vw;
  }
`;

export const NamePage = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
  height: 10vh;
  background-color: ${({ theme }) => theme.colors.background_primary};
  border-radius: 10px;

  padding: 0 3vw;
  margin-bottom: 2vh;

  @media (max-width: 539.9px) {
    padding: 5vh 10vw;
  }
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSize.paragraphBig}px;
  color: ${({ theme }) => theme.colors.text_blue};

  @media (max-width: 539.9px) {
    font-size: ${({ theme }) => theme.fontSize.paragraph}px;
  }
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background_primary};
  border-radius: 10px;

  padding: 6vh 3vw;
  margin-bottom: 2vh;

  @media (max-width: 539.9px) {
    padding: 5vh 10vw;
  }
`;

export const HeaderContent = styled.div`
  width: 100%;
`;

export const HeaderContentBoxImageProfile = styled.div`
  width: 140px;
  height: 140px;
  position: relative;

  > svg {
    margin-right: 15px;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;

export const UserImage = styled.img`
  margin-right: 15px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

export const LabelUpdateAvatar = styled.label`
  cursor: pointer;
  position: absolute;
  bottom: -4vh;
  right: 0;

  > svg {
    width: 45px;
  }

  > input {
    display: none;
  }
`;

export const ContentUserForm = styled.div`
  padding: 8vh 0 2vh;
`;

export const UserForm = styled(Form)``;

export const FieldGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 2vw;
  margin-bottom: 2vh;

  @media (min-width: 1024px) {
    max-width: 70%;
  }

  @media (max-width: 1023.9px) {
    max-width: 80%;
  }

  @media (max-width: 767.9px) {
    max-width: 100%;
  }

  @media (max-width: 539.9px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }

  > div {
    margin-right: 2vw;

    @media (max-width: 539.9px) {
      margin-bottom: 2vh;
    }
  }

  > div > input {
    text-transform: capitalize;
  }

  > div > input::placeholder,
  > div > input[type='email'],
  > div > input[type='date'] {
    text-transform: none;
  }

  > .link {
    text-align: start;
    font-weight: 500;
    font-size: ${({ theme }) => theme.fontSize.paragraph}px;
    color: ${({ theme }) => theme.colors.text_blue};

    @media (max-width: 539.9px) {
      margin-bottom: 2vh;
    }
  }
`;

export const ChangePassword = styled.button``;

export const Subtitle = styled.h2`
  margin: 6vh 0 2vh;
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSize.paragraphBig}px;
  color: ${({ theme }) => theme.colors.text_gray};

  @media (max-width: 539.9px) {
    font-size: ${({ theme }) => theme.fontSize.paragraphBig}px;
  }
`;

export const LinkSearchCep = styled(Link)`
  display: flex;
  align-items: center;
`;

export const ModalPassword = styled(Modal)`
  background-color: ${({ theme }) => theme.colors.background_primary};
  border-radius: 10px;
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.1);
  border: 0.5px solid ${({ theme }) => theme.colors.text_gray_15};
  max-height: 400px;
  max-width: 550px;
  width: 80%;
  padding: 40px 80px;
  margin: 25vh auto 0;
  position: relative;

  @media (max-width: 539.9px) {
    margin: 25vh 10vw 0;
    padding: 6vh 6vw;
  }

  > h1 {
    text-align: center;
    font-weight: 500;
    font-size: ${({ theme }) => theme.fontSize.subtitle}px;
    color: ${({ theme }) => theme.colors.text_gray};
    margin-bottom: 10px;

    @media (max-width: 539.9px) {
      font-size: ${({ theme }) => theme.fontSize.paragraphBig}px;
    }
  }

  > p {
    text-align: center;
    font-weight: 400;
    font-size: ${({ theme }) => theme.fontSize.paragraph}px;
    color: ${({ theme }) => theme.colors.text_gray_50};
    margin-bottom: 20px;

    @media (max-width: 539.9px) {
      font-size: ${({ theme }) => theme.fontSize.legend}px;
    }
  }

  > form {
    padding: 20px 10% 0;
  }
`;

export const ButtonCloseModal = styled.button`
  position: absolute;
  top: 10%;
  left: 93%;

  @media (max-width: 539.9px) {
    top: 2vh;
    left: 90%;
  }

  > svg {
    width: 12px;
    margin: 0;
  }
`;

export const FieldModal = styled.div`
  margin-bottom: 2vh;
`;
