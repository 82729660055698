import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import { IconMenuBurguer, IconUser } from '@assets/icons';
import { ImageLogotipo } from '@assets/images';
import { ButtonBlueGradient } from '@components/index';
import { ConfigBase } from '@config/index';

import { BorderBottom, Container, Navbar, NavbarText } from './styles';

interface IHeaderProps {
  handleOpenSidebar(): void;
}

const HeaderHome: React.FC<IHeaderProps> = ({ handleOpenSidebar }) => {
  const { t } = useTranslation(['Home2']);
  const [redirect, setRedirect] = useState('');

  const changeRedirect = () => {
    setRedirect('signin');
  };

  return (
    <Container>
      {redirect === 'signin' && <Redirect to="/login" />}
      {redirect === 'landingpage' && <Redirect to="/" exact />}
      <ImageLogotipo
        className="logotipo"
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setRedirect('landingpage');
        }}
      />

      <IconMenuBurguer className="menu-burguer" onClick={handleOpenSidebar} />

      <Navbar>
        <NavbarText to="/">
          Início
          <BorderBottom />
        </NavbarText>

        <NavbarText
          to={{ pathname: `${ConfigBase.clientBaseURL}` }}
          target="_blank"
        >
          Quero ser cliente
          <BorderBottom />
        </NavbarText>

        <NavbarText
          to={{ pathname: `${ConfigBase.providerBaseURL}/cadastro` }}
          target="_blank"
        >
          Quero ser prestador
          <BorderBottom />
        </NavbarText>

        {/* <NavbarText
          to={{
            pathname: `${ConfigBase.clientBaseURL}/login`,
          }}
          target="_blank"
        >
          Para clientes
          <BorderBottom />
        </NavbarText> */}

        {/* <NavbarText
          to={{
            pathname: `${ConfigBase.providerBaseURL}/cadastro`,
          }}
          target="_blank"
        >
          Para prestadores
          <BorderBottom />
        </NavbarText> */}

        <ButtonBlueGradient onClick={changeRedirect} style={{ fontSize: 14 }}>
          <IconUser width={14} style={{ marginRight: '.5vw' }} />
          Área do afiliado
        </ButtonBlueGradient>
      </Navbar>
    </Container>
  );
};

export default HeaderHome;
