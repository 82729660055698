import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { MoonLoader } from 'react-spinners';

import Payment from '@models/Payment';
import User from '@models/User';
import api from '@services/api';
import sessionService from '@services/session.service';
import notify from '@utils/notify/toasts';

import Item from './Item';
import { IParams } from './typing';

import {
  Container,
  Main,
  Content,
  Loading,
  List,
  NavigationPages,
} from './styles';

const PAGE_PAYMENT_HISTORY_STORAGE = '@Rebox:Indications:PaymentsHistory:page';

const IndicationsDetailsPaymentsHistory: React.FC = () => {
  const { id } = useParams<IParams>();
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<User>(sessionService.getUser());
  const [payments, setPayments] = useState<Payment[]>([]);
  const [page, setPage] = useState<number>(
    Number.parseInt(
      sessionStorage.getItem(PAGE_PAYMENT_HISTORY_STORAGE) || '1',
      10,
    ),
  );
  const [totalPages, setTotalPages] = useState<number>(1);

  const getPayments = async (pageNew: number) => {
    try {
      setLoading(prevState => !prevState);
      const { data } = await api.get(
        `/payments?what_is_being_paid=indications&paying_item_with_id=${id}`,
      );
      setPayments(data.data);
      setTotalPages(Math.ceil(data.header.total / data.header.per_page));
    } catch (error) {
      notify('Houve um erro ao tentar buscar as informações.', 'error');
    } finally {
      setLoading(prevState => !prevState);
    }
  };

  useEffect(() => {
    getPayments(page);
    sessionStorage.setItem(PAGE_PAYMENT_HISTORY_STORAGE, `${page}`);
  }, [page]);

  return (
    <Container>
      <Main>
        <Content>
          {loading ? (
            <Loading>
              <MoonLoader size={30} color="#07359B" />
            </Loading>
          ) : (
            <List>
              <Item payments={payments} />
            </List>
          )}

          <NavigationPages>
            <button disabled={page === 1} onClick={() => setPage(page - 1)}>
              Anterior
            </button>
            <p>
              {page} de {totalPages}
            </p>
            <button
              disabled={page === totalPages}
              className="invert"
              onClick={() => setPage(page + 1)}
            >
              Próximo
            </button>
          </NavigationPages>
        </Content>
      </Main>
    </Container>
  );
};

export default IndicationsDetailsPaymentsHistory;
