/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { IconArrowLeft, IconArrowRight } from '@assets/icons';
import Indication from '@models/Indication';
import Payment from '@models/Payment';
import User from '@models/User';
import {
  formatCellphone,
  formatCPF,
  formatDate,
  formatMoney,
} from '@utils/formatters';
import { formatDateBR } from '@utils/validatorDate';

import { indicationStatus } from './constants';

import {
  Container,
  NoInfo,
  ItemList,
  ItemListLabel,
  ItemListText,
  ItemListMobile,
  MobileItem,
  MobileItemBox,
  MobileItemBoxLabel,
  MobileItemBoxText,
} from './styles';

interface IParams {
  indications?: Indication[];
}

const Item: React.FC<IParams> = ({ indications }) => {
  const { push } = useHistory();
  const { innerWidth } = window;
  const [
    firstComissionPaymentStatus,
    setFirstComissionPaymentStatus,
  ] = useState('');
  const [
    secondComissionPaymentStatus,
    setSecondComissionPaymentStatus,
  ] = useState('');

  const handleChangeIndicationPaidStatus = (
    payments: Payment[] | undefined,
  ): void => {
    if (payments) {
      const sortedPayments = payments.sort((a: Payment, b: Payment) => {
        return a?.date_created > b?.date_created ? 1 : -1;
      });
      const [firstPayment, secondPayment] = sortedPayments;
      if (
        firstPayment.pay_day &&
        ['RECEIVED', 'CONFIRMED', 'RECEIVED_IN_CASH'].includes(
          firstPayment.status,
        )
      ) {
        setFirstComissionPaymentStatus('Pago');
      }
      if (
        secondPayment.pay_day &&
        ['RECEIVED', 'CONFIRMED', 'RECEIVED_IN_CASH'].includes(
          secondPayment.status,
        )
      ) {
        setSecondComissionPaymentStatus('Pago');
      }

      if (
        firstPayment.status === 'PENDING' &&
        secondPayment.status === 'PENDING'
      ) {
        setFirstComissionPaymentStatus('Aguardando');
        setSecondComissionPaymentStatus('Aguardando');
      }
      if (
        firstPayment.status === 'OVERDUE' &&
        secondPayment.status === 'OVERDUE'
      ) {
        setFirstComissionPaymentStatus('Não pago');
        setSecondComissionPaymentStatus('Não pago');
      }
    }
  };

  useEffect(() => {
    if (indications) {
      for (const indication of indications) {
        handleChangeIndicationPaidStatus(
          indication.user_indicated?.contracts?.[0]?.payments,
        );
      }
    }
  }, []);

  return (
    <Container>
      {indications?.length === 0 ? (
        <NoInfo>Nenhuma indicação foi encontrada</NoInfo>
      ) : (
        <>
          {innerWidth > 960 ? (
            <>
              <ItemList>
                <ItemListLabel>Criado em</ItemListLabel>
                <ItemListLabel>Status</ItemListLabel>
                <ItemListLabel>Comissão atual</ItemListLabel>
                <ItemListLabel>Indicado</ItemListLabel>
                <ItemListLabel>1ª Comissão</ItemListLabel>
                <ItemListLabel>2ª Comissão</ItemListLabel>
                <ItemListLabel>Ação</ItemListLabel>
                {/* <ItemListLabel>Ação</ItemListLabel> */}
              </ItemList>
              {indications?.map(item => (
                <ItemList key={item.id}>
                  <ItemListText>
                    {formatDate.addMask(item.date?.split(' ')[0] || '')}
                  </ItemListText>
                  <ItemListText>
                    {indicationStatus[item.status?.toLowerCase() || '']}
                  </ItemListText>
                  <ItemListText>
                    {formatMoney.fromNumberToPrice(item.cash_bonus || 0)}
                  </ItemListText>
                  <ItemListText>
                    {item.user_indicated?.name.toUpperCase()}
                  </ItemListText>
                  <ItemListText>{firstComissionPaymentStatus}</ItemListText>
                  <ItemListText>{secondComissionPaymentStatus}</ItemListText>
                  <ItemListText
                    style={{ cursor: 'pointer' }}
                    onClick={() => push(`/indicacao/${item.id}`)}
                  >
                    <IconArrowRight />
                  </ItemListText>
                </ItemList>
              ))}
            </>
          ) : (
            <ItemListMobile>
              {indications?.map(item => (
                <MobileItem key={item.id}>
                  <MobileItemBox>
                    <MobileItemBoxLabel>Criado em</MobileItemBoxLabel>
                    <MobileItemBoxText>
                      {formatDate.addMask(item.date?.split(' ')[0] || '')}
                    </MobileItemBoxText>
                  </MobileItemBox>

                  <MobileItemBox>
                    <MobileItemBoxLabel>Status</MobileItemBoxLabel>
                    <MobileItemBoxText>
                      {indicationStatus[item.status?.toLowerCase() || '']}
                    </MobileItemBoxText>
                  </MobileItemBox>

                  <MobileItemBox>
                    <MobileItemBoxLabel>Próxima comissão</MobileItemBoxLabel>
                    <MobileItemBoxText>
                      {formatMoney.fromNumberToPrice(item.cash_bonus || 0)}
                    </MobileItemBoxText>
                  </MobileItemBox>

                  <MobileItemBox>
                    <MobileItemBoxLabel>Indicado</MobileItemBoxLabel>
                    <MobileItemBoxText>
                      {item.user_indicated?.name.toUpperCase()}
                    </MobileItemBoxText>
                  </MobileItemBox>

                  <MobileItemBox>
                    <MobileItemBoxLabel>E-mail</MobileItemBoxLabel>
                    <MobileItemBoxText>
                      {item.user_indicated?.email}
                    </MobileItemBoxText>
                  </MobileItemBox>
                  <MobileItemBox>
                    <MobileItemBoxLabel>Celular</MobileItemBoxLabel>
                    <MobileItemBoxText>
                      {item.user_indicated?.cellphone}
                    </MobileItemBoxText>
                    <MobileItemBoxLabel>1ª Comissão</MobileItemBoxLabel>
                    <MobileItemBoxText>
                      {firstComissionPaymentStatus}
                    </MobileItemBoxText>
                    <MobileItemBoxLabel>2ª Comissão</MobileItemBoxLabel>
                    <MobileItemBoxText>
                      {secondComissionPaymentStatus}
                    </MobileItemBoxText>
                  </MobileItemBox>
                  {/* <ItemListText
              style={{ textAlign: 'center', cursor: 'pointer' }}
              onClick={() => push(`/cliente/${item.id}`)}
            >
              <IconArrowRight />
            </ItemListText> */}
                </MobileItem>
              ))}
            </ItemListMobile>
          )}
        </>
      )}
    </Container>
  );
};

export default Item;
