import { ConfigValues } from '@config/index';

export const typeBank = [
  {
    label: 'Pix',
    value: ConfigValues.bank_account.type.pix,
  },
];

export const pixType = [
  {
    label: 'E-mail',
    value: ConfigValues.bank_account.pix_type.email,
  },
  {
    label: 'CPF',
    value: ConfigValues.bank_account.pix_type.cpf,
  },
  {
    label: 'CNPJ',
    value: ConfigValues.bank_account.pix_type.cnpj,
  },
  {
    label: 'Celular',
    value: ConfigValues.bank_account.pix_type.cellphone,
  },
  {
    label: 'Chave aleatória',
    value: ConfigValues.bank_account.pix_type.others,
  },
];

export const listBank = [
  {
    label: '237 - Banco Bradesco S.A.',
    value: ConfigValues.bank_account.bank_name.bradesco,
  },
  {
    label: '001 - Banco do Brasil S.A.',
    value: ConfigValues.bank_account.bank_name.banco_brasil,
  },
  {
    label: '033 - Banco Santander (Brasil) S.A.',
    value: ConfigValues.bank_account.bank_name.santander,
  },
  {
    label: '341 - Banco Itaú S.A.',
    value: ConfigValues.bank_account.bank_name.itau,
  },
  {
    label: '389 - Banco Mercantil do Brasil S.A.',
    value: ConfigValues.bank_account.bank_name.mercantil,
  },
  {
    label: '453 - Banco Rural S.A.',
    value: ConfigValues.bank_account.bank_name.banco_rural,
  },
  {
    label: '745 - Banco Citibank S.A.',
    value: ConfigValues.bank_account.bank_name.citibank,
  },
  {
    label: '041 - BANRISUL Banco do Estado do Rio Grande do Sul S.A.',
    value: ConfigValues.bank_account.bank_name.banrisul,
  },
  {
    label: '077 - Banco Inter S.A.',
    value: ConfigValues.bank_account.bank_name.inter,
  },
  {
    label: '212 - Banco Original S.A.',
    value: ConfigValues.bank_account.bank_name.original,
  },
  {
    label: '104 - Caixa Econômica Federal',
    value: ConfigValues.bank_account.bank_name.caixa,
  },
  {
    label: '362 - Cielo S.A.',
    value: ConfigValues.bank_account.bank_name.cielo,
  },
  {
    label: '652 - Itaú Unibanco Holding S.A.',
    value: ConfigValues.bank_account.bank_name.itau_unibanco,
  },
  {
    label: '323 - Mercado Pago',
    value: ConfigValues.bank_account.bank_name.mercado_pago,
  },
  {
    label: '260 - Nu Pagamentos S.A.',
    value: ConfigValues.bank_account.bank_name.nubank,
  },
  {
    label: '290 - Pag Seguro Internet S.A.',
    value: ConfigValues.bank_account.bank_name.pagseguro,
  },
  {
    label: '380 - Picpay Serviços S.A.',
    value: ConfigValues.bank_account.bank_name.picpay,
  },
];
