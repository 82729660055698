import styled from 'styled-components';
import Modal from 'react-modal';

/* VERSÃO MOBILE
 * - 1024px
 * - 960px
 * - 768px
 * - 540px
 * - 480px
 * - 360px
 * - 320px
 */
export const Container = styled.div`
  background: ${({ theme }) => theme.colors.background_secondary};
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    display: grid;
    grid-template-rows: 16vh 84vh;
    grid-template-columns: 2.2fr 7.8fr;
    grid-template-areas:
      'header header'
      'menu main';
  }
`;

export const Header = styled.div`
  grid-area: header;
  padding: 2vh 2vw;

  @media (max-width: 1023.9px) {
    height: 16vh;
  }
`;

export const Main = styled.div`
  grid-area: main;
  overflow: auto;

  padding: 0 2vw;

  @media (min-width: 1024px) {
    padding: 0 2vw 2vh 0;
  }
`;

export const Menu = styled.nav`
  @media (min-width: 1023.9px) {
    grid-area: menu;
    padding: 0 1vw 2vh 2vw;
  }
`;

export const NamePage = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
  height: 10vh;
  background-color: ${({ theme }) => theme.colors.background_primary};
  border-radius: 10px;

  padding: 0 3vw;
  margin-bottom: 2vh;

  @media (max-width: 539.9px) {
    padding: 5vh 8vw;
  }
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSize.paragraphBig}px;
  color: ${({ theme }) => theme.colors.text_blue};
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background_primary};
  border-radius: 10px;

  padding: 2vh 3vw 3vh;
  margin-bottom: 2vh;
  position: relative;

  @media (max-width: 539.9px) {
    padding: 5vh 10vw;
  }
`;

export const SubordinateInformation = styled.section`
  padding: 3vh 2vw 0;
  > h2 {
    font-weight: 600;
    font-size: ${({ theme }) => theme.fontSize.subtitle}px;
    color: ${({ theme }) => theme.colors.text_gray};
    margin-bottom: 3vh;
  }
`;

export const SubordinateInformationBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 3vh;

  @media (max-width: 539.9px) {
    grid-template-columns: 1fr;
  }

  > p {
    font-weight: 400;
    font-size: ${({ theme }) => theme.fontSize.paragraphMed}px;
    color: ${({ theme }) => theme.colors.text_gray_75};
    margin-bottom: 2vh;
    > span {
      font-weight: 500;
      color: ${({ theme }) => theme.colors.text_gray};
    }
  }
`;

export const ModalRemoveSubordinate = styled(Modal)`
  background-color: ${({ theme }) => theme.colors.background_primary};
  border-radius: 10px;
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.1);
  border: 0.5px solid ${({ theme }) => theme.colors.text_gray_15};
  max-height: 400px;
  max-width: 550px;
  width: 80%;
  padding: 40px 80px;
  margin: 25vh auto 0;
  position: relative;

  @media (max-width: 539.9px) {
    margin: 25vh 10vw 0;
    padding: 6vh 6vw;
  }

  > h1 {
    text-align: center;
    font-weight: 500;
    font-size: ${({ theme }) => theme.fontSize.subtitle}px;
    color: ${({ theme }) => theme.colors.text_gray};
    margin-bottom: 10px;

    @media (max-width: 539.9px) {
      font-size: ${({ theme }) => theme.fontSize.paragraphBig}px;
    }
  }

  > p {
    text-align: center;
    font-weight: 400;
    font-size: ${({ theme }) => theme.fontSize.paragraph}px;
    color: ${({ theme }) => theme.colors.text_gray_75};
    margin-bottom: 20px;

    @media (max-width: 539.9px) {
      font-size: ${({ theme }) => theme.fontSize.legend}px;
    }
  }

  > div {
    display: flex;
    margin-top: 5vh;
  }
`;

export const ButtonCloseModal = styled.button`
  position: absolute;
  top: 10%;
  left: 93%;

  @media (max-width: 539.9px) {
    top: 2vh;
    left: 90%;
  }

  > svg {
    width: 12px;
    margin: 0;
  }
`;
