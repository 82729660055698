import React from 'react';

import {
  IconChecked,
  IconDanger,
  IconDownloadDoc,
  IconMoneyRescue,
} from '@assets/icons';
import { ConfigValues } from '@config/index';
import Rescue from '@models/Rescue';
import { fromDateToHumanExpression, fromNumberToPrice } from '@utils/format';

import {
  Amount,
  Container,
  Content,
  Description,
  Download,
  Items,
  NoInfo,
  Paid,
  Pending,
  Status,
} from './styles';

interface IParams {
  rescues?: Rescue[];
}

const RescueItems: React.FC<IParams> = ({ rescues = [] }) => {
  return (
    <Container>
      {rescues?.length === 0 ? (
        <NoInfo>Nenhum resgate encontrado</NoInfo>
      ) : (
        <>
          {rescues.map(rescue => (
            <Items key={rescue.code}>
              <IconMoneyRescue width={50} />
              <Content>
                <Description>
                  <p>Resgate solicitado</p>
                  <span>Código: {rescue.code}</span>
                </Description>
                <Amount>
                  <span>Valor solicitado</span>
                  <p>{fromNumberToPrice(rescue.amount)}</p>
                  <span>{fromDateToHumanExpression(rescue.date)}</span>
                </Amount>
                <Status>
                  {rescue.status === ConfigValues.rescue.status.pending ? (
                    <Pending>
                      <IconDanger />
                      <p>Pendente</p>
                    </Pending>
                  ) : (
                    <Paid>
                      <IconChecked />
                      <p>Pago</p>
                    </Paid>
                  )}
                </Status>
                <Download>
                  <IconDownloadDoc />
                </Download>
              </Content>
            </Items>
          ))}
        </>
      )}
    </Container>
  );
};

export default RescueItems;
