import React, { useCallback, useEffect, useRef, useState } from 'react';

import { FormHandles } from '@unform/core';
import { useHistory, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import * as Yup from 'yup';

import {
  ImageBussinessDecisions,
  ImageFormUser,
  ImageLogotipo,
} from '@assets/images';
import {
  ButtonBlueGradient,
  HeaderBack,
  InputMask,
  InputSelect,
  InputText,
} from '@components/index';
import { ConfigValues } from '@config/index';
import { useSignUp } from '@hooks/signup';
import api from '@services/api';
import { formatDate } from '@utils/formatters';
import { generateDate } from '@utils/generators';
import { getValidationErrors } from '@utils/index';
import notify from '@utils/notify/toasts';

import { dataCompanySize } from './constants';
import { schemaLegal, schemaPhysical } from './schemaValidation';
import { IPhysicalFormData, ILegalFormData } from './typing';

import {
  Card,
  Container,
  Content,
  ContentTitle,
  ContentSubtitle,
  ContentAllCards,
  ContentAllCardsCardItem,
  ContentFormSignUp,
  ContentFormSignUpForm,
  ContentFormSignUpFormTermsOfUse,
  InternalLink,
} from './styles';

const PersonalData: React.FC = () => {
  const formLegalRef = useRef<FormHandles>(null);
  const formPhysicalRef = useRef<FormHandles>(null);
  const useQuery = () => new URLSearchParams(useLocation().search);
  const { signUpUserData, updateSignUpUserData } = useSignUp();
  const { push } = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [isLegalPerson, setIsLegalPerson] = useState<boolean>(
    signUpUserData.cnpj ? true : false,
  );
  const [subordinateOf, setSubordinateOf] = useState<string>(
    useQuery().get('s') || signUpUserData.subordinate_of || '',
  );

  const handleSubmitLegal = useCallback(async (data: ILegalFormData) => {
    try {
      setLoading(prevState => !prevState);

      formPhysicalRef.current?.reset();
      formLegalRef.current?.setErrors({});

      await schemaLegal.validate(data, {
        abortEarly: false,
      });

      updateSignUpUserData({
        name: data.legal_name,
        cnpj: data.legal_cnpj,
        email: data.legal_email,
        birth: generateDate.now().split(' ')[0],
        cellphone: data.legal_cellphone,
        company_size: data.legal_company_size,
        access_level: ConfigValues.user.access_level.high,
      });

      push('/cadastro/senha');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);

        formLegalRef.current?.setErrors(errors);

        const {
          legal_name,
          legal_cnpj,
          legal_email,
          legal_cellphone,
          legal_company_size,
        } = errors;

        if (legal_name) notify(legal_name, 'error');
        if (legal_cnpj) notify(legal_cnpj, 'error');
        if (legal_email) notify(legal_email, 'error');
        if (legal_cellphone) notify(legal_cellphone, 'error');
        if (legal_company_size) notify(legal_company_size, 'error');
      } else {
        notify(error.response.data.error, 'error');
      }
    } finally {
      setLoading(prevState => !prevState);
    }
  }, []);

  const handleSubmitPhysical = useCallback(async (data: IPhysicalFormData) => {
    try {
      setLoading(prevState => !prevState);

      formLegalRef.current?.reset();
      formPhysicalRef.current?.setErrors({});

      await schemaPhysical.validate(data, {
        abortEarly: false,
      });

      if (data.physical_subordinate_of) {
        const { data: responseAffiliateCompany } = await api.get(
          `/users/null?referral_code=${data.physical_subordinate_of}`,
        );

        if (responseAffiliateCompany.data.cnpj) {
          updateSignUpUserData({
            name: data.physical_name,
            cpf: data.physical_cpf,
            email: data.physical_email,
            birth: formatDate.removeMask(data.physical_birth),
            cellphone: data.physical_cellphone,
            access_level: ConfigValues.user.access_level.low,
            subordinate_of: data.physical_subordinate_of,
          });
          push('/cadastro/senha');
        } else {
          notify(
            'O código informado não pertence a uma empresa afiliada, por favor ',
            'error',
          );
        }
      } else {
        updateSignUpUserData({
          name: data.physical_name,
          cpf: data.physical_cpf,
          email: data.physical_email,
          birth: formatDate.removeMask(data.physical_birth),
          cellphone: data.physical_cellphone,
          access_level: ConfigValues.user.access_level.normal,
        });
        push('/cadastro/senha');
      }
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);

        formPhysicalRef.current?.setErrors(errors);

        const {
          physical_name,
          physical_cpf,
          physical_email,
          physical_birth,
          physical_cellphone,
        } = errors;

        if (physical_name) notify(physical_name, 'error');
        if (physical_cpf) notify(physical_cpf, 'error');
        if (physical_email) notify(physical_email, 'error');
        if (physical_birth) notify(physical_birth, 'error');
        if (physical_cellphone) notify(physical_cellphone, 'error');
      } else {
        notify(error.response.data.error, 'error');
      }
    } finally {
      setLoading(prevState => !prevState);
    }
  }, []);

  useEffect(() => {
    localStorage.removeItem('@Rebox-Client:signup-data');
  }, []);

  return (
    <Container>
      <HeaderBack />

      <Content>
        <ImageLogotipo width={200} height={50} />

        <ContentTitle>Que bom ter você aqui!</ContentTitle>

        <ContentSubtitle>
          Para prosseguir com seu cadastro precisamos te conhecer melhor.
          Preencha corretamente os dados abaixo.
        </ContentSubtitle>

        <ContentAllCards>
          <ContentAllCardsCardItem>
            <Card
              isLegalPerson={!isLegalPerson}
              onClick={() => {
                setIsLegalPerson(prevState => !prevState);
              }}
            >
              <p>Sou pessoa física</p>
            </Card>
          </ContentAllCardsCardItem>
          <ContentAllCardsCardItem>
            <Card
              isLegalPerson={isLegalPerson}
              onClick={() => {
                setIsLegalPerson(prevState => !prevState);
              }}
            >
              <p>Sou pessoa jurídica</p>
            </Card>
          </ContentAllCardsCardItem>
        </ContentAllCards>

        {!isLegalPerson ? (
          <ContentFormSignUp>
            <ContentFormSignUpForm
              ref={formPhysicalRef}
              onSubmit={handleSubmitPhysical}
              initialData={{
                physical_name: signUpUserData.cpf ? signUpUserData.name : '',
                physical_cpf: signUpUserData.cpf || '',
                physical_birth: signUpUserData.cpf
                  ? formatDate.addMask(signUpUserData.birth || '')
                  : '',
                physical_email: signUpUserData.cpf ? signUpUserData.email : '',
                physical_cellphone: signUpUserData.cpf
                  ? signUpUserData.cellphone
                  : '',
              }}
            >
              <InputText
                name="physical_name"
                placeholder="Nome completo"
                alertVisible
              />

              <InputMask
                name="physical_cpf"
                placeholder="CPF"
                alertVisible
                mask="999.999.999-99"
              />

              <InputMask
                name="physical_birth"
                placeholder="Data de nascimento"
                alertVisible
                mask="99/99/9999"
              />

              <InputText
                name="physical_email"
                placeholder="E-mail"
                alertVisible
              />

              <InputMask
                name="physical_cellphone"
                placeholder="Celular"
                alertVisible
                mask="55 (99) 9 9999-9999"
              />

              <ContentFormSignUpFormTermsOfUse>
                Ao continuar você concorda com nossos
                <InternalLink to="/"> termos de uso </InternalLink> e
                <InternalLink to="/"> políticas de privacidade</InternalLink>.
              </ContentFormSignUpFormTermsOfUse>

              <ButtonBlueGradient loading={loading}>
                Continuar
              </ButtonBlueGradient>
            </ContentFormSignUpForm>

            <ImageFormUser />
          </ContentFormSignUp>
        ) : (
          <ContentFormSignUp>
            <ContentFormSignUpForm
              ref={formLegalRef}
              onSubmit={handleSubmitLegal}
              initialData={{
                legal_name: signUpUserData.cnpj ? signUpUserData.name : '',
                legal_cnpj: signUpUserData.cnpj || '',
                legal_email: signUpUserData.cnpj ? signUpUserData.email : '',
                legal_cellphone: signUpUserData.cnpj
                  ? signUpUserData.cellphone
                  : '',
                legal_company_size: signUpUserData.cnpj
                  ? signUpUserData.company_size
                  : '',
              }}
            >
              <InputText
                name="legal_name"
                placeholder="Razão social/Nome fantasia"
                alertVisible
              />

              <InputMask
                name="legal_cnpj"
                placeholder="CNPJ"
                alertVisible
                mask="99.999.999/9999-99"
              />

              <InputText name="legal_email" placeholder="E-mail" alertVisible />

              <InputMask
                name="legal_cellphone"
                placeholder="Celular"
                alertVisible
                mask="(99) 9 9999-9999"
              />

              <ContentFormSignUpFormTermsOfUse>
                Ao continuar você concorda com nossos
                <InternalLink to="/"> termos de uso </InternalLink> e
                <InternalLink to="/"> políticas de privacidade</InternalLink>.
              </ContentFormSignUpFormTermsOfUse>

              <ButtonBlueGradient loading={loading}>
                Continuar
              </ButtonBlueGradient>
            </ContentFormSignUpForm>

            <ImageBussinessDecisions />
          </ContentFormSignUp>
        )}
      </Content>
      <ToastContainer />
    </Container>
  );
};

export default PersonalData;
