import styled from 'styled-components';

/*  320px
    360px
    480px
    540px
    640px
    768px
    960px
    1024px
    1366px
    1920px
    2560px
*/

export const Container = styled.div`
  padding: 3vh 3vw;
`;

export const Title = styled.h3`
  margin-bottom: 2vh;
`;

export const IndicationInformation = styled.section`
  padding: 3vh 2vw 0;
  > h2 {
    font-weight: 600;
    font-size: ${({ theme }) => theme.fontSize.subtitle}px;
    color: ${({ theme }) => theme.colors.text_gray};
    margin-bottom: 3vh;
  }
`;

export const IndicationInformationBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 3vh;

  @media (max-width: 539.9px) {
    grid-template-columns: 1fr;
  }

  > p {
    font-weight: 400;
    font-size: ${({ theme }) => theme.fontSize.paragraphMed}px;
    color: ${({ theme }) => theme.colors.text_gray_75};
    margin-bottom: 2vh;
    > span {
      font-weight: 500;
      color: ${({ theme }) => theme.colors.text_gray};
    }
  }
`;

export const FieldGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 0 2vw;
  margin-bottom: 2vh;

  @media (max-width: 540px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    align-items: unset;
  }

  @media (min-width: 768px) {
    max-width: 85%;
    max-width: 100%;
  }

  @media (min-width: 1024px) {
    max-width: 100%;
  }
`;

export const FieldSet = styled.div`
  padding-bottom: 2vh;
`;

export const FieldSetTitle = styled.p`
  text-align: start;
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSize.paragraph}px;
  color: ${({ theme }) => theme.colors.text_gray};
  padding: 1vh 0 1vh;
  padding-left: 1vw;
`;

export const FieldSetData = styled.span`
  text-align: start;
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSize.paragraph}px;
  color: ${({ theme }) => theme.colors.text_gray};
  padding: 1vh 0 2vh;
  padding-left: 1vw;
`;
