import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.background_primary};
  flex: 1;
  height: 100vh;
  padding-top: 8vh;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;

  margin: 0 auto;

  width: 100%;

  padding: 0 20px;

  @media (min-width: 1024px) {
    padding: 0;
  }

  @media (max-width: 767.9px) {
    padding: 0 15vw;
  }

  @media (max-width: 479.9px) {
    padding: 0 10vw;
  }
`;

export const ContentTitle = styled.h1`
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSize.subtitle}px;
  line-height: 39px;

  color: ${({ theme }) => theme.colors.text_secondary};

  margin-top: 60px;
`;

export const ContentSubtitle = styled.h2`
  font-weight: normal;
  font-size: ${({ theme }) => theme.fontSize.paragraphBig}px;
  line-height: 29px;
  color: ${({ theme }) => theme.colors.text_gray_75};

  max-width: 805px;

  margin-top: 22px;
`;

export const ContentBox = styled.section`
  display: flex;
  padding: 8vh 10vw;
  align-items: center;
`;

export const ButtonAccess = styled.button`
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2vh 1.5vw;
  background-color: ${({ theme }) => theme.colors.text_blue};
  border-radius: 8px;
  margin: 0;
  margin-bottom: 2vh;
  width: 100%;

  @media (max-width: 767.9px) {
    padding: 2vh 4vw;
  }

  > svg {
    width: 14px;
    margin-left: 4vw;

    @media (max-width: 767.9px) {
      margin-left: 8vw;
    }
  }
`;

export const ButtonShare = styled.div`
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2vh 1.5vw;
  border: 1px solid ${({ theme }) => theme.colors.text_blue};
  border-radius: 8px;
  justify-content: start;
  margin-bottom: 2vh;
  width: 100%;

  @media (max-width: 767.9px) {
    padding: 2vh 4vw;
  }

  > svg {
    width: 14px;
    margin-left: 4vw;
    path {
      fill: ${({ theme }) => theme.colors.text_blue};
    }

    @media (max-width: 767.9px) {
      margin-left: 8vw;
    }
  }
`;

export const TextButtonAccess = styled.p`
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSize.paragraph}px;
  color: ${({ theme }) => theme.colors.background_primary};
`;

export const TextButtonShare = styled.p`
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSize.paragraph}px;
  color: ${({ theme }) => theme.colors.text_blue};
`;

export const ContainerThanks = styled.div`
  width: 100%;
  max-width: 400px;
  > svg {
    width: 100%;
    height: 100%;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    padding-left: 8vw;
  }
`;
