import React, { useState, useEffect } from 'react';

import { Redirect, Route, RouterProps, useLocation } from 'react-router-dom';

import { ConfigValues } from '@config/index';
import Session from '@models/Session';
import User from '@models/User';
import api from '@services/api';
import sessionService from '@services/session.service';

interface IRoutePrivated {
  component: React.FC<any>;
  path: string;
  exact?: boolean;
}

const ROUTE_REDIRECT = '/login';

const PrivateRoute = ({
  component: Component,
  exact = true,
  ...rest
}: IRoutePrivated) => {
  const useQuery = () => new URLSearchParams(useLocation().search);

  const [sessionId, setSessionId] = useState<string>(useQuery().get('s') || '');
  const [token, setToken] = useState<string>(useQuery().get('t') || '');
  const [authorized, setAuthorized] = useState<boolean>();

  const routeIsAuthorized = async () => {
    if (sessionId && token) {
      const { data: responseSession } = await api.get(`/sessions/${sessionId}`);

      const session: Session = responseSession.data;

      if (session.type.toLowerCase() !== ConfigValues.session.type.login) {
        setAuthorized(false);
      }

      if (token !== session.token) {
        setAuthorized(false);
      }

      const { data: responseUser } = await api.get(
        `/users/${session.users_id}`,
      );

      sessionService.updateLocalOrSessionStorage({
        sessions_id: session.id,
        token: session.token,
        user: responseUser.data,
      });

      setAuthorized(true);
    } else {
      setAuthorized(sessionService.checked());
    }
  };

  useEffect(() => {
    routeIsAuthorized();
  }, []);

  return (
    <Route
      exact={exact}
      {...rest}
      render={props =>
        authorized === true ? (
          <Component {...props} />
        ) : (
          <>
            {authorized === false ? (
              <Redirect
                to={{
                  pathname: ROUTE_REDIRECT,
                  state: { from: props.location },
                }}
              />
            ) : (
              <></>
            )}
          </>
        )
      }
    />
  );
};

export default PrivateRoute;
