import { Form } from '@unform/web';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

/* VERSÃO MOBILE
 * - 1024px
 * - 960px
 * - 768px
 * - 540px
 * - 480px
 * - 360px
 * - 320px
 */
export const Container = styled.div`
  background: ${({ theme }) => theme.colors.background_secondary};
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    display: grid;
    grid-template-rows: 16vh 84vh;
    grid-template-columns: 2.2fr 7.8fr;
    grid-template-areas:
      'header header'
      'menu main';
  }
`;

export const Header = styled.div`
  grid-area: header;
  padding: 2vh 2vw;

  @media (max-width: 1023.9px) {
    height: 16vh;
  }
`;

export const Main = styled.div`
  grid-area: main;
  overflow: auto;

  padding: 0 2vw;

  @media (min-width: 1024px) {
    padding: 0 2vw 2vh 0;
  }
`;

export const Menu = styled.nav`
  @media (min-width: 1023.9px) {
    grid-area: menu;
    padding: 0 1vw 2vh 2vw;
  }
`;

export const NamePage = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 10vh;
  background-color: ${({ theme }) => theme.colors.background_primary};
  border-radius: 10px;

  padding: 0 3vw;
  margin-bottom: 2vh;

  @media (max-width: 539.9px) {
    padding: 5vh 8vw;
  }
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSize.paragraphBig}px;
  color: ${({ theme }) => theme.colors.text_blue};
`;

export const Content = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background_primary};
  border-radius: 10px;

  padding: 5vh 4vw;
  margin-bottom: 2vh;

  @media (max-width: 539.9px) {
    padding: 5vh 10vw;
  }
`;

export const ButtonBack = styled(Link)`
  display: flex;
  align-items: center;

  > svg {
    width: 14px;
  }

  > span {
    font-weight: 600;
    font-size: ${({ theme }) => theme.fontSize.paragraph}px;
    color: ${({ theme }) => theme.colors.text_blue};
    margin-left: 0.5vw;
  }
`;

export const ContentForm = styled.section`
  padding: 4vh 0;

  > form {
    @media (min-width: 1024px) {
      max-width: 80vw;
    }
    @media (max-width: 767.9px) {
      max-width: 60vw;
    }
    @media (max-width: 479.9px) {
      max-width: 100vw;
    }

    > .btn-submit {
      @media (min-width: 767.9px) {
        max-width: 43vw;
      }
      @media (min-width: 1024px) {
        width: 30vw;
        max-width: 200px;
      }
    }
  }
`;

export const FormHeader = styled.div`
  margin-bottom: 4vh;
`;

export const ContentFormBankAccount = styled(Form)``;

export const Subtitle = styled.h2`
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSize.paragraphBig}px;
  color: ${({ theme }) => theme.colors.text_gray};

  @media (max-width: 539.9px) {
    font-size: ${({ theme }) => theme.fontSize.paragraphBig}px;
  }
`;

export const Legend = styled.p`
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSize.paragraph}px;
  color: ${({ theme }) => theme.colors.text_gray_75};
  margin-top: 1vh;

  @media (max-width: 539.9px) {
    font-size: ${({ theme }) => theme.fontSize.legend}px;
    margin-bottom: ${props => (props.className !== 'account' ? '0' : '3vh')};
  }
`;

export const FieldGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 2vh;
  grid-gap: 0 2vw;

  @media (max-width: 767.9px) {
    grid-template-columns: 1fr;
    grid-gap: 2vh 0;
  }
`;

export const InputGroup = styled.div`
  display: grid;
  grid-template-columns: 6fr 3fr;
  grid-gap: 0 2vw;

  @media (max-width: 539.9px) {
    grid-template-columns: 1fr;
    grid-gap: 2vh 0;
  }
`;
