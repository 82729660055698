import * as Yup from 'yup';

import { ConfigValues } from '@config/index';

export const schema = Yup.object().shape({
  bank_type: Yup.string().required('É obrigatório selecionar o tipo da conta.'),

  pix_type: Yup.string().when('bank_type', {
    is: value => value === ConfigValues.bank_account.type.pix,
    then: Yup.string().required(
      'É obrigatório selecionar o tipo da chave do pix.',
    ),
    otherwise: Yup.string(),
  }),

  pix_key: Yup.string()
    .when('bank_type', {
      is: value => value === ConfigValues.bank_account.type.pix,
      then: Yup.string().required('É obrigatório digitar sua chave do pix.'),
      otherwise: Yup.string(),
    })
    .when('pix_type', {
      is: value => value === ConfigValues.bank_account.pix_type.email,
      then: Yup.string().email(
        'Um e-mail deve ser informado na sua chave do pix.',
      ),
    }),

  owner_name: Yup.string().notRequired(),

  bank_name: Yup.string().when('bank_type', {
    is: value =>
      value === ConfigValues.bank_account.type.current_account ||
      value === ConfigValues.bank_account.type.savings_account,
    then: Yup.string().required(
      'É obrigatório selecionar o nome do banco de sua conta.',
    ),
    otherwise: Yup.string(),
  }),

  bank_agency: Yup.string().when('bank_type', {
    is: value =>
      value === ConfigValues.bank_account.type.current_account ||
      value === ConfigValues.bank_account.type.savings_account,
    then: Yup.string().min(
      4,
      'É obrigatório informar o número da agência com no mínimo 4 dígitos.',
    ),
    otherwise: Yup.string(),
  }),

  bank_agency_digit: Yup.string().notRequired(),

  bank_number: Yup.string().when('bank_type', {
    is: value =>
      value === ConfigValues.bank_account.type.current_account ||
      value === ConfigValues.bank_account.type.savings_account,
    then: Yup.string().min(
      5,
      'É obrigatório informar o número da sua conta com no mínimo 5 dígitos.',
    ),
    otherwise: Yup.string(),
  }),

  bank_number_digit: Yup.string().notRequired(),
});
