import React, { useCallback, useState } from 'react';

import { Link } from 'react-router-dom';

import {
  IconRegister,
  IconDocument,
  IconSocialMedia,
  IconHourglass,
  IconTransfer,
  IconAddGray,
  IconWhatsapp,
} from '@assets/icons';
import { ImageCashSavings, ImageSocialGirl } from '@assets/images';
import { HeaderHome, FooterHome } from '@components/index';
import HomeSidebar from '@components/menus/HomeSidebar';
import { ConfigBase } from '@config/index';

import {
  Container,
  FlashyInformation,
  FlashyTitle,
  FlashySubtitle,
  RangeInformation,
  RangeInformationItems,
  RangeInformationItem,
  RangeInformationItemContainer,
  RangeInformationItemTitle,
  RangeInformationItemText,
  SolutionsInformation,
  SolutionsInformationBox,
  SolutionsBoxItems,
  SolutionsItem,
  SolutionsItemDescription,
  SolutionsItemDescriptionTitle,
  SolutionsItemDescriptionText,
  SolutionsSubtitle,
  SolutionsTitle,
  ButtonChoosePlan,
  ChoosePlanLink,
  AffiliateRules,
  AffiliateRulesLink,
  CommonQuestions,
  CommonQuestionsTitle,
  CommonQuestionsSubtitle,
  CommonQuestionsItems,
  CommonQuestionsItem,
  CommonQuestionsItemTitle,
  CommonQuestionsItemTouch,
  CommonQuestionsItemDescription,
  CommonQuestionsItemDescriptionText,
  Whatsapp,
} from './styles';

const LandingPage: React.FC = () => {
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  const [
    isDetailsCommonQuestions,
    setIsDetailsCommonQuestions,
  ] = useState<number>(0);

  const handleOpenSidebar = useCallback(() => {
    setSidebarIsOpen(!sidebarIsOpen);
  }, [sidebarIsOpen]);

  const showDetailsCommonQuestions = (value: number) => {
    setIsDetailsCommonQuestions(value);
  };

  return (
    <Container sidebarIsOpen={sidebarIsOpen}>
      <HomeSidebar
        handleOpenSidebar={handleOpenSidebar}
        sidebarIsOpen={sidebarIsOpen}
      />

      <HeaderHome handleOpenSidebar={handleOpenSidebar} />

      <FlashyInformation>
        <FlashyTitle>Venha ganhar dinheiro com a Rebox!</FlashyTitle>
        <FlashySubtitle>
          Nesse <b>momento de crise</b>, o que faz a diferença é ajudar você ou
          seu negócio a ter uma <b>renda extra!</b>
        </FlashySubtitle>
      </FlashyInformation>

      <RangeInformation>
        <RangeInformationItems>
          <RangeInformationItem>
            <RangeInformationItemContainer>
              <RangeInformationItemTitle>
                Compartilhe e ganhe
              </RangeInformationItemTitle>
              <RangeInformationItemText>
                Através de seu código ou link de indicação você vende nossos
                planos e ganha comissões.
              </RangeInformationItemText>
            </RangeInformationItemContainer>
            <ImageSocialGirl />
          </RangeInformationItem>

          <RangeInformationItem>
            <ImageCashSavings />
            <RangeInformationItemContainer>
              <RangeInformationItemTitle>
                Resgate a qualquer hora
              </RangeInformationItemTitle>
              <RangeInformationItemText>
                Assim que seu indicado fizer o primeiro pagamento, você poderá
                acessar o Painel do afiliado e solicitar o resgate. É simples e
                Rápido!
              </RangeInformationItemText>
            </RangeInformationItemContainer>
          </RangeInformationItem>
        </RangeInformationItems>
      </RangeInformation>

      <SolutionsInformation>
        <SolutionsInformationBox>
          <SolutionsTitle>Como funciona?</SolutionsTitle>
          <SolutionsSubtitle>
            Confira tudo o que precisa para você se preocupar apenas em indicar!
          </SolutionsSubtitle>
          <SolutionsBoxItems>
            <SolutionsItem>
              <IconRegister />
              <SolutionsItemDescription>
                <SolutionsItemDescriptionTitle>
                  1º Você realiza o seu cadastro
                </SolutionsItemDescriptionTitle>
                <SolutionsItemDescriptionText>
                  Como pessoa física ou jurídica! Simples e{' '}
                  <b>sem burocracia</b>.
                </SolutionsItemDescriptionText>
              </SolutionsItemDescription>
            </SolutionsItem>

            <SolutionsItem>
              <IconDocument />
              <SolutionsItemDescription>
                <SolutionsItemDescriptionTitle>
                  2º Leia todo o material de apoio
                </SolutionsItemDescriptionTitle>
                <SolutionsItemDescriptionText>
                  Enviaremos para o e-mail cadastrado todo o material que
                  precisa <b>para começar a indicar</b> com segurança!
                </SolutionsItemDescriptionText>
              </SolutionsItemDescription>
            </SolutionsItem>

            <SolutionsItem>
              <IconSocialMedia />
              <SolutionsItemDescription>
                <SolutionsItemDescriptionTitle>
                  3º Compartilhe seu código ou link com seus amigos
                </SolutionsItemDescriptionTitle>
                <SolutionsItemDescriptionText>
                  Acessando o painel de afiliado, você verá um botão{' '}
                  {`"Compartilhar"`}. <b>Divulgue em suas redes sociais</b>{' '}
                  favoritas!
                </SolutionsItemDescriptionText>
              </SolutionsItemDescription>
            </SolutionsItem>

            <SolutionsItem>
              <IconHourglass />
              <SolutionsItemDescription>
                <SolutionsItemDescriptionTitle>
                  4º Aguarde até que o indicado efetive a compra
                </SolutionsItemDescriptionTitle>
                <SolutionsItemDescriptionText>
                  Ele ou ela precisa <b>se cadastrar</b> como nosso cliente,{' '}
                  <b>assinar</b> um de nossos planos e{' '}
                  <b>efetuar o primeiro pagamento</b>.
                </SolutionsItemDescriptionText>
              </SolutionsItemDescription>
            </SolutionsItem>

            <SolutionsItem>
              <IconTransfer />
              <SolutionsItemDescription>
                <SolutionsItemDescriptionTitle>
                  5º Solicite resgates para sua conta
                </SolutionsItemDescriptionTitle>
                <SolutionsItemDescriptionText>
                  Através do painel de afiliado <b>você solicita</b> um valor de
                  seu saldo disponível e a <b>Rebox deposita</b> em sua conta!
                </SolutionsItemDescriptionText>
              </SolutionsItemDescription>
            </SolutionsItem>
          </SolutionsBoxItems>

          <ChoosePlanLink to="/cadastro">
            <ButtonChoosePlan>Clique aqui e cadastre-se</ButtonChoosePlan>
          </ChoosePlanLink>

          <AffiliateRules>
            <AffiliateRulesLink
              to={{
                pathname:
                  'https://rebox.com.br/external/anexos/regras-afiliados.pdf',
              }}
              target="_blank"
            >
              <p>Regras e condições</p>
            </AffiliateRulesLink>
          </AffiliateRules>
        </SolutionsInformationBox>
      </SolutionsInformation>

      <CommonQuestions>
        <CommonQuestionsTitle>Perguntas frequentes</CommonQuestionsTitle>
        <CommonQuestionsSubtitle>
          Alguém já nos perguntou o que você quer saber:
        </CommonQuestionsSubtitle>
        <CommonQuestionsItems>
          <CommonQuestionsItem>
            <CommonQuestionsItemTouch
              isActive={isDetailsCommonQuestions === 1}
              onClick={() =>
                showDetailsCommonQuestions(
                  isDetailsCommonQuestions === 1 ? 0 : 1,
                )
              }
            >
              <CommonQuestionsItemTitle>
                O que é o afiliado Rebox?
              </CommonQuestionsItemTitle>
              <IconAddGray />
            </CommonQuestionsItemTouch>
            <CommonQuestionsItemDescription
              isActive={isDetailsCommonQuestions === 1}
            >
              <CommonQuestionsItemDescriptionText>
                É uma plataforma online onde você se cadastra gratuitamente e
                recebe um código e link de indicação. Com esse link você poderá
                vender os planos da Rebox e ser comissionado sempre que alguém
                comprar.
              </CommonQuestionsItemDescriptionText>
            </CommonQuestionsItemDescription>
          </CommonQuestionsItem>

          <CommonQuestionsItem>
            <CommonQuestionsItemTouch
              isActive={isDetailsCommonQuestions === 2}
              onClick={() =>
                showDetailsCommonQuestions(
                  isDetailsCommonQuestions === 2 ? 0 : 2,
                )
              }
            >
              <CommonQuestionsItemTitle>
                Como faço pra me cadastrar?
              </CommonQuestionsItemTitle>
              <IconAddGray />
            </CommonQuestionsItemTouch>
            <CommonQuestionsItemDescription
              isActive={isDetailsCommonQuestions === 2}
            >
              <CommonQuestionsItemDescriptionText>
                Basta acessar o site afiliado.rebox.com.br e clicar no botão{' '}
                {`"Área do afiliado"`}. Depois, clique em {`"cadastre-se aqui"`}{' '}
                e preencha o formulário.
              </CommonQuestionsItemDescriptionText>
            </CommonQuestionsItemDescription>
          </CommonQuestionsItem>

          <CommonQuestionsItem>
            <CommonQuestionsItemTouch
              isActive={isDetailsCommonQuestions === 3}
              onClick={() =>
                showDetailsCommonQuestions(
                  isDetailsCommonQuestions === 3 ? 0 : 3,
                )
              }
            >
              <CommonQuestionsItemTitle>
                Posso cadastrar minha empresa?
              </CommonQuestionsItemTitle>
              <IconAddGray />
            </CommonQuestionsItemTouch>
            <CommonQuestionsItemDescription
              isActive={isDetailsCommonQuestions === 3}
            >
              <CommonQuestionsItemDescriptionText>
                Sim. O cadastro pode ser feito para empresas ou pessoas físicas.
                Na afiliação da empresa você poderá cadastrar vendedores para
                trabalharem em sua rede.
              </CommonQuestionsItemDescriptionText>
            </CommonQuestionsItemDescription>
          </CommonQuestionsItem>

          <CommonQuestionsItem>
            <CommonQuestionsItemTouch
              isActive={isDetailsCommonQuestions === 4}
              onClick={() =>
                showDetailsCommonQuestions(
                  isDetailsCommonQuestions === 4 ? 0 : 4,
                )
              }
            >
              <CommonQuestionsItemTitle>
                Depois de criar meu link, como faço para vender?
              </CommonQuestionsItemTitle>
              <IconAddGray />
            </CommonQuestionsItemTouch>
            <CommonQuestionsItemDescription
              isActive={isDetailsCommonQuestions === 4}
            >
              <CommonQuestionsItemDescriptionText>
                É só enviar o link para seus amigos e interessados em adquirir o
                plano da Rebox. Assim que eles clicarem em seu link serão
                direcionados para a página de planos da Rebox. Após escolherem o
                plano e efetuarem o pagamento, essa comissão estará disponível
                em seu painel do afiliado.
              </CommonQuestionsItemDescriptionText>
            </CommonQuestionsItemDescription>
          </CommonQuestionsItem>

          <CommonQuestionsItem>
            <CommonQuestionsItemTouch
              isActive={isDetailsCommonQuestions === 5}
              onClick={() =>
                showDetailsCommonQuestions(
                  isDetailsCommonQuestions === 5 ? 0 : 5,
                )
              }
            >
              <CommonQuestionsItemTitle>
                Há limites de indicações?
              </CommonQuestionsItemTitle>
              <IconAddGray />
            </CommonQuestionsItemTouch>
            <CommonQuestionsItemDescription
              isActive={isDetailsCommonQuestions === 5}
            >
              <CommonQuestionsItemDescriptionText>
                Não! Você poderá indicar quantas pessoas quiser.
              </CommonQuestionsItemDescriptionText>
            </CommonQuestionsItemDescription>
          </CommonQuestionsItem>

          <CommonQuestionsItem>
            <CommonQuestionsItemTouch
              isActive={isDetailsCommonQuestions === 6}
              onClick={() =>
                showDetailsCommonQuestions(
                  isDetailsCommonQuestions === 6 ? 0 : 6,
                )
              }
            >
              <CommonQuestionsItemTitle>
                Quanto posso ganhar sendo um afiliado da Rebox?
              </CommonQuestionsItemTitle>
              <IconAddGray />
            </CommonQuestionsItemTouch>
            <CommonQuestionsItemDescription
              isActive={isDetailsCommonQuestions === 6}
            >
              <CommonQuestionsItemDescriptionText>
                Se você indicar apenas 05 clientes por dia, trabalhando de
                segunda a sexta, para o plano de R$ 34,90, você ganhará R$
                2.500,00.
              </CommonQuestionsItemDescriptionText>
            </CommonQuestionsItemDescription>
          </CommonQuestionsItem>

          <CommonQuestionsItem>
            <CommonQuestionsItemTouch
              isActive={isDetailsCommonQuestions === 7}
              onClick={() =>
                showDetailsCommonQuestions(
                  isDetailsCommonQuestions === 7 ? 0 : 7,
                )
              }
            >
              <CommonQuestionsItemTitle>
                Como faço para resgatar minhas comissões?
              </CommonQuestionsItemTitle>
              <IconAddGray />
            </CommonQuestionsItemTouch>
            <CommonQuestionsItemDescription
              isActive={isDetailsCommonQuestions === 7}
            >
              <CommonQuestionsItemDescriptionText>
                Você poderá solicitar o resgate de suas comissões todo dia 15 e
                último dia útil de cada mês, através do seu perfil de afiliado,
                clicando na opção {`"Resgatar"`}. Após a solicitação a Rebox tem
                até 5 dias úteis para transferir a sua comissão para sua conta
                bancária cadastrada.
              </CommonQuestionsItemDescriptionText>
            </CommonQuestionsItemDescription>
          </CommonQuestionsItem>

          <CommonQuestionsItem>
            <CommonQuestionsItemTouch
              isActive={isDetailsCommonQuestions === 8}
              onClick={() =>
                showDetailsCommonQuestions(
                  isDetailsCommonQuestions === 8 ? 0 : 8,
                )
              }
            >
              <CommonQuestionsItemTitle>
                Qual é o valor da comissão?
              </CommonQuestionsItemTitle>
              <IconAddGray />
            </CommonQuestionsItemTouch>
            <CommonQuestionsItemDescription
              isActive={isDetailsCommonQuestions === 8}
            >
              <CommonQuestionsItemDescriptionText>
                Clique na aba {`"Regras de afiliação"`} e saiba o valor da
                comissão e da recorrência de sua rede.
              </CommonQuestionsItemDescriptionText>
            </CommonQuestionsItemDescription>
          </CommonQuestionsItem>

          {/* <CommonQuestionsItem>
            <CommonQuestionsItemTouch
              isActive={isDetailsCommonQuestions === 9}
              onClick={() =>
                showDetailsCommonQuestions(
                  isDetailsCommonQuestions === 9 ? 0 : 9,
                )
              }
            >
              <CommonQuestionsItemTitle>
                Ganharei comissão apenas na primeira mensalidade?
              </CommonQuestionsItemTitle>
              <IconAddGray />
            </CommonQuestionsItemTouch>
            <CommonQuestionsItemDescription
              isActive={isDetailsCommonQuestions === 9}
            >
              <CommonQuestionsItemDescriptionText>
                Não. Além da primeira mensalidade, você ganhará um percentual
                das futuras mensalidades, enquanto esse indicado for cliente da
                Rebox.
              </CommonQuestionsItemDescriptionText>
            </CommonQuestionsItemDescription>
          </CommonQuestionsItem> */}
        </CommonQuestionsItems>
      </CommonQuestions>

      <FooterHome />
      <Whatsapp
        target="_blank"
        to={{
          pathname: `${ConfigBase.whatsapp.apiBaseURL}/send?phone=${ConfigBase.phoneContactRebox}&text=Olá! Gostaria de me tornar um afiliado(a), pode me ajudar?`,
        }}
      >
        <IconWhatsapp />
      </Whatsapp>
    </Container>
  );
};

export default LandingPage;
