import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

/* VERSÃO MOBILE
 * - 1024px
 * - 960px
 * - 768px
 * - 540px
 * - 480px
 * - 360px
 * - 320px
 */
export const Container = styled.div`
  background: ${({ theme }) => theme.colors.background_secondary};
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    display: grid;
    grid-template-rows: 16vh 84vh;
    grid-template-columns: 2.2fr 7.8fr;
    grid-template-areas:
      'header header'
      'menu main';
  }
`;

export const Header = styled.div`
  grid-area: header;
  padding: 2vh 2vw;

  @media (max-width: 1023.9px) {
    height: 16vh;
  }
`;

export const Main = styled.div`
  grid-area: main;
  overflow: auto;

  padding: 0 2vw;

  @media (min-width: 1024px) {
    padding: 0 2vw 2vh 0;
  }
`;

export const Menu = styled.nav`
  @media (min-width: 1023.9px) {
    grid-area: menu;
    padding: 0 1vw 2vh 2vw;
  }
`;

export const NamePage = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 10vh;
  background-color: ${({ theme }) => theme.colors.background_primary};
  border-radius: 10px;

  padding: 0 3vw;
  margin-bottom: 2vh;

  @media (max-width: 539.9px) {
    padding: 5vh 8vw;
  }
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSize.paragraphBig}px;
  color: ${({ theme }) => theme.colors.text_blue};
`;

export const Content = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background_primary};
  border-radius: 10px;

  padding: 0 4vw 5vh;
  margin-bottom: 2vh;

  @media (max-width: 539.9px) {
    padding: 5vh 10vw;
  }
`;

export const ButtonAddVehicle = styled(Link)`
  background-color: ${({ theme }) => theme.colors.background_secondary};
  width: 40px;
  height: 40px;
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    background-color: ${({ theme }) => theme.colors.text_gray_07};
  }

  @media (min-width: 768px) {
    width: 35px;
    height: 35px;
  }

  > svg {
    width: 16px;

    @media (min-width: 768px) {
      width: 14px;
    }
  }
`;

export const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 150px;
`;

export const List = styled.div`
  @media (min-width: 540px) {
    padding: 40px 0 15px 0;
  }
`;

export const Subtitle = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.paragraphBig}px;
  color: ${({ theme }) => theme.colors.text_gray};
`;

export const NavigationPages = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2vh;

  > p {
    padding: 1vh 1vw;
  }

  > button {
    padding: 1vh 1vw;
    background-color: ${({ theme }) => theme.colors.background_secondary};
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  > button.invert {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;
