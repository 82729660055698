import React, { useCallback, useEffect, useRef, useState } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useLocation } from 'react-router-dom';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import { MoonLoader } from 'react-spinners';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';

import { IconArrowLeft, IconArrowRight, IconClose } from '@assets/icons';
import {
  ButtonBlueGradient,
  ButtonOutline,
  HeaderMenu,
  InputText,
  MenuSideBar,
} from '@components/index';
import { ConfigValues, ConfigBase } from '@config/index';
import BankAccount from '@models/BankAccount';
import User from '@models/User';
import api from '@services/api';
import rescueService from '@services/rescue.service';
import sessionService from '@services/session.service';
import userService from '@services/user.service';
import { fromDateToHumanExpression, fromNumberToPrice } from '@utils/format';
import { getValidationErrors } from '@utils/index';
import notify from '@utils/notify/toasts';
import { changeMonthInPeriod, getPeriodNow } from '@utils/validatorDate';

import RescueItems from './RescueItems';
import { schema } from './schemaValidation';
import {
  IIndicators,
  IPeriod,
  IRescueFormData,
  IResponseIndication,
  IResponseRescue,
} from './typing';

import {
  Alert,
  BoxGroup,
  ButtonCloseModal,
  ButtonSocial,
  Container,
  Content,
  Date,
  Header,
  HeaderOptions,
  Indicators,
  LinkDirect,
  List,
  Loading,
  Main,
  Menu,
  ModalRescue,
  ModalShared,
  MonthsButtonNav,
  MonthsFilter,
  NumberIndications,
  ReferralCode,
  Revenues,
} from './styles';

const Dashboard: React.FC = () => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [auth, setAuth] = useState<string>(useQuery().get('auth') || '');
  const [modalShareIsOpen, setModalShareIsOpen] = useState(false);
  const [modalRescueIsOpen, setModalRescueIsOpen] = useState(false);
  const [user, setUser] = useState<User>(sessionService.getUser());
  const [indications, setIndications] = useState<IResponseIndication>();
  const [indicators, setIndicators] = useState<IIndicators>({
    effective_indications: 0,
    indications_made: 0,
    canceled_indications: 0,
  });
  const [rescues, setRescues] = useState<IResponseRescue>();
  const [period, setPeriod] = useState<IPeriod>(getPeriodNow());
  const [bankAccount, setBankAccount] = useState<BankAccount>();
  const share_link = `${ConfigBase.linkShared}${user.referral_code}&e=i`;

  const changeValueModalShare = () => {
    setModalShareIsOpen(!modalShareIsOpen);
  };

  const changeValueModalRescue = () => {
    // notify(
    //   'Estamos em manutenção, logo será possível solicitar um resgate!',
    //   'info',
    // );
    setModalRescueIsOpen(!modalRescueIsOpen);
  };

  const getIndications = async () => {
    const response = await api.get(
      `/indications?id_who_indicated=${user?.id}&status=${ConfigValues.indication.status.effective}`,
    );
    setIndications(response.data);
  };

  const getUser = async () => {
    const { data } = await userService.getOne({ users_id: user?.id });
    setUser(data);
    userService.updateLocalStorage(user);

    const indicators_new: IIndicators = {
      effective_indications: 0,
      indications_made: 0,
      canceled_indications: 0,
    };

    user.indications?.forEach(item => {
      if (item.status === ConfigValues.indication.status.created) {
        indicators_new.indications_made += 1;
      }
      if (item.status === ConfigValues.indication.status.effective) {
        indicators_new.effective_indications += 1;
      }
      if (item.status === ConfigValues.indication.status.canceled) {
        indicators_new.canceled_indications += 1;
      }
    });

    setIndicators(indicators_new);
  };

  const getRescues = async () => {
    setLoading(true);
    const { period_start, period_end } = period;
    const response = await rescueService.getManyByPeriod({
      period_start,
      period_end,
      users_id: user.id,
    });
    setLoading(false);
    setRescues(response);
  };

  const getBankAccount = useCallback(async () => {
    try {
      const response_bank_accounts = await api.get(
        `/users/bank-accounts?users_id=${user.id}`,
      );
      setBankAccount(response_bank_accounts.data.data[0]);
    } catch (error: any) {
      if (error.response) {
        notify(
          error.response.data.error ||
            'Houve um erro ao tentar buscar seus dados bancários.',
          'error',
        );
      }
    }
  }, []);

  const changePeriod = (action: string) => {
    setPeriod({
      period_start: changeMonthInPeriod(period.period_start, action),
      period_end: changeMonthInPeriod(period.period_end, action),
    });
  };

  const handleSubmit = useCallback(async (data: IRescueFormData) => {
    try {
      setLoading(prevState => !prevState);

      await schema.validate(data, {
        abortEarly: true,
      });

      const { data: response } = await api.post(`/rescues`, {
        amount: parseFloat(
          data.amount.replace(/[^0-9,]*/g, '').replace(',', '.'),
        ),
        users_id: user.id,
      });

      changeValueModalRescue();

      notify(response.header.message, 'success');

      getRescues();
      getUser();
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);

        const { amount } = errors;

        if (amount) notify(amount, 'error');
      } else if (error.response) notify(error.response.data.error, 'error');
    } finally {
      setLoading(prevState => !prevState);
    }
  }, []);

  useEffect(() => {
    if (auth === 'not') {
      notify('Você não tem autorização para acessar a página', 'error');
    }
    getIndications();
    getUser();
    getBankAccount();
  }, []);

  useEffect(() => {
    getRescues();
  }, [period]);

  return (
    <>
      <Container>
        <Header>
          <HeaderMenu />
        </Header>

        <Menu>
          <MenuSideBar />
        </Menu>

        <Main>
          <Alert isShow={!bankAccount}>
            <p>
              <strong>Olá {user.name.split(' ')[0]}!</strong> Gostaríamos de
              comunicar que o preenchimento do seus dados bancários está
              pendente .{' '}
              <LinkDirect to="/conta-bancaria">Entre aqui</LinkDirect> e
              preencha para, mais tarde, tudo correr bem com os seus resgates.
            </p>
          </Alert>

          <BoxGroup>
            {user.access_level !== ConfigValues.user.access_level.low && (
              <Indicators>
                <NumberIndications>
                  <p>
                    {indications?.header.total >= 0
                      ? `${indications?.header.total}`
                      : '--'}
                  </p>
                  <span>
                    {indications?.header.total === 1
                      ? 'Indicação'
                      : 'Indicações'}
                  </span>
                </NumberIndications>
                <Revenues>
                  <span>Faturamento total</span>
                  <p>
                    {indications?.header.total_cash_bonus === 0
                      ? 'R$ 0,00'
                      : fromNumberToPrice(
                          indications?.header.total_cash_bonus || -1,
                        )}
                  </p>
                </Revenues>
                <Revenues>
                  <span>Saldo disponível</span>
                  <p>
                    {user?.balance === 0
                      ? 'R$ 0,00'
                      : fromNumberToPrice(user?.balance || -1)}
                  </p>
                </Revenues>
              </Indicators>
            )}

            {user.access_level === ConfigValues.user.access_level.low && (
              <Indicators>
                <NumberIndications>
                  <p>
                    {indicators?.effective_indications >= 0
                      ? `${indicators?.effective_indications}`
                      : '--'}
                  </p>
                  <span>
                    {indicators?.effective_indications === 1
                      ? 'Indicação efetivada'
                      : 'Indicações efetivadas'}
                  </span>
                </NumberIndications>
                <Revenues>
                  <p style={{ marginBottom: '10px' }}>
                    {indicators?.indications_made >= 0
                      ? `${indicators?.indications_made}`
                      : '--'}
                  </p>
                  <span style={{ marginBottom: 0 }}>
                    {indicators?.indications_made === 1
                      ? 'Indicação realizada'
                      : 'Indicações realizadas'}
                  </span>
                </Revenues>
                <Revenues>
                  <span>Saldo disponível</span>
                  <p>
                    {user?.balance === 0
                      ? 'R$ 0,00'
                      : fromNumberToPrice(user?.balance || -1)}
                  </p>
                </Revenues>
                {/* <Revenues>
                  <p style={{ marginBottom: '10px' }}>
                    {indicators?.canceled_indications >= 0
                      ? `${indicators?.canceled_indications}`
                      : '--'}
                  </p>
                  <span style={{ marginBottom: 0 }}>
                    {indicators?.canceled_indications === 1
                      ? 'Indicação cancelada'
                      : 'Indicações canceladas'}
                  </span>
                </Revenues> */}
              </Indicators>
            )}

            <ReferralCode>
              <div>
                <span>Seu código de indicação</span>
                <p>{user.referral_code || 'Não informado'}</p>
              </div>
              <ButtonOutline
                onClick={changeValueModalShare}
                loading={false}
                icon={'IconShare'}
              >
                Compartilhar
              </ButtonOutline>
            </ReferralCode>
          </BoxGroup>
          <Content>
            <HeaderOptions>
              <ButtonBlueGradient
                onClick={changeValueModalRescue}
                style={{ maxWidth: 180 }}
              >
                Solicitar resgate
              </ButtonBlueGradient>

              <MonthsFilter>
                <MonthsButtonNav onClick={() => changePeriod('back')}>
                  <IconArrowLeft />
                </MonthsButtonNav>
                <Date>
                  {fromDateToHumanExpression(period.period_start, 'day')}
                </Date>
                <MonthsButtonNav onClick={() => changePeriod('next')}>
                  <IconArrowRight />
                </MonthsButtonNav>
              </MonthsFilter>
            </HeaderOptions>
            {loading ? (
              <Loading>
                <MoonLoader size={30} color="#07359B" />
              </Loading>
            ) : (
              <List>
                <RescueItems rescues={rescues?.data} />
              </List>
            )}
          </Content>
        </Main>
      </Container>
      <ModalShared
        isOpen={modalShareIsOpen}
        onRequestClose={changeValueModalShare}
        contentLabel="Compartilhar"
      >
        <ButtonCloseModal onClick={changeValueModalShare}>
          <IconClose />
        </ButtonCloseModal>
        <h1>Compartilhe o link com o seu código de indicação</h1>
        <p>{share_link}</p>
        <ButtonSocial>
          <FacebookShareButton
            quote={
              'Olá amigos! Gostaria de indicar um serviço de assistência veicular 24h na Rebox. Planos a partir de R$ 19,90 por mês. Corre lá e se cadastra usando o meu link.'
            }
            url={share_link}
          >
            <FacebookIcon />
          </FacebookShareButton>

          <WhatsappShareButton
            title={'Venha fazer parte da Rebox!'}
            url={share_link}
          >
            <WhatsappIcon />
          </WhatsappShareButton>

          <TwitterShareButton
            title={'Venha fazer parte da Rebox!'}
            url={share_link}
          >
            <TwitterIcon />
          </TwitterShareButton>

          <TelegramShareButton
            title={'Venha fazer parte da Rebox!'}
            url={share_link}
          >
            <TelegramIcon />
          </TelegramShareButton>

          <LinkedinShareButton
            title={'Venha fazer parte da Rebox!'}
            url={share_link}
          >
            <LinkedinIcon />
          </LinkedinShareButton>

          <EmailShareButton
            subject={'Venha fazer parte da Rebox!'}
            body={
              'Olá amigos! Gostaria de indicar um serviço de assistência veicular 24h na Rebox. Planos a partir de R$ 19,90 por mês. Corre lá e se cadastra usando o meu link.'
            }
            url={share_link}
          >
            <EmailIcon />
          </EmailShareButton>
        </ButtonSocial>
      </ModalShared>
      <ModalRescue
        isOpen={modalRescueIsOpen}
        onRequestClose={changeValueModalRescue}
        contentLabel="Resgate"
      >
        <h1>Informe o valor do resgate</h1>
        <p>
          Você pode solicitar no mínimo o valor de{' '}
          {fromNumberToPrice(ConfigValues.rescue.limit_min)}
        </p>
        <ButtonCloseModal onClick={changeValueModalRescue}>
          <IconClose />
        </ButtonCloseModal>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <InputText
            name="amount"
            onChange={value => {
              let amount = value.target.value;
              amount = amount.replace(/\D/g, '');
              amount = amount.replace(/(\d)(\d{2})$/, '$1,$2');
              amount = amount.replace(/(?=(\d{3})+(\D))\B/g, '.');
              value.target.value = amount;
            }}
            placeholder={`Ex: ${fromNumberToPrice(
              ConfigValues.rescue.limit_min,
            )}`}
          />
          <ButtonBlueGradient style={{ marginTop: 20 }} loading={loading}>
            Resgatar
          </ButtonBlueGradient>
        </Form>
      </ModalRescue>
      <ToastContainer />
    </>
  );
};

export default Dashboard;
