import React from 'react';

import { useHistory } from 'react-router-dom';

import { formatDate, formatMoney } from '@utils/formatters';

import { indicationStatus } from './constants';
import { IParams } from './typing';

import {
  Container,
  NoInfo,
  ItemList,
  ItemListLabel,
  ItemListText,
  ItemListMobile,
  MobileItem,
  MobileItemBox,
  MobileItemBoxLabel,
  MobileItemBoxText,
} from './styles';

const Item: React.FC<IParams> = ({ payments }) => {
  const { push } = useHistory();
  const { innerWidth } = window;

  return (
    <Container>
      {payments?.length === 0 ? (
        <NoInfo>Não há histórico de pagamentos a ser exibido.</NoInfo>
      ) : (
        <>
          {innerWidth > 960 ? (
            <>
              <ItemList>
                <ItemListLabel>Valor</ItemListLabel>
                <ItemListLabel>Status</ItemListLabel>
                <ItemListLabel>Criado em</ItemListLabel>
                <ItemListLabel>Pago em</ItemListLabel>
              </ItemList>
              {payments?.map(item => (
                <ItemList key={item.id}>
                  <ItemListText>
                    {formatMoney.fromNumberToPrice(item.amount || 0)}
                  </ItemListText>
                  <ItemListText>
                    {indicationStatus[item.status?.toLowerCase() || '']}
                  </ItemListText>
                  <ItemListText>
                    {formatDate.addMask(item.date_created?.split(' ')[0] || '')}
                  </ItemListText>
                  <ItemListText>
                    {formatDate.addMask(item.pay_day?.split(' ')[0] || '')}
                  </ItemListText>
                </ItemList>
              ))}
            </>
          ) : (
            <ItemListMobile>
              {payments?.map(item => (
                <MobileItem key={item.id}>
                  <MobileItemBox>
                    <MobileItemBoxLabel>Valor</MobileItemBoxLabel>
                    <MobileItemBoxText>
                      {formatMoney.fromNumberToPrice(item.amount || 0)}
                    </MobileItemBoxText>
                  </MobileItemBox>

                  <MobileItemBox>
                    <MobileItemBoxLabel>Status</MobileItemBoxLabel>
                    <MobileItemBoxText>
                      {indicationStatus[item.status?.toLowerCase() || '']}
                    </MobileItemBoxText>
                  </MobileItemBox>

                  <MobileItemBox>
                    <MobileItemBoxLabel>Criado em</MobileItemBoxLabel>
                    <MobileItemBoxText>
                      {formatDate.addMask(
                        item.date_created?.split(' ')[0] || '',
                      )}
                    </MobileItemBoxText>
                  </MobileItemBox>

                  <MobileItemBox>
                    <MobileItemBoxLabel>Pago em</MobileItemBoxLabel>
                    <MobileItemBoxText>
                      {formatDate.addMask(item.pay_day?.split(' ')[0] || '')}
                    </MobileItemBoxText>
                  </MobileItemBox>
                </MobileItem>
              ))}
            </ItemListMobile>
          )}
        </>
      )}
    </Container>
  );
};

export default Item;
