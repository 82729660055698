import * as Yup from 'yup';

export const schemaPhysical = Yup.object().shape({
  physical_name: Yup.string().required('É obrigatório informar o campo nome'),

  physical_cpf: Yup.string().notRequired(),

  physical_birth: Yup.string().notRequired(),

  physical_email: Yup.string()
    .required('É obrigatório informar um e-mail')
    .email('Insira um endereço de e-mail válido'),

  physical_cellphone: Yup.string().required(
    'É obrigatório informar seu número de celular',
  ),
});

export const schemaLegal = Yup.object().shape({
  legal_name: Yup.string().required('É obrigatório informar o campo nome'),

  legal_cnpj: Yup.string().notRequired(),

  legal_email: Yup.string()
    .required('É obrigatório informar um e-mail')
    .email('Insira um endereço de e-mail válido'),

  legal_cellphone: Yup.string().required(
    'É obrigatório informar seu número de celular',
  ),
});
