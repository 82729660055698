import React, { useCallback, useEffect, useState } from 'react';

import { FormControlLabel, Switch } from '@material-ui/core';
import { Form } from '@unform/web';
import { Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { IconClose } from '@assets/icons';
import {
  ButtonBlueGradient,
  ButtonOutline,
  HeaderMenu,
  InputSelect,
  InputText,
  MenuSideBar,
} from '@components/index';
import { ConfigValues } from '@config/index';
import Setting from '@models/Setting';
import User from '@models/User';
import api from '@services/api';
import sessionService from '@services/session.service';
import notify from '@utils/notify/toasts';

import { percentage } from './constants';

import {
  ButtonCloseModal,
  Container,
  Description,
  Header,
  Item,
  ItemName,
  ItemText,
  Items,
  Main,
  Menu,
  ModalCancelAccount,
  NamePage,
  SettingsItems,
  Subtitle,
  Title,
} from './styles';

const Settings: React.FC = () => {
  const [settings, setSettings] = useState<Setting>();
  const [user, setUser] = useState<User>(sessionService.getUser());
  const [isEmailAlertsOn, setIsEmailAlertsOn] = useState(true);
  const [isNewsByEmailOn, setIsNewsByEmailOn] = useState(true);
  const [commissionTransfer, setCommissionTransfer] = useState<string>();
  const [commissionFixed, setCommissionFixed] = useState<number>(0);
  const [commissionRecurring, setCommissionRecurring] = useState<number>(0);
  const [isAccountCanceled, setIsAccountCanceled] = useState(false);
  const [modalCancelAccountIsOpen, setModalCancelAccountIsOpen] = useState(
    false,
  );

  const getSettings = async () => {
    const {
      data: { data },
    } = await api.get(`/users/settings?users_id=${user.id}`);

    setSettings(data);

    const { receive_news_by_email, to_be_notified_by_email } = data;

    setIsEmailAlertsOn(to_be_notified_by_email);
    setIsNewsByEmailOn(receive_news_by_email);
  };

  const changeModalCancelAccountIsOpen = () => {
    setModalCancelAccountIsOpen(!modalCancelAccountIsOpen);
  };

  const changeEmailAlertsOn = () => {
    setIsEmailAlertsOn(!isEmailAlertsOn);
  };

  const changeNewsByEmailOn = () => {
    setIsNewsByEmailOn(!isNewsByEmailOn);
  };

  const updateSettings = async () => {
    api
      .put(`/users/settings`, {
        users_id: user.id,
        language: settings?.language,
        theme: settings?.theme,
        to_be_notified_by_email: isEmailAlertsOn,
        receive_news_by_email: isNewsByEmailOn,
        commission_transfer:
          commissionTransfer || settings?.commission_transfer,
        commission_fixed: commissionFixed || settings?.commission_fixed,
        commission_recurring:
          commissionRecurring || settings?.commission_recurring,
      })
      .then(response => {
        setSettings(response.data.data);
        // notify(response.data.header.message, 'success');
      })
      .catch(error => {
        notify(error.response.data.error, 'error');
      });
  };

  const cancelAccount = useCallback(async () => {
    user.status = 'deleted';
    user.is_partner = false;

    api
      .put(`/users/${user.id}`, user)
      .then(response => {
        const {
          data: { data },
        } = response;

        const TIME_LIMIT_NOTIFY = 5000;

        notify('Sua conta foi desativada temporariamente.', 'success');

        sessionService.updateLocalOrSessionStorage({
          token: sessionService.getToken() || '',
          user,
          sessions_id: sessionService.getId() || '',
        });

        sessionService.cleanLocalStorage();

        setTimeout(
          () => setIsAccountCanceled(!isAccountCanceled),
          TIME_LIMIT_NOTIFY,
        );
      })
      .catch(error => {
        console.log('Error: ', error);
        notify('Houve um erro ao tentar cancelar sua conta.', 'error');
      });
  }, []);

  useEffect(() => {
    getSettings();
  }, []);

  useEffect(() => {
    updateSettings();
  }, [
    isEmailAlertsOn,
    isNewsByEmailOn,
    commissionTransfer,
    commissionFixed,
    commissionRecurring,
  ]);

  return (
    <>
      <Container>
        {isAccountCanceled && <Redirect to="/login" exact />}

        <Header>
          <HeaderMenu />
        </Header>

        <Menu>
          <MenuSideBar />
        </Menu>
        <Main>
          <NamePage>
            <Title>Configuração</Title>
          </NamePage>
          <SettingsItems>
            <Subtitle>Notificações</Subtitle>
            <Items>
              <Item>
                <ItemText>
                  Desejo receber todos os tipos de avisos, alertas e
                  notificações em geral por e-mail
                </ItemText>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isEmailAlertsOn}
                      onChange={changeEmailAlertsOn}
                      name="checkedB"
                      color="primary"
                      size="small"
                    />
                  }
                  label={isEmailAlertsOn ? 'On' : 'Off'}
                />
              </Item>

              <Item>
                <ItemText>
                  Desejo receber novidades, promoções, posts da Rebox por e-mail
                </ItemText>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isNewsByEmailOn}
                      onChange={changeNewsByEmailOn}
                      name="checkedB"
                      color="primary"
                      size="small"
                    />
                  }
                  label={isNewsByEmailOn ? 'On' : 'Off'}
                />
              </Item>
            </Items>
          </SettingsItems>

          <SettingsItems>
            <Subtitle>Comissionamento</Subtitle>
            <Items>
              <Item className={'commission'}>
                <Description>
                  <ItemName>Deseja repassar suas comissões?</ItemName>
                  <ItemText>
                    Não é obrigatório. Você pode escolher para quem deseja
                    repassar suas comissões e o quanto.
                  </ItemText>
                </Description>
                <Form
                  ref={null}
                  onSubmit={() => {
                    console.log('');
                  }}
                >
                  <InputSelect
                    style={{ width: 190 }}
                    name="commission_transfer"
                    options={[
                      {
                        label: 'Para mim',
                        value:
                          ConfigValues.setting.commission_transfer.undefined,
                      },
                      {
                        label: 'Aos indicados',
                        value: ConfigValues.setting.commission_transfer.client,
                      },
                    ]}
                    selectedDefault={
                      commissionTransfer || settings?.commission_transfer
                    }
                    placeholder="Para quem?"
                    onChange={value => {
                      setCommissionTransfer(value.target.value);

                      if (
                        value.target.value ===
                        ConfigValues.setting.commission_transfer.undefined
                      ) {
                        setCommissionFixed(0);
                        setCommissionRecurring(0);
                      }
                    }}
                  />
                </Form>
              </Item>
              <Item className={'commission'}>
                <Description>
                  <ItemName>Repassar a comissão da 1ª mensalidade</ItemName>
                  <ItemText>
                    Ao indicar um cliente você ganha uma comissão fixa na 1ª
                    mensalidade. Informe quantos % deseja repassar
                  </ItemText>
                </Description>
                <Form
                  ref={null}
                  onSubmit={() => {
                    console.log('');
                  }}
                >
                  <InputSelect
                    style={{ width: 190 }}
                    name="commission_fixed"
                    options={percentage}
                    selectedDefault={`${
                      commissionFixed || settings?.commission_fixed
                    }`}
                    placeholder="Valor %"
                    onChange={value => {
                      setCommissionFixed(
                        Number.parseInt(value.target.value, 10),
                      );
                    }}
                  />
                </Form>
              </Item>
              <Item className={'commission'}>
                <Description>
                  <ItemName>Repassar comissão recorrente.</ItemName>
                  <ItemText>
                    Enquanto seu cliente indicado mantiver o contrato ativo você
                    ganha uma comissão recorrente. Informe quantos % deseja
                    repassar
                  </ItemText>
                </Description>
                <Form
                  ref={null}
                  onSubmit={() => {
                    console.log('');
                  }}
                >
                  <InputSelect
                    disabled={
                      user.access_level === ConfigValues.user.access_level.low
                    }
                    style={{ width: 190 }}
                    name="commission_recurrent"
                    options={percentage}
                    selectedDefault={`${
                      commissionRecurring || settings?.commission_recurring
                    }`}
                    placeholder="Valor %"
                    onChange={value => {
                      setCommissionRecurring(
                        Number.parseInt(value.target.value, 10),
                      );
                    }}
                  />
                </Form>
              </Item>
            </Items>
          </SettingsItems>

          <SettingsItems>
            <Subtitle>Conta</Subtitle>
            <Items>
              <Item className={'account'}>
                <Description>
                  <ItemName>Desejo deletar minha conta.</ItemName>
                  <ItemText className={'account'}>
                    Todos os seus dados serão mantidos por até 30 dias, caso
                    tenha interesse em recuperar a conta.
                  </ItemText>
                </Description>
                <ButtonBlueGradient
                  onClick={changeModalCancelAccountIsOpen}
                  style={{ maxWidth: 200, fontSize: 13 }}
                >
                  Cancelar minha conta
                </ButtonBlueGradient>
              </Item>
            </Items>
          </SettingsItems>
        </Main>
        <ToastContainer />
      </Container>
      <ModalCancelAccount
        isOpen={modalCancelAccountIsOpen}
        onRequestClose={changeModalCancelAccountIsOpen}
        contentLabel="Cancelar conta"
      >
        <ButtonCloseModal onClick={changeModalCancelAccountIsOpen}>
          <IconClose />
        </ButtonCloseModal>
        <h1>Ainda é muito cedo...</h1>
        <p>Você tem certeza que deseja cancelar sua conta?</p>
        <div>
          <ButtonBlueGradient
            onClick={changeModalCancelAccountIsOpen}
            style={{ height: 40, marginRight: 20, fontSize: 14 }}
          >
            Não, vou ficar
          </ButtonBlueGradient>
          <ButtonOutline onClick={cancelAccount}>Tenho certeza</ButtonOutline>
        </div>
      </ModalCancelAccount>
    </>
  );
};

export default Settings;
