import React, { useCallback, useRef, useState } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import * as Yup from 'yup';

import { ImageLogotipo } from '@assets/images';
import { ButtonBlueGradient, HeaderBack, InputText } from '@components/index';
import api from '@services/api';
import { getValidationErrors } from '@utils/index';
import notify from '@utils/notify/toasts';

import { schemaEmail, schemaCode, schemaPassword } from './schemaValidation';

import {
  Container,
  Title,
  Content,
  DontHaveAccountText,
  ForgetPassword,
  ForgetPasswordLink,
  SignUpLink,
  Subtitle,
} from './styles';

interface IForm1 {
  email: string;
}

interface IForm2 {
  code: string;
}

interface IForm3 {
  password: string;
  confirm_password: string;
}

const RecoverPassword: React.FC = () => {
  const { push } = useHistory();
  const formRef1 = useRef<FormHandles>(null);
  const formRef2 = useRef<FormHandles>(null);
  const formRef3 = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [referralAttempts, setReferralAttempts] = useState(0);
  const [numberOfCodeErrors, setNumberOfCodeErrors] = useState(0);

  const handleSubmitEmail = useCallback(async (data: IForm1) => {
    try {
      setLoading(prevState => !prevState);

      formRef1.current?.setErrors({});

      await schemaEmail.validate(data, {
        abortEarly: false,
      });

      const { data: responseUserWithEmail } = await api.get(
        `/users?email=${data.email}`,
      );

      if (responseUserWithEmail.data.length > 0) {
        const response = await api.post(`/users/recover-password`, {
          email: data.email,
        });

        notify(
          'Um código de validação foi enviado para o e-mail cadastrado.',
          'success',
        );

        sessionStorage.setItem(
          '@Rebox:User:RecoverPassword',
          JSON.stringify(response.data.data),
        );
        sessionStorage.setItem('@Rebox:User:Email', data.email);

        setStep(2);
      } else {
        notify(
          'E-mail não encontrado em nosso sistema, por favor tente novamente.',
          'error',
        );
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);

        formRef1.current?.setErrors(errors);

        const { email } = errors;

        if (email) notify(email, 'error');
      } else {
        notify(error.response.data.error, 'error');
      }
    } finally {
      setLoading(prevState => !prevState);
    }
  }, []);

  const handleSubmitCode = useCallback(async (data: IForm2) => {
    try {
      if (numberOfCodeErrors < 4) {
        setLoading(prevState => !prevState);

        formRef2.current?.setErrors({});

        await schemaCode.validate(data, {
          abortEarly: false,
        });

        const recoverPassword = JSON.parse(
          sessionStorage.getItem('@Rebox:User:RecoverPassword') || '{}',
        );

        const response = await api.post(
          `/users/recover-password/${recoverPassword.id}`,
          {
            code: data.code,
          },
        );

        setStep(3);
      } else {
        notify(
          'O número de tentativas se esgotou, por favor entre em contato com a nossa central.',
          'info',
        );
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);

        formRef2.current?.setErrors(errors);

        const { code } = errors;

        if (code) notify(code, 'error');
      } else {
        notify(error.response.data.error, 'error');
        setNumberOfCodeErrors(numberOfCodeErrors + 1);
      }
    } finally {
      setLoading(prevState => !prevState);
    }
  }, []);

  const handleSubmitPassword = useCallback(async (data: IForm3) => {
    try {
      setLoading(prevState => !prevState);

      formRef3.current?.setErrors({});

      await schemaPassword.validate(data, {
        abortEarly: false,
      });

      const recoverPassword = JSON.parse(
        sessionStorage.getItem('@Rebox:User:RecoverPassword') || '{}',
      );

      const response = await api.put(
        `/users/${recoverPassword.users_id}/password`,
        {
          password: data.password,
        },
      );

      const { header } = response.data;

      notify(header.message, 'success');
      sessionStorage.removeItem('@Rebox:User:RecoverPassword');
      sessionStorage.removeItem('@Rebox:User:Email');

      setTimeout(() => push('/login'), 4000);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef3.current?.setErrors(errors);

        const { password, confirm_password } = errors;

        if (password) notify(password, 'error');
        if (confirm_password) notify(confirm_password, 'error');
      } else if (error.response.data)
        notify(error.response.data.error, 'error');
    } finally {
      setLoading(prevState => !prevState);
    }
  }, []);

  const resendCode = async () => {
    try {
      if (referralAttempts < 4) {
        const users_email = sessionStorage.getItem('@Rebox:User:Email') || '';
        const response = await api.post(`/users/recover-password`, {
          email: users_email,
        });
        setReferralAttempts(referralAttempts + 1);
      } else {
        notify(
          'O número de tentativas se esgotou, por favor entre em contato com a nossa central.',
          'info',
        );
      }
    } catch (error) {
      notify(
        'Houve um erro ao reenviar o código de validação, por favor tente novamente mais tarde.',
        'error',
      );
    }
  };

  return (
    <Container>
      <HeaderBack />
      <Content>
        <ImageLogotipo width={100} height={50} />

        {step === 1 && (
          <>
            <Title>Recuperar senha</Title>
            <Subtitle>Digite abaixo o e-mail cadastrado</Subtitle>
            <Form
              // initialData={{ email: 'cliente@gmail.com', password: '123123' }}
              ref={formRef1}
              onSubmit={handleSubmitEmail}
            >
              <InputText
                name="email"
                placeholder="E-mail cadastrado"
                type="email"
              />

              <ButtonBlueGradient style={{ marginTop: 20 }} loading={loading}>
                Continuar
              </ButtonBlueGradient>

              <DontHaveAccountText>Lembrei minha senha</DontHaveAccountText>

              <SignUpLink to="/login">Acessar minha conta</SignUpLink>
            </Form>
          </>
        )}

        {step === 2 && (
          <>
            <Title>Código de validação</Title>
            <Subtitle>Digite abaixo o código recebido no e-mail</Subtitle>
            <Form
              // initialData={{ email: 'cliente@gmail.com', password: '123123' }}
              ref={formRef2}
              onSubmit={handleSubmitCode}
            >
              <InputText name="code" placeholder="Código recebido" />
              <button className="resend" onClick={resendCode}>
                Não recebi o código
              </button>

              <ButtonBlueGradient style={{ marginTop: 20 }} loading={loading}>
                Continuar
              </ButtonBlueGradient>

              <DontHaveAccountText>Lembrei minha senha</DontHaveAccountText>

              <SignUpLink to="/login">Acessar minha conta</SignUpLink>
            </Form>
          </>
        )}

        {step === 3 && (
          <>
            <Title>Alterar senha</Title>
            <Subtitle>Digite sua nova senha abaixo</Subtitle>
            <Form ref={formRef3} onSubmit={handleSubmitPassword}>
              <InputText
                name="password"
                placeholder="Nova senha"
                showIconPassword
              />

              <InputText
                name="confirm_password"
                placeholder="Confirme a nova senha"
                showIconPassword
              />

              <ButtonBlueGradient style={{ marginTop: 20 }} loading={loading}>
                Alterar a senha
              </ButtonBlueGradient>

              <DontHaveAccountText>Lembrei minha senha</DontHaveAccountText>

              <SignUpLink to="/login">Acessar minha conta</SignUpLink>
            </Form>
          </>
        )}
      </Content>
      <ToastContainer />
    </Container>
  );
};

export default RecoverPassword;
