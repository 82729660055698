// src/pages/SignIn/index.tsx
import React, { useCallback, useRef, useState } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import * as Yup from 'yup';

import { IconBack } from '@assets/icons';
import { ImageLogotipo } from '@assets/images';
import { ButtonBlueGradient, InputText } from '@components/index';
import { ConfigValues } from '@config/index';
import api from '@services/api';
import sessionService from '@services/session.service';
import { getValidationErrors } from '@utils/index';
import notify from '@utils/notify/toasts';

import { schema } from './schemaValidation';

import {
  Container,
  Content,
  DontHaveAccountText,
  ForgetPassword,
  ForgetPasswordLink,
  SignUpLink,
  Title,
  ButtonHeaderBack,
  ButtonBack,
  BackText,
} from './styles';

export interface ISignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(sessionService.checked());
  const [redirect, setRedirect] = useState('');

  const handleSubmit = useCallback(async (data: ISignInFormData) => {
    try {
      notify('Estamos efetuando o seu login. Por favor aguarde...', 'info');

      setLoading(prevState => !prevState);

      formRef.current?.setErrors({});

      // Remover possíveis espaços que o usuário digitou antes ou depois do email
      data.email = data.email.trim();

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await api.post('/sessions', {
        email: data.email,
        password: data.password,
      });

      const { user, token, id } = response.data.data;

      if (
        user.is_partner === false ||
        user.status !== ConfigValues.user.status.active
      ) {
        notify('Usuário bloqueado ou não possui permissão de acesso.', 'error');
        await api.put(`/sessions/${id}`);
        return;
      }

      sessionService.updateLocalOrSessionStorage({
        user,
        token,
        sessions_id: id,
      });

      notify(response.data.header.message, 'success');

      setLoggedIn(true);
    } catch (error: any) {
      // if (error instanceof Yup.ValidationError) {
      //   const errors = getValidationErrors(error);
      console.log(error);
      //   formRef.current?.setErrors(errors);
      // } else if (error)
      // notify(
      //   error.response.data.error || 'Não foi possível efetuar o login.',
      //   'error',
      // );
    } finally {
      setLoading(prevState => !prevState);
    }
  }, []);

  return (
    <Container>
      {loggedIn && <Redirect to="/painel" exact />}
      {redirect === 'landingpage' && <Redirect to="/" exact />}
      <ButtonHeaderBack>
        <ButtonBack onClick={() => setRedirect('landingpage')}>
          <IconBack width={14} />
          <BackText>Voltar</BackText>
        </ButtonBack>
      </ButtonHeaderBack>
      <Content>
        <ImageLogotipo width={100} height={50} />

        <Title>Acesse sua conta</Title>

        <Form ref={formRef} onSubmit={handleSubmit}>
          <InputText name="email" placeholder="E-mail" />
          <InputText name="password" showIconPassword placeholder="Senha" />

          <ForgetPassword>
            <ForgetPasswordLink to="/esqueci-minha-senha">
              Esqueci minha senha
            </ForgetPasswordLink>
          </ForgetPassword>

          <ButtonBlueGradient style={{ marginTop: 20 }} loading={loading}>
            Entrar
          </ButtonBlueGradient>

          <DontHaveAccountText>Ainda não possui conta?</DontHaveAccountText>

          <SignUpLink to="/cadastro">Cadastre-se aqui</SignUpLink>
        </Form>
      </Content>
      <ToastContainer />
    </Container>
  );
};

export default SignIn;
