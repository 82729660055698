import React from 'react';

import { useTranslation } from 'react-i18next';

import { IconUser } from '@assets/icons';
import { ConfigBase } from '@config/index';

import {
  BorderBottom,
  ButtonAreaClient,
  ButtonAreaClientText,
  Container,
  IconContainer,
  IconMenuClose,
  Navbar,
  NavbarText,
} from './styles';

interface ISidebarProps {
  handleOpenSidebar(): void;
  sidebarIsOpen: boolean;
}

const HomeSidebar: React.FC<ISidebarProps> = ({
  handleOpenSidebar,
  sidebarIsOpen,
}) => {
  const { t } = useTranslation(['Home2']);

  return (
    <Container className={`${sidebarIsOpen ? 'sidebar-opened' : ''}`}>
      <IconContainer>
        <IconMenuClose size={40} onClick={handleOpenSidebar} />
      </IconContainer>

      <Navbar>
        <NavbarText to="/">
          Início
          <BorderBottom />
        </NavbarText>

        <NavbarText
          to={{ pathname: `${ConfigBase.clientBaseURL}` }}
          target="_blank"
        >
          Quero ser cliente
          <BorderBottom />
        </NavbarText>

        <NavbarText
          to={{ pathname: `${ConfigBase.providerBaseURL}/cadastro` }}
          target="_blank"
        >
          Quero ser prestador
          <BorderBottom />
        </NavbarText>

        <ButtonAreaClient to="/login" style={{ height: 55, maxWidth: 230 }}>
          <IconUser />
          <ButtonAreaClientText>Área do afiliado</ButtonAreaClientText>
        </ButtonAreaClient>
      </Navbar>
    </Container>
  );
};

export default HomeSidebar;
