import { formatDateBR } from '@utils/validatorDate';
import React from 'react';
import { useHistory } from 'react-router-dom';

import User from '@models/User';
import { IconArrowRight } from '@assets/icons';

import {
  Container,
  NoInfo,
  ListDesktop,
  ListMobile,
  ItemList,
  MobileCard,
} from './styles';

interface IParams {
  users?: User[];
}

const messageStatus: any = {
  active: 'Ativo',
  inactive: 'Inativo',
  suspended: 'Suspenso',
  incomplete: 'Incompleto',
  deleted: 'Cancelado',
};

const Item: React.FC<IParams> = ({ users }) => {
  const { push } = useHistory();
  return (
    <Container>
      {users?.length === 0 ? (
        <NoInfo>Nenhum promotor foi encontrado</NoInfo>
      ) : (
        <>
          <ListDesktop>
            <ItemList style={{ fontWeight: 'bold' }}>
              <p>Nome</p>
              <p>CPF/CNPJ</p>
              <p>E-mail</p>
              <p>Celular</p>
              {/* <p>Status</p> */}
            </ItemList>
            {users?.map(item => (
              <ItemList
                key={item.id}
                onClick={() => push(`/promotor/${item.id}`)}
              >
                <p>{item.name.toUpperCase()}</p>
                <p>{item.cpf || item.cnpj}</p>
                <p>{item.email}</p>
                <p>{item.cellphone}</p>
                <IconArrowRight />
                {/* <p>{messageStatus[item.user?.status || 'active']}</p> */}
              </ItemList>
            ))}
          </ListDesktop>
          <ListMobile>
            {users?.map(item => (
              <MobileCard
                key={item.id}
                onClick={() => push(`/promotor/${item.id}`)}
              >
                <IconArrowRight />
                <div>
                  <span>Nome completo</span>
                  <p>{item.name.toUpperCase()}</p>
                </div>
                <div>
                  <span>CPF/CNPJ</span>
                  <p>{item.cpf || item.cnpj}</p>
                </div>
                <div>
                  <span>E-mail</span>
                  <p>{item.email}</p>
                </div>
                <div>
                  <span>Celular</span>
                  <p>{item.cellphone}</p>
                </div>
                {/* <p>{messageStatus[item.user?.status || 'active']}</p> */}
              </MobileCard>
            ))}
          </ListMobile>
        </>
      )}
    </Container>
  );
};

export default Item;
