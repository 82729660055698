import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 4vh;

  @media (min-width: 768px) {
    > div:nth-child(1n + 2) {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
`;

export const NoInfo = styled.p`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.paragraph}px;
  color: ${({ theme }) => theme.colors.text_gray_75};
`;

export const ListDesktop = styled.div`
  @media (max-width: 767.9px) {
    display: none;
  }
`;

export const ItemList = styled.div`
  display: grid;
  grid-template-columns: 0.8fr 0.8fr 1.2fr 1fr 0.2fr;
  grid-gap: 0 2vw;
  padding: 2vh 0;
  cursor: pointer;

  :nth-child(1n + 2) {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.background_secondary};
  }

  > p {
    word-break: break-all;
    font-size: ${({ theme }) => theme.fontSize.paragraph}px;
    color: ${({ theme }) => theme.colors.text_gray};
  }
`;

export const ListMobile = styled.div`
  display: none;
  @media (max-width: 767.9px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3vh 2vw;
  }

  @media (max-width: 639.9px) {
    grid-template-columns: 1fr;
  }
`;

export const MobileCard = styled.div`
  padding: 1vh 3vw 4vh;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: relative;

  > div {
    margin-top: 3vh;

    > span {
      font-weight: 400;
      font-size: ${({ theme }) => theme.fontSize.paragraph}px;
      color: ${({ theme }) => theme.colors.text_gray_50};
    }

    > p {
      font-weight: 500;
      font-size: ${({ theme }) => theme.fontSize.paragraph}px;
      color: ${({ theme }) => theme.colors.text_gray};
      margin-top: 5px;
    }
  }

  > svg {
    position: absolute;
    top: 3vh;
    right: 2vw;
  }
`;
