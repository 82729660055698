import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { FormHandles } from '@unform/core';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import * as Yup from 'yup';

import { IconClose, IconProfileUser, IconUpdateImage } from '@assets/icons';
import {
  ButtonBlueGradient,
  HeaderMenu,
  InputMask,
  InputText,
  MenuSideBar,
} from '@components/index';
import Address from '@models/Address';
import User from '@models/User';
import api from '@services/api';
import sessionService from '@services/session.service';
import { formatDate } from '@utils/formatters';
import { getValidationErrors } from '@utils/index';
import notify from '@utils/notify/toasts';

import { schema } from './schemaValidation';
import { IProfileData, IRequest } from './typing';

import {
  ButtonCloseModal,
  ChangePassword,
  Container,
  Content,
  ContentUserForm,
  UserForm,
  FieldGroup,
  FieldModal,
  Header,
  HeaderContent,
  HeaderContentBoxImageProfile,
  LabelUpdateAvatar,
  LinkSearchCep,
  Main,
  Menu,
  ModalPassword,
  NamePage,
  Subtitle,
  Title,
  UserImage,
} from './styles';

const regexRemoveAllNonDigits = /[^\d]+/g;

const Profile: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const formRefPass = useRef<FormHandles>(null);
  const [user, setUser] = useState<User>(sessionService.getUser());
  const [address, setAddress] = useState<Address>();
  const [modalPasswordIsOpen, setModalPasswordIsOpen] = useState<boolean>(
    false,
  );
  const [loadingUpdateUser, setLoadingUpdateUser] = useState<boolean>(false);
  const [loadingUpdatePassword, setLoadingUpdatePassword] = useState<boolean>(
    false,
  );
  const [userImageUrl, setUserImageUrl] = useState<string>('');

  const changeModalPasswordIsOpen = () => {
    setModalPasswordIsOpen(!modalPasswordIsOpen);
  };

  const getUser = async () => {
    api.get(`/users/${user.id}`).then(response => {
      const { data } = response.data;

      setUser(data);
      sessionService.updateLocalOrSessionStorage({
        sessions_id: sessionService.getId() || '',
        token: sessionService.getToken() || '',
        user: data,
      });

      const [address_saved, _] = data.adresses;

      // Resolvendo uma falha na inicialização do forms
      formRef.current?.setData({
        address_zip_code: address_saved ? address_saved.zip_code : '',
      });

      setAddress(address_saved);
    });
  };

  const getAddressByZipcode = useCallback(async (cepText: string) => {
    if (cepText.length === 8) {
      try {
        const response = await axios.get(
          `https://viacep.com.br/ws/${cepText}/json/`,
        );

        if (response.data.logradouro) {
          const currentData = formRef.current?.getData();

          formRef.current?.setData({
            ...currentData,
            address_state: response.data.uf,
            address_city: response.data.localidade,
            address_neighborhood: response.data.bairro,
            address_street: response.data.logradouro,
          });
        }
      } catch (error) {
        notify(
          'Não foi possível buscar seu endereço pelo CEP. Por favor tente novamente.',
          'error',
        );
      }
    }
  }, []);

  const handleSubmit = useCallback(async (data: IProfileData) => {
    try {
      setLoadingUpdateUser(true);

      notify('Estamos salvando seus dados. Por favor aguarde...', 'info');

      const response = await api.put(`/users/${user.id}`, {
        name: data.name,
        cpf: data.cpf,
        cnpj: data.cnpj,
        date_of_birth: formatDate.removeMask(data.birth),
        email: data.email,
        role: user.role,
        cellphone: data.cellphone,
        is_partner: user.is_partner,
        status: user.status,
        company_size: user.company_size,
        accept_terms_of_use: user.accept_terms_of_use,
        image_url: user.image_url,
        access_level: user.access_level,
      });

      const body_adress = {
        users_id: user.id,
        country: 'BR',
        state: data.address_state,
        city: data.address_city,
        neighborhood: data.address_neighborhood,
        zip_code: data.address_zip_code,
        street: data.address_street,
        number: data.address_number,
        complement: data.address_complement,
      };

      const { data: response_address } = await api.get(
        `/users/address?users_id=${user.id}`,
      );

      const address_saved = response_address.data[0];

      if (!address_saved) {
        await api.post('/users/address', body_adress);
      } else {
        await api.put(`/users/address/${address_saved.id}`, body_adress);
      }

      const { header } = response.data;

      notify(header.message, 'success');
    } catch (error) {
      notify(error.response.data.error, 'error');
    } finally {
      setLoadingUpdateUser(false);
    }
  }, []);

  const handleUpdatePassword = useCallback(async (data: IRequest) => {
    try {
      setLoadingUpdatePassword(true);

      formRefPass.current?.setErrors({});

      await schema.validate(data, {
        abortEarly: false,
      });

      notify('Estamos atualizando sua senha. Por favor aguarde...', 'info');

      const response = await api.put(`/users/${user.id}/password`, {
        password: data.password,
      });

      const { header } = response.data;

      notify(header.message, 'success');
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRefPass.current?.setErrors(errors);

        const { password, confirm_password } = errors;

        if (password) notify(password, 'error');
        if (confirm_password) notify(confirm_password, 'error');
      } else if (error.response.data)
        notify(error.response.data.error, 'error');
    } finally {
      setLoadingUpdatePassword(false);
    }
  }, []);

  const handleAvatarChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      // Ver a imagem que foi selecionada
      if (e.target.files) {
        const userData = new FormData();

        // O name do campo input, e pegar o valor da imagem selecionada.
        userData.append('attachment_profile', e.target.files[0]);

        try {
          const response = await api.post(
            `/users/${user.id}/upload/avatar`,
            userData,
          );

          const { image_url } = response.data.data;

          setUserImageUrl(image_url);

          notify(response.data.header.message, 'success');
        } catch (error) {
          notify(error.response.data.error, 'error');
        }
      }
    },
    [],
  );

  useEffect(() => {
    getUser();
  }, [userImageUrl]);

  return (
    <Container>
      <Header>
        <HeaderMenu />
      </Header>

      <Menu>
        <MenuSideBar />
      </Menu>

      <Main>
        <NamePage>
          <Title>Meu perfil</Title>
        </NamePage>

        <Content>
          <HeaderContent>
            <HeaderContentBoxImageProfile>
              {!user?.image_url ? (
                <IconProfileUser />
              ) : (
                <UserImage src={user?.image_url} />
              )}
              <LabelUpdateAvatar htmlFor="avatar">
                <IconUpdateImage />
                <input
                  type="file"
                  name="attachment_profile"
                  id="avatar"
                  onChange={handleAvatarChange}
                />
              </LabelUpdateAvatar>
            </HeaderContentBoxImageProfile>
          </HeaderContent>

          <ContentUserForm>
            <UserForm
              ref={formRef}
              onSubmit={handleSubmit}
              initialData={{
                name: user.name,
                cpf: user.cpf,
                cnpj: user.cnpj,
                email: user.email,
                birth: formatDate.addMask(user.date_of_birth || ''),
                cellphone: user.cellphone,
                address_zip_code: address?.zip_code || '',
                address_state: address?.state || '',
                address_city: address?.city || '',
                address_neighborhood: address?.neighborhood || '',
                address_street: address?.street || '',
                address_number: address?.number || '',
                address_complement: address?.complement || '',
              }}
            >
              <FieldGroup>
                <InputText
                  name="name"
                  placeholder="Nome completo"
                  required
                  tabIndex={1}
                />
                {user.cpf && (
                  <InputMask
                    name="cpf"
                    placeholder="CPF"
                    mask="999.999.999-99"
                    required
                    tabIndex={2}
                  />
                )}
                {user.cnpj && (
                  <InputMask
                    name="cnpj"
                    placeholder="CNPJ"
                    mask="99.999.999/9999-99"
                    required
                    tabIndex={2}
                  />
                )}
              </FieldGroup>

              <FieldGroup>
                <InputText
                  type="email"
                  name="email"
                  placeholder="E-mail"
                  required
                  tabIndex={3}
                />
                <InputMask
                  name="birth"
                  placeholder="Data de nascimento"
                  mask="99/99/9999"
                  required
                  tabIndex={4}
                />
              </FieldGroup>

              <FieldGroup>
                <InputMask
                  name="cellphone"
                  placeholder="Celular"
                  mask="99 (99) 99999-9999"
                  required
                  tabIndex={5}
                />
                <ChangePassword
                  onClick={changeModalPasswordIsOpen}
                  className="link"
                  type="button"
                  tabIndex={6}
                >
                  Alterar minha senha
                </ChangePassword>
              </FieldGroup>

              <Subtitle>Endereço</Subtitle>

              <FieldGroup>
                <InputMask
                  name="address_zip_code"
                  placeholder="CEP"
                  mask="99999-999"
                  onChange={cepText => {
                    getAddressByZipcode(
                      cepText.target.value.replace(regexRemoveAllNonDigits, ''),
                    );
                  }}
                  required
                  tabIndex={7}
                />
                <LinkSearchCep
                  className="link"
                  to={{
                    pathname:
                      'https://buscacepinter.correios.com.br/app/endereco/index.php?t',
                  }}
                  key="config"
                  target="_blank"
                  tabIndex={8}
                >
                  Não sei meu cep
                </LinkSearchCep>
              </FieldGroup>

              <FieldGroup>
                <InputText
                  name="address_state"
                  placeholder="Estado"
                  readOnly
                  required
                />
                <InputText
                  name="address_city"
                  placeholder="Cidade"
                  readOnly
                  required
                />
              </FieldGroup>

              <FieldGroup>
                <InputText
                  name="address_neighborhood"
                  placeholder="Bairro"
                  readOnly
                  required
                />
              </FieldGroup>

              <FieldGroup style={{ gridTemplateColumns: '3fr 1fr' }}>
                <InputText
                  name="address_street"
                  placeholder="Rua"
                  readOnly
                  required
                />
                <InputText
                  name="address_number"
                  placeholder="Número"
                  required
                  tabIndex={9}
                />
              </FieldGroup>

              <FieldGroup style={{ gridTemplateColumns: '3fr 1fr' }}>
                <InputText
                  name="address_complement"
                  placeholder="Complemento"
                  tabIndex={10}
                />
              </FieldGroup>

              <ButtonBlueGradient
                type="submit"
                style={{ maxWidth: 200, marginTop: '2vh' }}
                tabIndex={11}
                loading={loadingUpdateUser}
              >
                Salvar
              </ButtonBlueGradient>
            </UserForm>
          </ContentUserForm>
        </Content>
      </Main>
      <ModalPassword
        isOpen={modalPasswordIsOpen}
        onRequestClose={changeModalPasswordIsOpen}
        contentLabel="Resgate"
      >
        <h1>Informe sua nova senha</h1>
        <p>
          A melhor senha é aquela fácil de memorizar e segura ao mesmo tempo.
        </p>
        <ButtonCloseModal onClick={changeModalPasswordIsOpen}>
          <IconClose />
        </ButtonCloseModal>
        <UserForm ref={formRefPass} onSubmit={handleUpdatePassword}>
          <FieldModal>
            <InputText
              name="password"
              placeholder="Nova senha"
              showIconPassword
            />
          </FieldModal>

          <FieldModal>
            <InputText
              name="confirm_password"
              placeholder="Confirme a nova senha"
              showIconPassword
            />
          </FieldModal>
          <ButtonBlueGradient loading={loadingUpdatePassword}>
            Alterar
          </ButtonBlueGradient>
        </UserForm>
      </ModalPassword>
      <ToastContainer />
    </Container>
  );
};

export default Profile;
