import User from '@models/User';

import api from './api';

interface IParamsShow {
  users_id?: string;
}

interface IResponseCreate {
  header: {
    user_type: string;
    step: number;
    status_code: number;
    message: string;
  };
  data: User;
}

const NAME_TOKEN_STORAGE = '@Rebox:token';
const NAME_USER_STORAGE = '@Rebox:user';
const NAME_REMEMBER_STORAGE = '@Rebox:rememberMe';

const userService = {
  getOne: async ({ users_id }: IParamsShow) => {
    const response = await api.get(`/users/${users_id}`);
    return response.data;
  },

  updateLocalStorage: (user: User) => {
    const remember = localStorage.getItem(NAME_REMEMBER_STORAGE);

    if (remember) {
      localStorage.setItem(NAME_USER_STORAGE, JSON.stringify(user));
    } else if (!remember || remember === null) {
      sessionStorage.setItem(NAME_USER_STORAGE, JSON.stringify(user));
    }
  },

  create: async (user: User) => {
    const { data } = await api.post<IResponseCreate>(`/users`, user);
    return data;
  },
};

export default userService;
