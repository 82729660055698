import React, { useCallback, useEffect, useRef, useState } from 'react';

import { FormHandles } from '@unform/core';
import { ToastContainer } from 'react-toastify';
import * as Yup from 'yup';

import { IconBack } from '@assets/icons/index';
import {
  HeaderMenu,
  InputSelect,
  InputText,
  MenuSideBar,
  ButtonBlueGradient,
} from '@components/index';
import { ConfigValues } from '@config/index';
import IBankAccount from '@models/BankAccount';
import api from '@services/api';
import { getValidationErrors } from '@utils/index';
import notify from '@utils/notify/toasts';

import { listBank, pixType, typeBank } from './constants';
import { schema } from './schemaValidation';
import { IFormBankAccount } from './typing';

import {
  Container,
  Header,
  Main,
  Menu,
  Content,
  FieldGroup,
  FormHeader,
  ContentFormBankAccount,
  NamePage,
  Subtitle,
  Title,
  Legend,
  ButtonBack,
  ContentForm,
  InputGroup,
} from './styles';

const BankAccount: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [bankType, setBankType] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState(
    JSON.parse(sessionStorage.getItem('@Rebox:user') || '{}'),
  );
  const [bankAccount, setBankAccount] = useState<IBankAccount>();

  const handleSubmit = useCallback(async (data: IFormBankAccount) => {
    try {
      setLoading(prevState => !prevState);

      formRef.current?.setErrors({});

      await schema.validate(data, {
        abortEarly: false,
      });

      const bank_accounts_id = sessionStorage.getItem(
        '@Rebox:User:BankAccount:id',
      );

      if (bank_accounts_id) {
        const agency = data.bank_agency_digit
          ? `${data.bank_agency}-${data.bank_agency_digit}`
          : data.bank_agency || '';

        const account = data.bank_number_digit
          ? `${data.bank_number}-${data.bank_number_digit}`
          : data.bank_number || '';

        const body = {
          users_id: user.id,
          type: data.bank_type,
          pix_type:
            data.bank_type === ConfigValues.bank_account.type.pix
              ? data.pix_type
              : '',
          pix_key:
            data.bank_type === ConfigValues.bank_account.type.pix
              ? data.pix_key
              : '',
          owner_name: data.owner_name || '',
          bank_name:
            data.bank_type !== ConfigValues.bank_account.type.pix
              ? data.bank_name
              : '',
          bank_agency:
            data.bank_type !== ConfigValues.bank_account.type.pix ? agency : '',
          bank_number:
            data.bank_type !== ConfigValues.bank_account.type.pix
              ? account
              : '',
        };

        const response_bank_account_updated = await api.put(
          `/users/bank-accounts/${bank_accounts_id}`,
          body,
        );

        const { header } = response_bank_account_updated.data;

        notify(header.message, 'success');
      } else {
        const response_bank_account = await api.post(`/users/bank-accounts`, {
          users_id: user.id,
          type: data.bank_type,
          pix_type: data.pix_type || '',
          pix_key: data.pix_key || '',
          owner_name: data.owner_name || '',
          bank_name: data.bank_name || '',
          bank_agency: data.bank_agency_digit
            ? `${data.bank_agency}-${data.bank_agency_digit}`
            : data.bank_agency || '',
          bank_number: data.bank_number_digit
            ? `${data.bank_number}-${data.bank_number_digit}`
            : data.bank_number || '',
        });

        const { header, data: bank_account } = response_bank_account.data;

        sessionStorage.setItem('@Rebox:User:BankAccount:id', bank_account.id);

        notify(header.message, 'success');
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);

        const {
          bank_type,
          pix_type,
          pix_key,
          owner_name,
          bank_name,
          bank_agency,
          bank_agency_digit,
          bank_number,
          bank_number_digit,
        } = errors;

        if (bank_type) notify(bank_type, 'error');
        if (pix_type) notify(pix_type, 'error');
        if (pix_key) notify(pix_key, 'error');
        if (owner_name) notify(owner_name, 'error');
        if (bank_name) notify(bank_name, 'error');
        if (bank_agency) notify(bank_agency, 'error');
        if (bank_agency_digit) notify(bank_agency_digit, 'error');
        if (bank_number) notify(bank_number, 'error');
        if (bank_number_digit) notify(bank_number_digit, 'error');
      } else {
        notify(error.response.data.error, 'error');
      }
    } finally {
      setLoading(prevState => !prevState);
    }
  }, []);

  const getBankAccount = useCallback(async () => {
    try {
      const response_bank_accounts = await api.get(
        `/users/bank-accounts?users_id=${user.id}`,
      );
      const bank_account_found = response_bank_accounts.data.data[0];
      if (bank_account_found) {
        setBankAccount(bank_account_found);
        setBankType(bank_account_found.type);
        sessionStorage.setItem(
          '@Rebox:User:BankAccount:id',
          bank_account_found.id,
        );
      } else {
        // Corrigindo bug que acontece caso delete os dados bancários do bd
        sessionStorage.removeItem('@Rebox:User:BankAccount:id');
      }
    } catch (error) {
      if (error.response) {
        notify(
          error.response.data.error ||
            'Houve um erro ao tentar buscar seus dados bancários.',
          'error',
        );
      }
    }
  }, []);

  useEffect(() => {
    getBankAccount();
  }, []);

  return (
    <Container>
      <Header>
        <HeaderMenu />
      </Header>

      <Menu>
        <MenuSideBar />
      </Menu>

      <Main>
        <NamePage>
          <Title>Conta bancária</Title>
        </NamePage>

        <Content>
          <ButtonBack to="/painel">
            <IconBack />
            <span>Voltar</span>
          </ButtonBack>

          <ContentForm>
            <FormHeader>
              <Subtitle>Cadastre seus dados bancários</Subtitle>
              <Legend>
                Utilizaremos os dados registrados abaixo para depositar os
                resgates.
              </Legend>
            </FormHeader>

            <ContentFormBankAccount
              ref={formRef}
              onSubmit={handleSubmit}
              initialData={{
                pix_key: bankAccount?.pix_key || '',
                owner_name: bankAccount?.owner_name || '',
                bank_agency: bankAccount?.bank_agency?.split('-')[0],
                bank_agency_digit: bankAccount?.bank_agency?.split('-')[1],
                bank_number: bankAccount?.bank_number?.split('-')[0],
                bank_number_digit: bankAccount?.bank_number?.split('-')[1],
              }}
            >
              <FieldGroup>
                <InputSelect
                  name="bank_type"
                  options={typeBank}
                  selectedDefault={bankAccount?.type}
                  onChange={value => {
                    setBankType(value.target.value);
                  }}
                  placeholder="Selecione o tipo da conta"
                />
              </FieldGroup>

              {bankType === ConfigValues.bank_account.type.pix && (
                <>
                  <FieldGroup>
                    <InputSelect
                      name="pix_type"
                      options={pixType}
                      selectedDefault={bankAccount?.pix_type}
                      placeholder="Qual o tipo da chave do pix?"
                    />
                  </FieldGroup>
                  <FieldGroup>
                    <InputText
                      name="pix_key"
                      placeholder="Digite sua chave no pix"
                    />
                  </FieldGroup>
                </>
              )}

              {bankType !== ConfigValues.bank_account.type.pix && (
                <>
                  <FieldGroup>
                    <InputSelect
                      name="bank_name"
                      options={listBank}
                      selectedDefault={bankAccount?.bank_name}
                      placeholder="Selecione o seu banco"
                    />
                  </FieldGroup>

                  <FieldGroup>
                    <InputGroup>
                      <InputText
                        name="bank_agency"
                        placeholder="Número de agência"
                      />
                      <InputText
                        name="bank_agency_digit"
                        placeholder="Dígito"
                      />
                    </InputGroup>
                  </FieldGroup>

                  <FieldGroup>
                    <InputGroup>
                      <InputText
                        name="bank_number"
                        placeholder="Número da conta"
                      />
                      <InputText
                        name="bank_number_digit"
                        placeholder="Dígito"
                      />
                    </InputGroup>
                  </FieldGroup>
                </>
              )}

              <FieldGroup>
                <InputText
                  name="owner_name"
                  placeholder="Digite o nome do titular"
                />
              </FieldGroup>

              <ButtonBlueGradient
                className="btn-submit"
                loading={loading}
                type="submit"
              >
                Salvar
              </ButtonBlueGradient>
            </ContentFormBankAccount>
          </ContentForm>
        </Content>
      </Main>
      <ToastContainer />
    </Container>
  );
};

export default BankAccount;
