import Rescue from '@models/Rescue';

import api from './api';

interface IParamsShow {
  period_start?: string;
  period_end?: string;
  users_id?: string;
}

interface IRequestCreate {
  users_id?: string;
  amount: number;
}

interface IResponseRescue {
  header: any;
  data: Rescue[];
}

const rescueService = {
  getManyByPeriod: async ({
    period_start,
    period_end,
    users_id,
  }: IParamsShow): Promise<IResponseRescue> => {
    const response = await api.get(
      `/rescues?users_id=${users_id}&period_start=${period_start}&period_end=${period_end}`,
    );
    return response.data;
  },
};

export default rescueService;
