import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import Indication from '@models/Indication';
import api from '@services/api';
import { formatMoney, formatDate } from '@utils/formatters';
import notify from '@utils/notify/toasts';
import { formatDateBR } from '@utils/validatorDate';

import { indicationStatus } from './constants';
import { IParams } from './typing';

import {
  Container,
  FieldGroup,
  FieldSet,
  FieldSetData,
  FieldSetTitle,
  Title,
} from './styles';

const IndicationsDetailsInformations: React.FC = () => {
  const { id } = useParams<IParams>();
  const [loading, setLoading] = useState<boolean>(false);
  const [indication, setIndication] = useState<Indication>();

  const getIndications = async () => {
    try {
      setLoading(prevState => !prevState);
      const { data: response } = await api.get(`/indications/${id}`);
      setIndication(response.data);
    } catch (error) {
      notify('Houve um erro ao tentar buscar as indicações.', 'error');
    } finally {
      setLoading(prevState => !prevState);
    }
  };

  useEffect(() => {
    getIndications();
  }, []);

  return (
    <>
      <Container>
        <Title>Dados do indicado</Title>
        <FieldGroup>
          <FieldSet>
            <FieldSetTitle>Nome: </FieldSetTitle>
            <FieldSetData style={{ textTransform: 'capitalize' }}>
              {indication?.user_indicated?.name}
            </FieldSetData>
          </FieldSet>
          <FieldSet>
            <FieldSetTitle>CPF/CNPJ: </FieldSetTitle>
            <FieldSetData>
              {indication?.user_indicated?.cpf ||
                indication?.user_indicated?.cnpj}
            </FieldSetData>
          </FieldSet>
          <FieldSet>
            <FieldSetTitle>E-mail: </FieldSetTitle>
            <FieldSetData>{indication?.user_indicated?.email}</FieldSetData>
          </FieldSet>
          <FieldSet>
            <FieldSetTitle>Data de Nascimento: </FieldSetTitle>
            <FieldSetData>
              {formatDateBR(indication?.user_indicated?.date_of_birth || '')}
            </FieldSetData>
          </FieldSet>
          <FieldSet>
            <FieldSetTitle>Celular:</FieldSetTitle>
            <FieldSetData>{indication?.user_indicated?.cellphone}</FieldSetData>
          </FieldSet>
        </FieldGroup>
      </Container>

      <Container>
        <Title>Indicação</Title>
        <FieldGroup>
          <FieldSet>
            <FieldSetTitle>Status: </FieldSetTitle>
            <FieldSetData>
              {indicationStatus[indication?.status?.toLowerCase() || '']}
            </FieldSetData>
          </FieldSet>
          <FieldSet>
            <FieldSetTitle>Data: </FieldSetTitle>
            <FieldSetData>
              {formatDate.addMask(indication?.date?.split(' ')[0] || '')}
            </FieldSetData>
          </FieldSet>
          <FieldSet>
            <FieldSetTitle>Valor: </FieldSetTitle>
            <FieldSetData>
              {formatMoney.fromNumberToPrice(indication?.cash_bonus || 0)}
            </FieldSetData>
          </FieldSet>
        </FieldGroup>
      </Container>
    </>
  );
};

export default IndicationsDetailsInformations;
