export const percentage = [
  {
    label: '0%',
    value: '0',
  },
  {
    label: '25%',
    value: '25',
  },
  {
    label: '50%',
    value: '50',
  },
  {
    label: '75%',
    value: '75',
  },
  {
    label: '95%',
    value: '95',
  },
];
