// src/styles/themes/light.ts

// DEFINIÇÃO DE ESTILOS LIGHT
const light = {
  colors: {
    background_primary: '#ffffff',
    background_secondary: '#F4F5F9',
    background_secondary_50: 'rgba(244, 245, 249, 0.5)',
    background_blue_05: 'rgba(7, 53, 155, 0.05)',

    text_primary: '#000000',
    text_blue: '#07359B',
    text_blue_05: 'rgba(7, 53, 155, 0.05)',
    text_secondary: '#07359B',
    text_white: '#ffffff',

    text_gray: '#1D1D1D',
    text_gray_07: 'rgba(29, 29, 29, 0.07)',
    text_gray_15: 'rgba(29, 29, 29, 0.15)',
    text_gray_50: 'rgba(29, 29, 29, 0.5)',
    text_gray_75: 'rgba(29, 29, 29, 0.75)',

    text_red: '#E84118',
  },

  fontFamily: {
    Montserrat: `'Montserrat', sans-serif`,
    Poppins: `'Poppins', sans-serif`,
    Quicksand: `'Quicksand', sans-serif`,
  },

  fontSize: {
    titleBig: 28,
    title: 24,
    subtitleBig: 22,
    subtitle: 20,
    paragraphBig: 18,
    paragraphMed: 16,
    paragraph: 14,
    legend: 12,
  },
};

export default light;
