import styled, { css } from 'styled-components';

import { ITabs } from './typing';

/* VERSÃO MOBILE
 * - 1024px
 * - 960px
 * - 768px
 * - 540px
 * - 480px
 * - 360px
 * - 320px
 */

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.background_secondary};
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    display: grid;
    grid-template-rows: 16vh 84vh;
    grid-template-columns: 2.2fr 7.8fr;
    grid-template-areas:
      'header header'
      'menu main';
  }
`;

export const Header = styled.div`
  grid-area: header;
  padding: 2vh 2vw;

  @media (max-width: 1023.9px) {
    height: 16vh;
  }
`;

export const Main = styled.div`
  grid-area: main;
  overflow: auto;

  padding: 0 2vw;

  @media (min-width: 1024px) {
    padding: 0 2vw 2vh 0;
  }
`;

export const Menu = styled.nav`
  @media (min-width: 1023.9px) {
    grid-area: menu;
    padding: 0 1vw 2vh 2vw;
  }
`;

export const NamePage = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
  height: 10vh;
  background-color: ${({ theme }) => theme.colors.background_primary};
  border-radius: 10px;

  padding: 0 3vw;
  margin-bottom: 2vh;

  @media (max-width: 539.9px) {
    padding: 5vh 8vw;
  }
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSize.paragraphBig}px;
  color: ${({ theme }) => theme.colors.text_blue};
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background_primary};
  border-radius: 10px;

  padding: 2vh 3vw 3vh;
  margin-bottom: 2vh;
  position: relative;

  @media (max-width: 540px) {
    padding: 5vh 10vw;
  }
`;

export const ContentBack = styled.div`
  position: relative;
  padding: 4vh 2vw 0;
  display: flex;
  align-items: center;
`;

export const ContentBackTitle = styled.h1`
  text-align: start;
  font-size: ${({ theme }) => theme.fontSize.paragraphBig}px;
  color: ${({ theme }) => theme.colors.text_blue};
  line-height: 24px;
  font-weight: 600;
`;

export const ContentHeader = styled.div`
  display: flex;
  width: 100%;
  padding: 2vh 2vw;

  @media (max-width: 960px) {
    > p {
      font-size: 12px;
    }
  }
`;

export const ContentHeaderItem = styled.div<ITabs>`
  padding: 2vh 2vw;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom: 1.5px solid ${({ theme }) => theme.colors.text_gray_15};
  cursor: pointer;

  > p {
    text-align: center;
    font-size: ${({ theme }) => theme.fontSize.paragraph}px;
    color: ${({ theme }) => theme.colors.text_gray_75};
    line-height: 24px;
    font-weight: 500;
  }

  :hover {
    border-bottom: 1.5px solid ${({ theme }) => theme.colors.text_gray_50};
  }

  ${props =>
    props.isActive &&
    css`
      border-bottom: none;
      border-top: 1.5px solid ${({ theme }) => theme.colors.text_gray_15};
      border-right: 1.5px solid ${({ theme }) => theme.colors.text_gray_15};
      border-left: 1.5px solid ${({ theme }) => theme.colors.text_gray_15};
      :hover {
        border-bottom: none;
      }
      > p {
        color: ${({ theme }) => theme.colors.text_gray};
      }
    `}
`;

export const IndicationInformation = styled.section`
  padding: 3vh 2vw 0;
  > h2 {
    font-weight: 600;
    font-size: ${({ theme }) => theme.fontSize.subtitle}px;
    color: ${({ theme }) => theme.colors.text_gray};
    margin-bottom: 3vh;
  }
`;

export const IndicationInformationBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 3vh;

  @media (max-width: 539.9px) {
    grid-template-columns: 1fr;
  }

  > p {
    font-weight: 400;
    font-size: ${({ theme }) => theme.fontSize.paragraphMed}px;
    color: ${({ theme }) => theme.colors.text_gray_75};
    margin-bottom: 2vh;
    > span {
      font-weight: 500;
      color: ${({ theme }) => theme.colors.text_gray};
    }
  }
`;

export const ButtonBackList = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  > svg {
    width: 12px;
    height: 12px;
    margin: 0 1vw;

    path {
      fill: ${({ theme }) => theme.colors.text_blue};
    }
  }

  > p {
    text-align: start;
    font-size: ${({ theme }) => theme.fontSize.paragraphBig}px;
    color: ${({ theme }) => theme.colors.text_blue};
    line-height: 24px;
    font-weight: 600;
  }
`;

export const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 150px;
`;
