import ReactInputMask from 'react-input-mask';
import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled?: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: relative;

  display: flex;
  align-items: center;

  background: rgba(244, 245, 249, 0.5);
  color: #000000;

  width: 100%;
  height: 50px;

  border: 1px solid rgba(128, 128, 128, 0.3);
  border-radius: 10px;

  transition: all 0.2s ease-in-out;

  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  /* Se o input estiver focado, mude a cor do icone e da borda. A cor do icone esta conectado com color */
  ${props =>
    props.isFocused &&
    css`
      color: #07359b;
      border-color: #07359b;
    `}

  /* Se o input estiver preenchido, mude a cor do icone. A cor do icone esta conectado com color */
  ${props =>
    props.isFilled &&
    css`
      color: #07359b;
      border-color: #07359b;
    `}
`;

export const ReactInputMaskStyled = styled(ReactInputMask)`
  flex: 1;
  width: 100%;
  height: 100%;

  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSize.paragraph}px;
  line-height: 24px;
  color: #000;

  padding: 0px 30px;

  background: transparent;
  border: 0;
  border-radius: 15px;

  ::placeholder {
    color: rgba(29, 29, 29, 0.5);
    font-size: ${({ theme }) => theme.fontSize.paragraph}px;
    text-transform: none;
  }
`;

export const Alert = styled(Tooltip)`
  margin-right: 20px;

  svg {
    margin: 0;
  }

  span {
    background: #07359b;
    color: #fff;

    &::before {
      border-color: #07359b transparent;
    }
  }
`;
