// ./src/utils/validatorDate.ts

import { parse } from 'path';

interface IPeriod {
  period_start: string;
  period_end: string;
}

const formatDate = (date: string, format?: string): string => {
  const parsed_date = new Date(date);

  const day = parsed_date.getDate();
  const month = parsed_date.getMonth() + 1;
  const year = parsed_date.getFullYear();

  return `${year}-${month < 10 ? `0${month}` : month}-${
    day < 10 ? `0${day}` : day
  }`;
};

const formatDateRevet = (date: string): string => {
  // Entrada: 20/03/1996
  // Saída: 1996-03-20
  const array_date_formated = date.split('/');
  const day = array_date_formated[0];
  const month = array_date_formated[1];
  const year = array_date_formated[2];
  return `${year}-${month}-${day}`;
};

const formatDateBR = (date: string): string => {
  // Entrada: 1996-03-20
  // Saída: 20/03/1996
  const array_date_formated = date.split('-');
  const day = array_date_formated[2];
  const month = array_date_formated[1];
  const year = array_date_formated[0];
  return `${day}/${month}/${year}`;
};

const getPeriodNow = (): IPeriod => {
  const parsed_date = new Date();

  const day = parsed_date.getDate();
  const month = parsed_date.getMonth() + 1;
  const year = parsed_date.getFullYear();

  const period: IPeriod = {
    period_start: `${year}-${month < 10 ? `0${month}` : month}-01 00:00`,
    period_end: `${year}-${month < 10 ? `0${month}` : month}-31 23:59`,
  };

  return period;
};

const changeMonthInPeriod = (date: string, action: string, num = 1): string => {
  // O formato do date precisa ser 2021-03-06 15:00
  const [year, month, rest] = date.split('-');
  const months = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
  ];

  console.log('Mês a ser encontrado: ', month);

  let index_month = 0;
  months.forEach((mon, index) => {
    if (mon === month) {
      index_month = index;
    }
  });

  console.log('Index encontrado: ', index_month);
  console.log('TAM: ', months.length);

  /**
   * [ ] Se o mês atual for dez, pule para o ano seguinte
   */
  if (action === 'next') {
    if (index_month + 1 === months.length) {
      const new_year = parseInt(year, 10);
      return `${new_year + 1}-${month[0]}-${rest}`;
    }

    return `${year}-${months[index_month + 1]}-${rest}`;
  }

  if (index_month === 0) {
    const new_year = parseInt(year, 10);
    return `${new_year - 1}-${months[11]}-${rest}`;
  }

  return `${year}-${months[index_month - 1]}-${rest}`;
};

export {
  formatDateBR,
  formatDateRevet,
  formatDate,
  getPeriodNow,
  changeMonthInPeriod,
};

// ANOTAÇÃO
// Guarda cada pedaço em uma variável
// var dia     = data.getDate();           // 1-31
// var dia_sem = data.getDay();            // 0-6 (zero=domingo)
// var mes     = data.getMonth();          // 0-11 (zero=janeiro)
// var ano2    = data.getYear();           // 2 dígitos
// var ano4    = data.getFullYear();       // 4 dígitos
// var hora    = data.getHours();          // 0-23
// var min     = data.getMinutes();        // 0-59
// var seg     = data.getSeconds();        // 0-59
// var mseg    = data.getMilliseconds();   // 0-999
// var tz      = data.getTimezoneOffset(); // em minutos

// // Formata a data e a hora (note o mês + 1)
// var str_data = dia + '/' + (mes+1) + '/' + ano4;
// var str_hora = hora + ':' + min + ':' + seg;

// // Mostra o resultado
// alert('Hoje é ' + str_data + ' às ' + str_hora);
