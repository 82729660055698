import styled from 'styled-components';

import { appearScreenFromRight } from '@styles/global';

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.background_primary};
  flex: 1;
  height: 100vh;

  animation: ${appearScreenFromRight} 1s;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;

  margin: 0 auto;

  width: 100%;

  padding: 0 20px;

  @media (min-width: 1024px) {
    padding: 0;
  }

  @media (max-width: 767.9px) {
    padding: 0 15vw;
  }

  @media (max-width: 479.9px) {
    padding: 0 10vw;
  }
`;

export const ContentTitle = styled.h1`
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSize.subtitle}px;
  line-height: 39px;

  color: ${({ theme }) => theme.colors.text_secondary};

  margin-top: 60px;
`;

export const ContentSubtitle = styled.h2`
  font-weight: normal;
  font-size: ${({ theme }) => theme.fontSize.paragraphBig}px;
  line-height: 29px;
  color: ${({ theme }) => theme.colors.text_gray_75};

  margin-top: 22px;

  max-width: 839px;
`;

export const ContentFormContainer = styled.section`
  width: 100%;
  height: 100%;

  display: block;
  justify-content: center;
  align-items: center;

  margin: 8vh 0;

  > form {
    width: 100%;

    display: grid;
    grid-template-columns: 1fr;
    row-gap: 20px;
    column-gap: 20px;
  }

  > svg {
    display: none;
    width: 100%;
    max-width: 400px;
  }

  @media (min-width: 768px) {
    display: flex;
    margin: 0;

    > form {
      max-width: 420px;
    }

    > svg {
      display: block;
      width: auto;
      margin-left: 36px;
    }
  }
`;
