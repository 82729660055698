import React, { useState } from 'react';

import { ToastContainer } from 'react-toastify';

import {
  IconArrowRight,
  IconClose,
  IconDashboard,
  IconDiscount,
  IconSetting,
  IconSuport,
  IconProfile,
  IconPartner,
} from '@assets/icons';
import { ConfigBase, ConfigValues } from '@config/index';
import User from '@models/User';
import api from '@services/api';
import sessionService from '@services/session.service';
import notify from '@utils/notify/toasts';

import {
  Bonus,
  ButtonCloseMenu,
  Container,
  Content,
  Description,
  Footer,
  LinkClient,
  LinkMenu,
  OpenMenuMobile,
  Shadow,
  Title,
  ButtonClient,
  ModalBecomeACustomer,
  ButtonCloseModal,
} from './styles';

const MenuSideBar: React.FC = () => {
  const [user, setUser] = useState<User>(sessionService.getUser());
  const [isOpenSideBar, setIsOpenSideBar] = useState(false);
  const [usersAccessLevel, setUsersAccessLevel] = useState<string>(
    JSON.parse(sessionStorage.getItem('@Rebox:user') || '{ access_level: "" }')
      .access_level,
  );
  const [
    modalBecomeACustomerIsOpen,
    setModalBecomeACustomerIsOpen,
  ] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const changeModalBecomeACustomerIsOpen = () => {
    setModalBecomeACustomerIsOpen(prevState => !prevState);
  };

  const becomeACustomer = async () => {
    try {
      changeModalBecomeACustomerIsOpen();
      setLoading(prevState => !prevState);
      user.role = ConfigValues.user.role.client;
      const { data: responseUser } = await api.put(`/users/${user.id}`, user);
      sessionService.updateLocalOrSessionStorage({
        user: responseUser.data,
        sessions_id: sessionService.getId() || '',
        token: sessionService.getToken() || '',
      });
    } catch (error) {
      changeModalBecomeACustomerIsOpen();
      notify(
        'Sinto muito, não foi possível mudar sua conta. Tente novamente mais tarde.',
        'error',
      );
    } finally {
      setLoading(prevState => !prevState);
    }
  };

  const showSideBar = () => setIsOpenSideBar(!isOpenSideBar);

  return (
    <>
      <Shadow className={isOpenSideBar ? 'active' : 'inactive'}></Shadow>
      <Container className={isOpenSideBar ? 'open' : 'close'}>
        <OpenMenuMobile
          className={isOpenSideBar ? 'active' : 'inactive'}
          onClick={showSideBar}
        >
          <IconArrowRight />
        </OpenMenuMobile>
        <ButtonCloseMenu onClick={showSideBar}>
          <IconClose />
        </ButtonCloseMenu>
        <Content>
          <LinkMenu to="/painel" key="dashboard">
            <IconDashboard style={{ opacity: 0.4 }} />
            <p>Dashboard</p>
          </LinkMenu>

          <LinkMenu to="/indicacoes" key="indicações">
            <IconPartner style={{ opacity: 0.4 }} />
            <p>Indicações</p>
          </LinkMenu>

          {usersAccessLevel &&
            usersAccessLevel === ConfigValues.user.access_level.high && (
              <LinkMenu to="/promotores" key="promotores">
                <IconProfile style={{ opacity: 0.4 }} />
                <p>Promotores</p>
              </LinkMenu>
            )}

          <Title>Ajuda</Title>
          <LinkMenu to="/ajustes" key="config">
            <IconSetting />
            <p>Configurações</p>
          </LinkMenu>
          {/* <LinkMenu to="/" key="suport">
            <IconSuport />
            <p>Suporte</p>
          </LinkMenu> */}
        </Content>
        <Footer>
          {user.role.toLowerCase() === ConfigValues.user.role.partner ? (
            <Bonus>
              <p>Seja cliente também!</p>
              <Description>
                {/* <IconDiscount /> */}
                <span>
                  Mude o tipo de sua conta para {`"Cliente"`}, e contrate nossos
                  planos sem deixar de ser um afiliado.
                </span>
              </Description>
            </Bonus>
          ) : (
            <Bonus>
              <p>Vamos resgatar!</p>
              <Description>
                <IconDiscount />
                <span>
                  Por tempo limitado, taxa 0% em solicitações de resgate.
                  Aproveite, e indique!
                </span>
              </Description>
            </Bonus>
          )}

          {user.role.toLowerCase() === ConfigValues.user.role.client ? (
            <LinkClient
              to={{
                pathname: `${
                  ConfigBase.clientBaseURL
                }/compras?s=${sessionService.getId()}&t=${sessionService.getToken()}`,
              }}
              target="_blank"
            >
              Acessar área do cliente
            </LinkClient>
          ) : (
            <>
              {user.role.toLowerCase() === ConfigValues.user.role.partner && (
                <ButtonClient onClick={becomeACustomer}>
                  Me tornar um cliente
                </ButtonClient>
              )}
            </>
          )}
        </Footer>
        <ModalBecomeACustomer
          isOpen={modalBecomeACustomerIsOpen}
          onRequestClose={changeModalBecomeACustomerIsOpen}
          contentLabel="Resgate"
        >
          <h1>Mudando sua conta</h1>
          <p>
            Caso ainda tenha dúvidas ou precise de ajuda, entre em contato com a
            gente pelos canais de atendimento.
          </p>
          <ButtonCloseModal onClick={changeModalBecomeACustomerIsOpen}>
            <IconClose />
          </ButtonCloseModal>
          <section>
            {loading ? (
              <p>Por favor, aguarde...</p>
            ) : (
              <LinkClient
                to={{
                  pathname: `${
                    ConfigBase.clientBaseURL
                  }/compras?s=${sessionService.getId()}&t=${sessionService.getToken()}`,
                }}
                target="_blank"
              >
                Acessar área do cliente
              </LinkClient>
            )}
          </section>
        </ModalBecomeACustomer>
        <ToastContainer />
      </Container>
    </>
  );
};

export default MenuSideBar;
