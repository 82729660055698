import styled from 'styled-components';

export const Container = styled.button`
  background: linear-gradient(
    270deg,
    rgba(7, 53, 155, 0.7) 2.02%,
    #07359b 100%
  );

  height: 40px;

  width: 100%;
  /* max-width: 420px; */

  border-radius: 10px;
  border: 0;

  padding: 0 20px;

  :hover {
    background: #07359b;
  }
`;

export const Text = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSize.paragraph}px;
  color: ${({ theme }) => theme.colors.text_white};
`;
