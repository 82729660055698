import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.background_primary};
  flex: 1;
  height: 100vh;
  /* padding-top: 5vh; */

  /* @media (max-width: 768px) {
    padding-top: 15vh;
  } */
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;

  margin: 0 auto;

  width: 100%;

  max-width: 350px;

  padding: 5vh 20px 20px;

  > svg {
    width: 100%;
  }

  > form {
    width: 100%;

    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;

    > button.resend {
      text-align: end;
      font-size: ${({ theme }) => theme.fontSize.paragraph}px;
      line-height: 10px;

      color: #07359b;

      transition: 180ms ease-in;

      :hover {
        color: #1d1d1d;
      }
    }
  }

  @media (min-width: 768px) {
    padding: 0;
  }
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSize.subtitle}px;
  line-height: 39px;

  color: ${({ theme }) => theme.colors.text_blue};

  margin-top: 8vh;
  margin-bottom: 1vh;
`;

export const Subtitle = styled.h2`
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSize.paragraphMed}px;
  line-height: 39px;

  color: ${({ theme }) => theme.colors.text_gray_75};
  margin-bottom: 6vh;
`;

export const ForgetPassword = styled.section`
  display: flex;
  justify-content: flex-end;

  margin-top: 5px;
  margin-right: 16px;
`;

export const ForgetPasswordLink = styled(Link)`
  font-size: ${({ theme }) => theme.fontSize.paragraph}px;
  line-height: 10px;

  color: #07359b;

  transition: 180ms ease-in;

  :hover {
    color: #1d1d1d;
  }
`;

export const DontHaveAccountText = styled.p`
  font-size: ${({ theme }) => theme.fontSize.paragraph}px;
  color: #1d1d1d;
  text-align: center;
  margin-top: 2vh;
`;

export const SignUpLink = styled(Link)`
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSize.paragraph}px;
  color: #07359b;

  transition: 180ms ease-in;
  padding: 1vh 2vw 3vh;

  :hover {
    color: #1d1d1d;
  }
`;
