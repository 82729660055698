import React, { useState } from 'react';

import User from 'models/User';
import { Redirect } from 'react-router-dom';

import {
  IconArrowBottom,
  IconLogout,
  IconNotification,
  IconNotificationActive,
  IconCreditCard,
  IconProfile,
  IconProfileUser,
} from '@assets/icons';
import { ImageLogotipo } from '@assets/images';
import api from '@services/api';
import sessionService from '@services/session.service';
import notify from '@utils/notify/toasts';

import {
  ArrowButtonOptions,
  ButtonLinkMenu,
  Container,
  ExpandedMenu,
  ImageGroup,
  LinkDefault,
  LinkMenu,
  NavigationGroup,
  Notification,
  NotificationLink,
  Profile,
  UserImage,
  UserName,
} from './styles';

const HeaderMenu: React.FC = () => {
  const [user, setUser] = useState<User | any>(sessionService.getUser());
  const [notification, setNotification] = useState(false);
  const [isExpandedMenuOpen, setIsExpandedMenuOpen] = useState(false);
  const [loggedIn, setLoggedIn] = useState(sessionService.checked());

  const changeExpandedMenuOpen = () => {
    setIsExpandedMenuOpen(!isExpandedMenuOpen);
  };

  const signOut = async () => {
    try {
      notify('Aguarde. Sua seção está sendo encerrada...', 'info');

      const session = await api.put(`/sessions/${sessionService.getId()}`, {});

      sessionService.cleanLocalStorage();

      setLoggedIn(!loggedIn);
    } catch (error) {
      notify('Sinto muito, houve um erro ao tentar encerrar a seção.', 'error');
    }
  };

  return (
    <Container>
      {!loggedIn && <Redirect to="/login" exact />}
      <ImageGroup>
        <LinkDefault to="/painel">
          <ImageLogotipo width={120} />
        </LinkDefault>
      </ImageGroup>

      <NavigationGroup>
        <Notification>
          {/* <NotificationLink to="/">
            {notification ? (
              <IconNotificationActive width={20} />
            ) : (
              <IconNotification width={20} />
            )}
          </NotificationLink> */}
        </Notification>
      </NavigationGroup>

      <Profile onClick={changeExpandedMenuOpen}>
        {!user?.image_url ? (
          <IconProfileUser />
        ) : (
          <UserImage src={user?.image_url} />
        )}
        <UserName>{user?.name.split(' ')[0] || 'Convidado'}</UserName>
        <ArrowButtonOptions>
          <IconArrowBottom
            className={isExpandedMenuOpen ? 'to_spin' : 'normal'}
          />
        </ArrowButtonOptions>
      </Profile>
      <ExpandedMenu className={isExpandedMenuOpen ? 'open' : 'close'}>
        <LinkMenu className="link-menu" to="/perfil" key="perfil">
          <IconProfile />
          <p>Meu perfil</p>
        </LinkMenu>
        <LinkMenu
          className="link-menu"
          to="/conta-bancaria"
          key="conta-bancaria"
        >
          <IconCreditCard />
          <p>Dados bancários</p>
        </LinkMenu>
        <ButtonLinkMenu className="link-menu" onClick={signOut}>
          <IconLogout />
          <p>Sair</p>
        </ButtonLinkMenu>
      </ExpandedMenu>
    </Container>
  );
};

export default HeaderMenu;
