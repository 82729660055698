import React, { useCallback, useEffect, useState } from 'react';

import { Redirect } from 'react-router-dom';
import { MoonLoader } from 'react-spinners';
import { ToastContainer } from 'react-toastify';

import { IconAddGray } from '@assets/icons';
import { HeaderMenu, MenuSideBar } from '@components/index';
import { ConfigValues } from '@config/index';
import User from '@models/User';
import api from '@services/api';
import notify from '@utils/notify/toasts';

import Item from './Item';

import {
  ButtonAddVehicle,
  Container,
  Content,
  Header,
  Main,
  Menu,
  NamePage,
  Title,
  List,
  Loading,
  NavigationPages,
  Subtitle,
} from './styles';

interface IResponseSubordinates {
  header: {
    page: any;
    per_page: any;
    total: number;
    message?: string;
  };
  data: User[];
}

interface IParamsSubordinates {
  page: number | string;
  per_page?: number | string;
}

const Subordinates: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<User>();
  const [subordinates, setSubordinates] = useState<IResponseSubordinates>({
    header: { page: 1, per_page: 10, total: 0 },
    data: [],
  });
  const [page, setPage] = useState<number>(
    Number.parseInt(sessionStorage.getItem('@Rebox:Page') || '1', 10),
  );
  const [usersAccessLevel, setUsersAccessLevel] = useState<string>(
    JSON.parse(sessionStorage.getItem('@Rebox:user') || '{ access_level: "" }')
      .access_level,
  );

  const getSubordinates = useCallback(async (params: IParamsSubordinates) => {
    try {
      setLoading(prevState => !prevState);

      const user_saved: User = JSON.parse(
        sessionStorage.getItem('@Rebox:user') || '{}',
      );
      const { data } = await api.get(
        `/users?page=${params.page || 1}&per_page=${
          params.per_page || 10
        }&subordinate_of=${user_saved?.id}`,
      );
      setSubordinates(data);
    } catch (error) {
      notify(
        'Houve um erro ao tentar buscar os dados dos auxiliadores.',
        'error',
      );
    } finally {
      setLoading(prevState => !prevState);
    }
  }, []);

  useEffect(() => {
    getSubordinates({ page });
    sessionStorage.setItem('@Rebox:Page', `${page}`);
  }, [page]);

  return (
    <Container>
      {usersAccessLevel &&
        usersAccessLevel !== ConfigValues.user.access_level.high && (
          <Redirect to="/painel?auth=not" />
        )}
      <Header>
        <HeaderMenu />
      </Header>

      <Menu>
        <MenuSideBar />
      </Menu>

      <Main>
        <NamePage>
          <Title>Seus promotores</Title>
          <ButtonAddVehicle to="/promotores/novo">
            <IconAddGray />
          </ButtonAddVehicle>
        </NamePage>

        <Content>
          <List>
            <Subtitle>Listagem</Subtitle>
            {loading ? (
              <Loading>
                <MoonLoader size={30} color="#07359B" />
              </Loading>
            ) : (
              <Item users={subordinates?.data} />
            )}
          </List>
          <NavigationPages>
            <button onClick={() => setPage(page - 1)}>Anterior</button>
            <p>
              {subordinates?.header.page} de{' '}
              {Math.ceil(
                subordinates?.header.total / subordinates?.header.per_page,
              )}
            </p>
            <button className="invert" onClick={() => setPage(page + 1)}>
              Próximo
            </button>
          </NavigationPages>
        </Content>
      </Main>
      <ToastContainer />
    </Container>
  );
};

export default Subordinates;
