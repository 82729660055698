import React, { useCallback, useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { IconClose } from '@assets/icons';
import {
  ButtonBlueGradient,
  ButtonOutline,
  HeaderBack,
  HeaderMenu,
  MenuSideBar,
} from '@components/index';
import { ConfigValues } from '@config/index';
import User from '@models/User';
import api from '@services/api';
import { formatMoney } from '@utils/formatters';
import notify from '@utils/notify/toasts';
import { formatDateBR } from '@utils/validatorDate';

import {
  Container,
  Header,
  Main,
  Menu,
  Content,
  NamePage,
  Title,
  SubordinateInformation,
  SubordinateInformationBox,
  ModalRemoveSubordinate,
  ButtonCloseModal,
} from './styles';

interface IParamsProps {
  id: string;
}

interface IIndicators {
  indications_made: number;
  effective_indications: number;
  canceled_indications: number;
}

const typeBank: any = {
  current_account: 'Conta corrente',
  savings_account: 'Conta poupança',
  pix: 'Pix',
};

const pixType: any = {
  email: 'E-mail',
  cpf: 'CPF',
  cnpj: 'CNPJ',
  cellphone: 'Celular',
  others: 'Chave aleatória',
};

const SubordinatesDetails: React.FC = () => {
  const { goBack } = useHistory();
  const { id } = useParams<IParamsProps>();
  const [subordinate, setSubordinate] = useState<User>();
  const [indicators, setIndicators] = useState<IIndicators>();
  const [
    modalRemoveSubordinateIsOpen,
    setModalRemoveSubordinateIsOpen,
  ] = useState(false);

  const changeModalRemoveSubordinateIsOpen = () => {
    setModalRemoveSubordinateIsOpen(prevState => !prevState);
  };

  const removeSubordinate = useCallback(async () => {
    try {
      await api.delete(`/users/${id}`);

      notify('Promotor removido com sucesso!', 'success');
      changeModalRemoveSubordinateIsOpen();

      setTimeout(() => goBack(), 2000);
    } catch (error) {
      notify('Houve um erro ao tentar remover seu promotor.', 'error');
    }
  }, []);

  const getSubordinate = useCallback(async () => {
    try {
      const { data: response_users } = await api.get(`/users/${id}`);
      const { data: response_bank } = await api.get(
        `/users/bank-accounts?users_id=${id}`,
      );
      const user: User = response_users.data;
      user.bank_accounts = response_bank.data;
      setSubordinate(user);

      const indicators_new: IIndicators = {
        effective_indications: 0,
        indications_made: 0,
        canceled_indications: 0,
      };

      user.indications?.forEach(item => {
        if (item.status === ConfigValues.indication.status.created) {
          indicators_new.indications_made += 1;
        }
        if (item.status === ConfigValues.indication.status.effective) {
          indicators_new.effective_indications += 1;
        }
        if (item.status === ConfigValues.indication.status.canceled) {
          indicators_new.canceled_indications += 1;
        }
      });

      setIndicators(indicators_new);
    } catch (error) {
      notify('Houve um erro ao tentar exibir os dados do auxiliador.', 'error');
    }
  }, []);

  useEffect(() => {
    getSubordinate();
  }, []);

  return (
    <Container>
      <Header>
        <HeaderMenu />
      </Header>

      <Menu>
        <MenuSideBar />
      </Menu>

      <Main>
        <NamePage>
          <Title>Detalhes do promotor</Title>
        </NamePage>

        <Content>
          <HeaderBack />
          <SubordinateInformation>
            <h2>Indicações</h2>
            <SubordinateInformationBox>
              <p>
                Código de indicação: <span>{subordinate?.referral_code}</span>
              </p>
              <p>
                Indicações realizadas:{' '}
                <span>{indicators?.indications_made}</span>
              </p>
              <p>
                Indicações efetivadas:{' '}
                <span>{indicators?.effective_indications}</span>
              </p>
              <p>
                Indicações encerradas:{' '}
                <span>{indicators?.canceled_indications}</span>
              </p>
              <p>
                Saldo disponível:{' '}
                <span>
                  {formatMoney.fromNumberToPrice(subordinate?.balance || 0)}
                </span>
              </p>
            </SubordinateInformationBox>

            <h2>Dados pessoais</h2>
            <SubordinateInformationBox>
              <p>
                Nome:{' '}
                <span style={{ textTransform: 'capitalize' }}>
                  {subordinate?.name}
                </span>
              </p>
              <p>
                CPF/CNPJ: <span>{subordinate?.cpf || subordinate?.cnpj}</span>
              </p>
              <p>
                E-mail: <span>{subordinate?.email}</span>
              </p>
              <p>
                Data de Nascimento:{' '}
                <span>{formatDateBR(subordinate?.date_of_birth || '')}</span>
              </p>
              <p>
                Celular: <span>{subordinate?.cellphone}</span>
              </p>
            </SubordinateInformationBox>

            <h2>Dados bancários</h2>
            {subordinate?.bank_accounts[0] &&
            subordinate?.bank_accounts[0].type ===
              ConfigValues.bank_account.type.pix ? (
              <SubordinateInformationBox>
                <p>
                  Tipo de conta:{' '}
                  <span>
                    {subordinate?.bank_accounts[0]
                      ? typeBank[
                          subordinate?.bank_accounts[0].type.toLowerCase()
                        ]
                      : '--'}
                  </span>
                </p>
                <p>
                  Tipo de chave:{' '}
                  <span>
                    {subordinate?.bank_accounts[0]
                      ? pixType[
                          subordinate?.bank_accounts[0].pix_type?.toLowerCase() ||
                            ''
                        ]
                      : '--'}
                  </span>
                </p>
                <p>
                  Chave:{' '}
                  <span>
                    {subordinate?.bank_accounts[0]
                      ? subordinate?.bank_accounts[0].pix_key
                      : '--'}
                  </span>
                </p>
                <p>
                  Nome do titular:{' '}
                  <span>
                    {subordinate?.bank_accounts[0]
                      ? subordinate?.bank_accounts[0].owner_name?.toUpperCase() ||
                        '--'
                      : '--'}
                  </span>
                </p>
              </SubordinateInformationBox>
            ) : (
              <SubordinateInformationBox>
                <p>
                  Banco:{' '}
                  <span>
                    {subordinate?.bank_accounts[0]
                      ? subordinate?.bank_accounts[0].bank_name
                      : '--'}
                  </span>
                </p>
                <p>
                  Tipo de conta:{' '}
                  <span>
                    {subordinate?.bank_accounts[0]
                      ? typeBank[
                          subordinate?.bank_accounts[0].type.toLowerCase()
                        ]
                      : '--'}
                  </span>
                </p>
                <p>
                  Agência:{' '}
                  <span>
                    {subordinate?.bank_accounts[0]
                      ? subordinate?.bank_accounts[0].bank_agency
                      : '--'}
                  </span>
                </p>
                <p>
                  Conta:{' '}
                  <span>
                    {subordinate?.bank_accounts[0]
                      ? subordinate?.bank_accounts[0].bank_number
                      : '--'}
                  </span>
                </p>
                <p>
                  Nome do titular:{' '}
                  <span>
                    {subordinate?.bank_accounts[0]
                      ? subordinate?.bank_accounts[0].owner_name?.toUpperCase() ||
                        '--'
                      : '--'}
                  </span>
                </p>
              </SubordinateInformationBox>
            )}

            <SubordinateInformationBox>
              <ButtonBlueGradient
                onClick={changeModalRemoveSubordinateIsOpen}
                style={{ maxWidth: 200 }}
              >
                Remover promotor
              </ButtonBlueGradient>
            </SubordinateInformationBox>
          </SubordinateInformation>
        </Content>
      </Main>
      <ToastContainer />
      <ModalRemoveSubordinate
        isOpen={modalRemoveSubordinateIsOpen}
        onRequestClose={changeModalRemoveSubordinateIsOpen}
        contentLabel="Cancelar conta"
      >
        <ButtonCloseModal onClick={changeModalRemoveSubordinateIsOpen}>
          <IconClose />
        </ButtonCloseModal>
        <h1>Você tem certeza?</h1>
        <p>
          Esta ação é irreversível, para mais informações contate a nossa
          equipe.
        </p>
        <div>
          <ButtonBlueGradient
            onClick={changeModalRemoveSubordinateIsOpen}
            style={{ height: 40, marginRight: 20, fontSize: 14 }}
          >
            Não, vou manter
          </ButtonBlueGradient>
          <ButtonOutline onClick={removeSubordinate}>
            Tenho certeza
          </ButtonOutline>
        </div>
      </ModalRemoveSubordinate>
    </Container>
  );
};

export default SubordinatesDetails;
