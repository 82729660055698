import React, { useEffect, useRef, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { MoonLoader } from 'react-spinners';
import { FormHandles } from '@unform/core';

import { HeaderMenu, InputText, MenuSideBar } from '@components/index';
import User from '@models/User';
import Indication from '@models/Indication';
import api from '@services/api';
import notify from '@utils/notify/toasts';
import sessionService from '@services/session.service';

import Item from './Item';

import {
  Container,
  Header,
  Menu,
  Main,
  NamePage,
  Title,
  Content,
  Loading,
  List,
  FormSearch,
  NavigationPages,
} from './styles';

interface IResponseIndications {
  header: any;
  data: Indication[];
}

const PAGE_INDICATION_STORAGE = '@Rebox:Indications:page';

const IndicationsList: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<User>(sessionService.getUser());
  const [indications, setIndications] = useState<IResponseIndications>();
  const [page, setPage] = useState<number>(
    Number.parseInt(sessionStorage.getItem(PAGE_INDICATION_STORAGE) || '1', 10),
  );
  const [totalPages, setTotalPages] = useState<number>(1);

  const getIndications = async (pageNew: number) => {
    try {
      setLoading(prevState => !prevState);
      const { data } = await api.get(
        `/indications?id_who_indicated=${user.id}&page=${
          pageNew || 1
        }&per_page=7&details=all`,
      );
      setIndications(data);
      setTotalPages(Math.ceil(data.header.total / data.header.per_page));
    } catch (error) {
      notify('Houve um erro ao tentar buscar indicações.', 'error');
    } finally {
      setLoading(prevState => !prevState);
    }
  };

  useEffect(() => {
    getIndications(page);
    sessionStorage.setItem(PAGE_INDICATION_STORAGE, `${page}`);
  }, [page]);

  return (
    <Container>
      <Header>
        <HeaderMenu />
      </Header>

      <Menu>
        <MenuSideBar />
      </Menu>

      <Main>
        <Content>
          <Title>Minhas indicações</Title>
          {loading ? (
            <Loading>
              <MoonLoader size={30} color="#07359B" />
            </Loading>
          ) : (
            <List>
              <Item indications={indications?.data} />
            </List>
          )}

          <NavigationPages>
            <button disabled={page === 1} onClick={() => setPage(page - 1)}>
              Anterior
            </button>
            <p>
              {page} de {totalPages}
            </p>
            <button
              disabled={page === totalPages}
              className="invert"
              onClick={() => setPage(page + 1)}
            >
              Próximo
            </button>
          </NavigationPages>
        </Content>
      </Main>
      <ToastContainer />
    </Container>
  );
};

export default IndicationsList;
