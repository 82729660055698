import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

/* VERSÃO MOBILE
 * - 1024px
 * - 960px
 * - 768px
 * - 540px
 * - 480px
 * - 360px
 * - 320px
 */

export const Shadow = styled.div`
  display: ${props => {
    return props.className === 'active' ? 'block' : 'none';
  }};
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  transition: 100ms ease;

  @media (min-width: 1023.9px) {
    display: none;
  }
`;

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background_primary};
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  z-index: 10;

  position: fixed;
  top: 0;
  height: 100vh;
  width: 34vw;
  left: ${props => {
    return props.className === 'open' ? 0 : '-34vw';
  }};
  transition: 450ms;
  /* left: -30vw; */

  @media (min-width: 1023.9px) {
    position: initial;
    border-radius: 10px;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 767.9px) {
    width: 40vw;
    left: ${props => {
      return props.className === 'open' ? 0 : '-40vw';
    }};
  }

  @media (max-width: 539.9px) {
    width: 70vw;
    left: ${props => {
      return props.className === 'open' ? 0 : '-70vw';
    }};
  }

  @media (max-width: 320px) {
    width: 85vw;
    left: ${props => {
      return props.className === 'open' ? 0 : '-85vw';
    }};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
  padding: 35px 30px;
`;

export const Title = styled.p`
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSize.paragraph}px;
  color: ${({ theme }) => theme.colors.text_blue};
  margin: 4vh 0 10px 30px;
`;

export const LinkMenu = styled(Link)`
  display: flex;
  align-items: center;

  margin-top: 10px;
  padding: 10px 30px;

  border-radius: 10px;

  font-size: ${({ theme }) => theme.fontSize.paragraph}px;
  color: ${({ theme }) => theme.colors.text_gray_75};

  > svg {
    width: 18px;
    height: 18px;
    opacity: 0.7;
    margin-right: 10px;
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.background_secondary_50};
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10%;
`;

export const Bonus = styled.div`
  background-color: ${({ theme }) => theme.colors.background_blue_05};
  border-radius: 15px;

  padding: 20px 15px;
  margin-bottom: 20px;
  width: 80%;

  > p {
    font-size: ${({ theme }) => theme.fontSize.paragraph}px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 6px;
  }
`;

export const Description = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    width: 50px;
    margin-right: 10px;
  }

  > span {
    text-align: center;
    font-size: ${({ theme }) => theme.fontSize.legend}px;
    color: ${({ theme }) => theme.colors.text_gray};
  }
`;

export const LinkClient = styled(Link)`
  font-size: ${({ theme }) => theme.fontSize.paragraph}px;
  color: ${({ theme }) => theme.colors.text_blue};

  :hover {
    color: ${({ theme }) => theme.colors.text_gray};
  }
`;

export const ButtonClient = styled.button`
  font-size: ${({ theme }) => theme.fontSize.paragraph}px;
  color: ${({ theme }) => theme.colors.text_blue};

  :hover {
    color: ${({ theme }) => theme.colors.text_gray};
  }
`;

export const ModalBecomeACustomer = styled(Modal)`
  background-color: ${({ theme }) => theme.colors.background_primary};
  border-radius: 10px;
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.1);
  border: 0.5px solid ${({ theme }) => theme.colors.text_gray_15};
  max-height: 400px;
  max-width: 550px;
  width: 80%;
  padding: 40px 80px;
  margin: 25vh auto 0;
  position: relative;

  @media (max-width: 539.9px) {
    margin: 25vh 10vw 0;
    padding: 6vh 6vw;
  }

  > h1 {
    text-align: center;
    font-weight: 500;
    font-size: ${({ theme }) => theme.fontSize.subtitle}px;
    color: ${({ theme }) => theme.colors.text_gray};
    margin-bottom: 10px;

    @media (max-width: 539.9px) {
      font-size: ${({ theme }) => theme.fontSize.paragraphBig}px;
    }
  }

  > p {
    text-align: center;
    font-weight: 400;
    font-size: ${({ theme }) => theme.fontSize.paragraph}px;
    color: ${({ theme }) => theme.colors.text_gray_50};
    margin-bottom: 20px;

    @media (max-width: 539.9px) {
      font-size: ${({ theme }) => theme.fontSize.legend}px;
    }
  }

  > form {
    padding: 20px 10% 0;
  }

  > section {
    text-align: center;
  }
`;

export const ButtonCloseModal = styled.button`
  position: absolute;
  top: 10%;
  left: 93%;

  @media (max-width: 539.9px) {
    top: 2vh;
    left: 90%;
  }

  > svg {
    width: 12px;
    margin: 0;
  }
`;

/**
 * MOBILE
 */

export const OpenMenuMobile = styled.button`
  display: ${props => {
    return props.className === 'active' ? 'none' : 'block';
  }};
  position: fixed;
  top: 47vh;
  left: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0;
  z-index: 11;

  background-color: ${({ theme }) => theme.colors.text_gray_15};
  width: 24px;
  height: 50px;

  @media (min-width: 1023.9px) {
    display: none;
  }
`;

export const ButtonCloseMenu = styled.button`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 12px;
  left: 88%;

  > svg {
    width: 12px;
    margin: 0;
  }

  @media (min-width: 1023.9px) {
    display: none;
  }

  @media (min-width: 768px) {
    top: 20px;
    left: 88%;
  }

  @media (min-width: 360px) {
    top: 20px;
    left: 85%;
  }
`;
