import axios from 'axios';

import { ConfigBase } from '../config';

const api = axios.create({
  baseURL: ConfigBase.baseURL,
  headers: {
    Authorization: `Bearer dba7a97fcb37074197a53798b777f7da`,
  },
});

export default api;
