// src/config/base.ts

export default {
  baseURL: 'https://rebox2.com.br/v1.0',
  // baseURL: 'http://localhost:3333/v1.0',
  linkShared: 'https://rebox.com.br/promocao?i=',
  clientBaseURL: 'https://rebox.com.br',
  providerBaseURL: 'https://prestador.rebox.com.br',
  emailContactRebox: 'faleconosco@rebox.com.br',
  phoneContactRebox: '5521983846769',
  whatsapp: {
    apiBaseURL: 'https://api.whatsapp.com',
  },
};
