import React from 'react';

import { Route, Switch } from 'react-router-dom';

// Pages
import * as Page from '@pages/index';

import PrivateRoute from './private';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Page.LandingPage} />
    <Route path="/login" exact component={Page.SignIn} />
    <Route path="/esqueci-minha-senha" exact component={Page.RecoverPassword} />
    <Route path="/cadastro" exact component={Page.PersonalData} />
    <Route path="/cadastro/senha" component={Page.SetPassword} />
    <Route path="/cadastro/obrigado" component={Page.Thanks} />
    <Route path="/politica-de-privacidade" component={Page.PrivacyPolicy} />
    <PrivateRoute path="/painel" component={Page.Dashboard} />
    <PrivateRoute path="/perfil" component={Page.Profile} />
    <PrivateRoute path="/ajustes" component={Page.Settings} />
    <PrivateRoute path="/conta-bancaria" component={Page.BankAccount} />
    <PrivateRoute path="/indicacoes" component={Page.IndicationsList} />
    <PrivateRoute
      path="/indicacao/:id"
      exact={true}
      component={Page.IndicationsDetails}
    />
    <PrivateRoute path="/promotores" exact component={Page.Subordinates} />
    <PrivateRoute
      path="/promotor/:id"
      exact={true}
      component={Page.SubordinatesDetails}
    />
    <PrivateRoute path="/promotores/novo" component={Page.SubordinatesNew} />
  </Switch>
);

export default Routes;
