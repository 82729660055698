/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { MoonLoader } from 'react-spinners';
import { ToastContainer } from 'react-toastify';

import { IconArrowRight, IconBack } from '@assets/icons';
import { HeaderMenu, MenuSideBar } from '@components/index';
import Indication from '@models/Indication';
import api from '@services/api';
import notify from '@utils/notify/toasts';

import IndicationsDetailsInformations from './Informations';
import IndicationsDetailsPaymentsHistory from './PaymentsHistory';
import { IParamsProps } from './typing';

import {
  Container,
  ContentHeader,
  ContentHeaderItem,
  ButtonBackList,
  ContentBack,
  ContentBackTitle,
  Header,
  Loading,
  Main,
  Menu,
  Content,
} from './styles';

const TAB_INDICATION_STORAGE = '@Rebox:Indication:tab';

const IndicationsDetails: React.FC = () => {
  const { goBack } = useHistory();
  const { id } = useParams<IParamsProps>();
  const [tab, setTab] = useState<number>(
    Number.parseInt(sessionStorage.getItem(TAB_INDICATION_STORAGE) || '1', 10),
  );
  const [indication, setIndication] = useState<Indication>();
  const [loading, setLoading] = useState<boolean>(false);

  const getIndication = useCallback(async () => {
    try {
      const { data: response_indication } = await api.get(`/indications/${id}`);
      setIndication(response_indication.data);
    } catch (error) {
      notify('Houve um erro ao tentar exibir a indicação.', 'error');
    }
  }, []);

  useEffect(() => {
    getIndication();
  }, []);

  return (
    <Container>
      <Header>
        <HeaderMenu />
      </Header>

      <Menu>
        <MenuSideBar />
      </Menu>

      <Main>
        <Content>
          <ContentBack>
            <ButtonBackList onClick={() => goBack()}>
              <IconBack />
              <p>Indicações</p>
              <IconArrowRight />
            </ButtonBackList>
            <ContentBackTitle>Detalhes</ContentBackTitle>
          </ContentBack>
          <ContentHeader>
            <ContentHeaderItem
              isActive={tab === 1}
              onClick={() => setTab(1)}
              style={{ borderLeft: 'none' }}
            >
              <p>Informações</p>
            </ContentHeaderItem>
            <ContentHeaderItem
              isActive={tab === 2}
              onClick={() => setTab(2)}
              style={{ borderRight: 'none' }}
            >
              <p>Histórico de pagamentos</p>
            </ContentHeaderItem>
          </ContentHeader>

          {loading ? (
            <Loading>
              <MoonLoader size={30} color="#07359B" />
            </Loading>
          ) : (
            <>
              {tab === 1 ? (
                <IndicationsDetailsInformations />
              ) : tab === 2 ? (
                <IndicationsDetailsPaymentsHistory />
              ) : (
                <div style={{ textAlign: 'center', marginTop: 30 }}>
                  Em breve
                </div>
              )}
            </>
          )}
        </Content>
      </Main>
      <ToastContainer />
    </Container>
  );
};

export default IndicationsDetails;
