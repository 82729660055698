import User from '@models/User';

import api from './api';

interface IParamsOpen {
  email: string;
  password: string;
}

interface IStorage {
  token: string;
  user: User;
  sessions_id: string;
}

const NAME_TOKEN_STORAGE = '@Rebox:token';
const NAME_USER_STORAGE = '@Rebox:user';
const ID_SESSION_STORAGE = '@Rebox:sessionsId';
const NAME_REMEMBER_STORAGE = '@Rebox:rememberMe';

const sessionService = {
  open: async ({ email, password }: IParamsOpen) => {
    const session = await api.post('/sessions', {
      email,
      password,
    });
    return session.data;
  },

  updateLocalOrSessionStorage: ({ token, user, sessions_id }: IStorage) => {
    const remember = localStorage.getItem(NAME_REMEMBER_STORAGE);

    if (remember) {
      localStorage.setItem(NAME_TOKEN_STORAGE, token);
      localStorage.setItem(NAME_USER_STORAGE, JSON.stringify(user));
      localStorage.setItem(ID_SESSION_STORAGE, sessions_id);
    } else if (!remember || remember === null) {
      sessionStorage.setItem(NAME_TOKEN_STORAGE, token);
      sessionStorage.setItem(NAME_USER_STORAGE, JSON.stringify(user));
      sessionStorage.setItem(ID_SESSION_STORAGE, sessions_id);
    }

    // api.defaults.headers.authorization = `Bearer ${token}`;
  },

  checked: () => {
    const existUserInLocalStorage = localStorage.getItem(NAME_TOKEN_STORAGE);

    let token = null;
    let user = null;

    if (existUserInLocalStorage) {
      token = localStorage.getItem(NAME_TOKEN_STORAGE);
      user = localStorage.getItem(NAME_USER_STORAGE);
    } else {
      token = sessionStorage.getItem(NAME_TOKEN_STORAGE);
      user = sessionStorage.getItem(NAME_USER_STORAGE);
    }

    if (token && user) {
      // api.defaults.headers.authorization = `Bearer ${token}`;

      return true;
    }

    return false;
  },

  getUser: () => {
    if (localStorage.getItem(NAME_TOKEN_STORAGE)) {
      return JSON.parse(localStorage.getItem(NAME_USER_STORAGE) || '{}');
    }

    if (sessionStorage.getItem(NAME_TOKEN_STORAGE)) {
      return JSON.parse(sessionStorage.getItem(NAME_USER_STORAGE) || '{}');
    }

    return null;
  },

  getToken: () => {
    if (localStorage.getItem(NAME_TOKEN_STORAGE)) {
      return localStorage.getItem(NAME_TOKEN_STORAGE);
    }
    return sessionStorage.getItem(NAME_TOKEN_STORAGE);
  },

  getId: () => {
    if (localStorage.getItem(ID_SESSION_STORAGE)) {
      return localStorage.getItem(ID_SESSION_STORAGE);
    }
    return sessionStorage.getItem(ID_SESSION_STORAGE);
  },

  cleanLocalStorage: () => {
    localStorage.clear();
    sessionStorage.clear();
  },
};

export default sessionService;
