import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const NoInfo = styled.p`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.paragraph}px;
  color: ${({ theme }) => theme.colors.text_gray_75};
`;

export const ItemList = styled.div`
  display: grid;
  grid-template-columns: 0.8fr 1fr 1fr 1fr;
  grid-gap: 0 1.5vw;
  align-items: center;
  padding: 2vh 0;

  :hover {
    background-color: ${({ theme }) => theme.colors.background_secondary_50};
  }
`;

export const ItemListLabel = styled.p`
  text-align: start;
  font-size: ${({ theme }) => theme.fontSize.paragraph}px;
  color: ${({ theme }) => theme.colors.text_gray};
  line-height: 24px;
  font-weight: 600;
`;

export const ItemListText = styled.p`
  text-align: start;
  font-size: ${({ theme }) => theme.fontSize.legend / 15}rem;
  color: ${({ theme }) => theme.colors.text_gray};
  line-height: 24px;
  word-break: keep-all;

  > svg {
    width: 12px;
    height: 12px;
  }

  ${props =>
    props.children === 'Cliente efetivado' &&
    css`
      font-weight: 600;
      color: #00D071// ${({ theme }) => theme.colors.text_blue};
    `}

  ${props =>
    props.children === 'Aguardando efetivação' &&
    css`
      font-weight: 600;
      color: ${({ theme }) => theme.colors.text_blue}; //#ff9a0a;
    `}

    ${props =>
    props.children === 'Cancelado' &&
    css`
      font-weight: 600;
      color: #d63031;
    `}
`;

export const ItemListMobile = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3vh 2vw;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 3vh 0;
  }
`;

export const MobileItem = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.text_gray_15};
  border-radius: 6px;
  padding: 4vh 4vw;
`;

export const MobileItemBox = styled.div`
  padding-bottom: 2vh;
`;

export const MobileItemBoxLabel = styled.p`
  text-align: start;
  font-size: ${({ theme }) => theme.fontSize.paragraph}px;
  color: ${({ theme }) => theme.colors.text_gray};
  line-height: 24px;
  font-weight: 600;
`;

export const MobileItemBoxText = styled.p`
  text-align: start;
  font-size: ${({ theme }) => theme.fontSize.legend / 15}rem;
  color: ${({ theme }) => theme.colors.text_gray};
  line-height: 24px;

  > svg {
    width: 12px;
    height: 12px;
  }

  ${props =>
    props.children === 'Cliente efetivado' &&
    css`
      font-weight: 600;
      color: #00D071// ${({ theme }) => theme.colors.text_blue};
    `}

  ${props =>
    props.children === 'Aguardando efetivação' &&
    css`
      font-weight: 600;
      color: ${({ theme }) => theme.colors.text_blue}; //#ff9a0a;
    `}

    ${props =>
    props.children === 'Cancelado' &&
    css`
      font-weight: 600;
      color: #d63031;
    `}
`;
