import React, { useCallback } from 'react';

import { useHistory } from 'react-router-dom';
import { WhatsappShareButton } from 'react-share';
import { ToastContainer } from 'react-toastify';

import { IconArrowLeftWhite } from '@assets/icons';
import { ImageLogotipo, ImageThanks } from '@assets/images';
import { ConfigValues } from '@config/index';
import { useSignUp } from '@hooks/signup';
import api from '@services/api';
import sessionService from '@services/session.service';
import notify from '@utils/notify/toasts';

import {
  Container,
  ContainerThanks,
  ContainerButtons,
  ContentTitle,
  Content,
  ContentBox,
  ContentSubtitle,
  ButtonAccess,
  ButtonShare,
  TextButtonAccess,
  TextButtonShare,
} from './styles';

const Thanks: React.FC = () => {
  const { signUpUserData, updateSignUpUserData } = useSignUp();
  const { push } = useHistory();

  const openSession = useCallback(async () => {
    try {
      if (signUpUserData.email && signUpUserData.password) {
        notify('Estamos efetuando o seu login. Por favor aguarde...', 'info');

        const response = await api.post('/sessions', {
          email: signUpUserData.email,
          password: signUpUserData.password,
        });

        const { user, token, id } = response.data.data;

        if (user.status !== ConfigValues.user.status.active) {
          notify('Sem permissão para acessar o sistema', 'error');
          await api.put(`/sessions/${id}`);
          return;
        }

        sessionStorage.clear();
        localStorage.removeItem('@Rebox-Client:signup-data');

        sessionService.updateLocalOrSessionStorage({
          user,
          token,
          sessions_id: id,
        });

        notify(response.data.header.message, 'success');
        push('/painel');
      }
    } catch (error) {
      notify(
        'Erro ao tentar acessar o sistema, por favor tente mais tarde.',
        'error',
      );
    }
  }, []);

  return (
    <Container>
      <Content>
        <ImageLogotipo width={200} height={50} />

        <ContentTitle>
          Deu tudo certo! Seu cadastro está concluído.
        </ContentTitle>

        <ContentSubtitle>
          Agora basta você compartilhar seu link de indicação com amigos, ou com
          quem quer que seja, e começar a ganhar uma renda extra.
        </ContentSubtitle>

        <ContentBox>
          <ContainerThanks>
            <ImageThanks />
          </ContainerThanks>
          <ContainerButtons>
            <ButtonAccess onClick={openSession}>
              <TextButtonAccess>Acessar o sistema</TextButtonAccess>
              <IconArrowLeftWhite />
            </ButtonAccess>

            <WhatsappShareButton
              className="btn-outline"
              title={
                'Oie, tudo bem? Eu vim aqui te convidar para utilizar a *REBOX*. Lá tem planos de assistência veicular 24h e com um preço que cabe no bolso. Eu já assinei, falta só você! '
              }
              url={'https://rebox.com.br'}
            >
              <ButtonShare>
                <TextButtonShare>Convidar meus amigos</TextButtonShare>
                <IconArrowLeftWhite />
              </ButtonShare>
            </WhatsappShareButton>
          </ContainerButtons>
        </ContentBox>
      </Content>
      <ToastContainer />
    </Container>
  );
};

export default Thanks;
